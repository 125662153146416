import { Box, Grid } from "@material-ui/core";

import backgroundLichtschalter from "../../../../../../assets/cwa/background/titelbilder/schalter/axcent-pur.jpg";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QProductGroup from "../../../../../../components/QProductGroup";
import QBodyText from "../../../../../../components/QBodyText";
import Preisliste from "../preisliste";
import CenteredImage from "../../../../../../components/CenteredImage";

const itemlist = [
  {
    src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/axcent-pur/papierblau.jpg",
    alt: <>Busch-axcent<sup>®</sup> pur - Papierblau</>,
  },
  {
    src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/axcent-pur/papierbraun.jpg",
    alt: <>Busch-axcent<sup>®</sup> pur - Papierbraun</>,
  },
  {
    src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/axcent-pur/schiefer.jpg",
    alt: <>Busch-axcent<sup>®</sup> pur - Schiefer</>,
  },
  {
    src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/axcent-pur/schwarzmatt-betongrau.jpg",
    alt: <>Busch-axcent<sup>®</sup> pur - Betongrau</>,
  },
  {
    src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/axcent-pur/anthrazit-platin.jpg",
    alt: <>Busch-axcent<sup>®</sup> pur - Platin</>,
  },
];

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produkte/schalterdesign/busch-axcent",
    target: "_blank",
  },
  {
    type: "dialog",
    img: "preisliste",
    children: <Preisliste anchor="busch-axcent-pur" />,
  },
];

export default function Axcent() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundLichtschalter, backgroundPosition: "left",
      }}
    >
      <CenteredImage
        images={itemlist}
        width="30vw"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                Busch-axcent<sup>®</sup> pur
              </>
            }
          />
        </Grid>
        <Grid item container>
          <Grid item xs={4} style={{ paddingRight: "35px" }}>
            <QBodyText>
              Die neue Farbpalette von Busch-axcent<sup>®</sup> pur greift
              aktuelle Trends auf und gibt individuellem Raumdesign den farbigen
              Schliff.
            </QBodyText>
            <QList
              title="Vorteile"
              items={[
                "Kontrastreich und lebendig",
                "Puristische Optik",
                "Hochwertige Materialien",
              ]}
              dense
              
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
