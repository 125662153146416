// Must be the first import
if (process.env.NODE_ENV === "development") {
  // Must use require here as import statements are only allowed
  // to exist at the top of a file.
  require("preact/debug");
}
if (typeof window !== "undefined") {
  require("./components/Utils/ScrollOverflow");
}
import App from "./app.js";
import "./style/index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-activity/dist/react-activity.css";
import * as B2CSDK from "@qmarketing/b2c-js-sdk";

global.API = B2CSDK.ApiClient.instance;
global.API.basePath = process.env.PREACT_APP_B2CAPI;

export default App;
