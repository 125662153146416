import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/46.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/Wetterstation/wetterstation.png";

export default function WetterstationOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/busch-freehome/wetterstation",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Wetterstation" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Unsere Wetterstation Busch-free@home® und Busch-free@home® flex
                wireless erfasst Temperatur, Windgeschwindigkeit und Regen.
                Sobald es stürmt, werden Jalousien selbstständig hoch- und die
                Markise eingefahren. Sofern ein Dachfenster geöffnet ist, kann
                das System eine Meldung auf das Smartphone oder Tablet des
                Nutzers schicken.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Zum Erfassen und Senden der Helligkeit, der Temperatur, der Windgeschwindigkeit und Regen",
                  "Wetterdaten können mit Aktoren verknüpft werden um automatisiert Funktionen zu steuern z.B. Anfahren einer Position",
                  <>
                    Wetterdaten können im Busch-free@home<sup>®</sup>{" "}
                    Webinterface und der Busch-free@home<sup>®</sup> Next App
                    visualisiert werde
                  </>,
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Wetterstation"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
