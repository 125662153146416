import { Link } from "@material-ui/core";
import background from "../../../../../../assets/renovation-und-neubau/fullscreen-funktion.jpg";

export default function SVGInteraktionFunktion() {
  return (
    <svg
      style={{
        display: "block",
        width: "100vw",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      }}
      viewBox="0 0 2697 2023"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="funktion-group"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        fill-opacity="0"
      >
    <g id="fullscreen-funktion-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0">
        <Link href="#funktion/5" target="_self">
          <rect className="hotspotHover" id="outdoor-ip44" fill="#000000" x="39" y="1180" width="184" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/9" target="_self">
        <rect className="hotspotHover" id="bwm-outdoor" fill="#000000" x="275" y="1180" width="184" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/12" target="_self">
        <rect className="hotspotHover" id="sicherheit" fill="#000000" x="587" y="1180" width="162" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/4" target="_self">
        <rect className="hotspotHover" id="bwm-indoor" fill="#000000" x="1057" y="1180" width="182" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/7" target="_self">
        <rect className="hotspotHover" id="multimedia" fill="#000000" x="1493" y="1180" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/11" target="_self">
        <rect className="hotspotHover" id="zutrittskontrolle" fill="#000000" x="1870" y="1180" width="162" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/10" target="_self">
        <rect className="hotspotHover" id="tuerkommunikation" fill="#000000" x="2089" y="1180" width="178" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/2" target="_self">
        <rect className="hotspotHover" id="jalousie" fill="#000000" x="1968" y="727" width="160" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/6" target="_self">
        <rect className="hotspotHover" id="beleuchtungssteuerung" fill="#000000" x="1590" y="727" width="202" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/1" target="_self">
        <rect className="hotspotHover" id="flextronics" fill="#000000" x="1082" y="726" width="172" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/3" target="_self">
        <rect className="hotspotHover" id="raumklima" fill="#000000" x="533" y="727" width="172" height="178" rx="6"></rect>
        </Link>
        <Link href="#funktion/8" target="_self">
        <rect className="hotspotHover" id="sat-edv-tel" fill="#000000" x="1574" y="423" width="161" height="178" rx="6"></rect>
        </Link>
    </g>
      </g>
    </svg>
  );
}
