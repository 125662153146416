import { Link } from "@material-ui/core";
import background from "../../../../../../assets/renovation-und-neubau/fullscreen-raeume.jpg";

export default function SVGInteraktionRaeume() {
  return (
    <svg
      style={{
        display: "block",
        width: "100vw",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      }}
      viewBox="0 0 2697 2023"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="fullscreen-raeume-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0">
        <Link href="#raeume/1" target="_self">
            <rect className="hotspotHover" id="arbeiten" fill="#000000" x="1574" y="423" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/3" target="_self">
            <rect className="hotspotHover" id="schlafen" fill="#000000" x="1599" y="811" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/2" target="_self">
            <rect className="hotspotHover" id="kind" fill="#000000" x="1336" y="811" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/4" target="_self">
            <rect className="hotspotHover" id="bad" fill="#000000" x="829" y="811" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/5" target="_self">
            <rect className="hotspotHover" id="outdoor" fill="#000000" x="229" y="1193" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/7" target="_self">
            <rect className="hotspotHover" id="kueche" fill="#000000" x="821" y="1193" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/10" target="_self">
            <rect className="hotspotHover" id="diele" fill="#000000" x="1378" y="1193" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/6" target="_self">
            <rect className="hotspotHover" id="wohnen" fill="#000000" x="1692" y="1193" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/9" target="_self">
            <rect className="hotspotHover" id="eingang" fill="#000000" x="1972" y="1193" width="161" height="178" rx="6"></rect>
        </Link>
        <Link href="#raeume/8" target="_self">
            <rect className="hotspotHover" id="garage" fill="#000000" x="2413" y="1193" width="161" height="178" rx="6"></rect>
        </Link>
      </g>
    </svg>
  );
}
