import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/3.jpg";
import Overlay from "../../../../../../../assets/renovation-und-neubau/raume/diele/busch-welcome/overlay.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/8.jpg";
import Image9 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/9.jpg";
import Image10 from "../../../../../../../assets/renovation-und-neubau/funktion/tuerkommunikation/10.jpg";

const bedienelementItems1 = [
  {
    title: "Außenstation Video",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=343&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=28b89f1061c3bc1d26662a7d9d971d7d",
    },
  },
  {
    title: "Außenstation Audio",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=343&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=28b89f1061c3bc1d26662a7d9d971d7d",
    },
  },
  {
    title: "Hinterbaumodule",
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=3124&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=e99110f90b97559aecc2accd9cb06aad",
    },
  },
  { placeholder: true },
  {
    title: "Außenstation Video",
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=2497&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=590654dd42480825bf848acfe84e1e14",
    },
  },
  {
    title: "Außenstation Video mit Display",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=2497&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=590654dd42480825bf848acfe84e1e14",
    },
  },
  {
    title: (
      <>
        Busch-SmartTouch<sup>®</sup> 10"
      </>
    ),
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=345&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=58b9f19e9deef3a18b30f287e2468106",
    },
  },
  {
    title: "Innenstation Audio",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=345&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=58b9f19e9deef3a18b30f287e2468106",
    },
  },
  {
    title: 'Video Innenstation 4,3" WLAN',
    img: Image8,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=345&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=58b9f19e9deef3a18b30f287e2468106",
    },
  },
  { placeholder: true },
  {
    title: "IP touch 7.",
    img: Image9,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=2498&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=13393756b65bed1340963bff49fa8cab",
    },
  },
  {
    title: "IP touch 10.",
    img: Image10,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=2498&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=13393756b65bed1340963bff49fa8cab",
    },
  },
];

export default function Tuerkommunikation() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.42,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-welcome",
          target: "_blank",
        },
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/smarter-home/systeme/busch-welcome-ip",
          target: "_blank",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: Overlay,
              alt: "Kommunikation mit Smartphone und Tablet",
            },
          ],
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={7}>
              <QHeader
                title={
                  <>
                    Busch-Welcome<sup>®</sup> Türkommunikation
                  </>
                }
                arrow
              />
              <QBodyText>
                Busch-Welcome<sup>®</sup> Türkommunikation – mit zweierlei Maß.
                Auf 2-Draht-Basis ideal für die Nachrüstung, da bestehende
                Leitungswege zur Türklingel oder Innenstation weiter genutzt
                werden können oder als Welcome IP auf Netzwerkbasis mit
                glasklarem Bild! Zuhause und unterwegs.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {bedienelementItems1.map((e, i) => {
                  return (
                    <Grid item xs={2}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
