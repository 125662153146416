import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/5.jpg";
import Jalousie from "../../../../../../../../assets/renovation-und-neubau/raume/kueche/busch-free-at-home-flex-jalousie.jpg";
import USBLaden from "../../../../../../../../assets/renovation-und-neubau/raume/kueche/usb-laden.jpg";
import Waermemelder from "../../../../../../../../assets/renovation-und-neubau/raume/kueche/waermemelder.jpg";
import BuschRadioBTConnect from "../../../../../../../../assets/renovation-und-neubau/raume/kueche/busch-radio-btconnect.jpg";
import DrehTastdimmer from "../../../../../../../../assets/renovation-und-neubau/raume/kueche/dreh-tastdimmer.jpg";
import JalousieOverlay from "./slides/Jalousie";
import USBOverlay from "./slides/USB";
import WaermemelderOverlay from "./slides/Waermemelder";
import BuschRadioDABPlusOverlay from "./slides/BuschRadioDABPlus";
import DrehTastdimmerOverlay from "./slides/DrehTastdimmer";

const items = [
  {
    title: (
      <>
        Busch-free@home<sup>®</sup> flex, Jalousie
      </>
    ),
    img: Jalousie,
    menuOverlay: <JalousieOverlay />,
  },
  {
    title: "USB-Laden",
    img: USBLaden,
    menuOverlay: <USBOverlay />,
  },
  {
    title: "Wärmemelder",
    img: Waermemelder,
    menuOverlay: <WaermemelderOverlay />,
  },
  {
    title: "Busch-Radio BTconnect DAB+",
    img: BuschRadioBTConnect,
    menuOverlay: <BuschRadioDABPlusOverlay />,
  },
  {
    title: "Dreh-/Tastdimmer",
    img: DrehTastdimmer,
    menuOverlay: <DrehTastdimmerOverlay />,
  },
];

export default function Kueche() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
        width: 0.45,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Küche" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Rezepte und Zutaten für Ihre Küche. Der Ort, an dem unsere
                Familie viel Zeit verbringt und im wahrsten Sinne des Wortes
                Geschmack hat.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
