import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";

// Images
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/3.jpg";
import Bewegungsmelder from "../../../../../../../../assets/renovation-und-neubau/raume/eingang/bewegungsmelder.jpg";
import BuschWelcome from "../../../../../../../../assets/renovation-und-neubau/raume/eingang/busch-welcome.jpg";
import Allwetter44 from "../../../../../../../../assets/renovation-und-neubau/raume/eingang/allwetter-44-a.jpg";
import BuschAccessControl from "../../../../../../../../assets/renovation-und-neubau/raume/eingang/busch-accesscontrol.jpg";
import Kamera from "../../../../../../../../assets/renovation-und-neubau/raume/eingang/kamera.jpg";
import DomeKamera from "../../../../../_globalSlides/Dome-Kamera";

// Overlays
import ChapterCard from "../../../../../../../../components/ChapterCard";
import BewegungsmelderOverlay from "./slides/Bewegungsmelder";
import TuerkommunikationOverlay from "./slides/Tuerkommunikation";
import AllwetterOverlay from "./slides/Allwetter";
import BuschAccessControlOverlay from "./slides/Busch-Accesc-Control";

const items = [
  {
    title: "Bewegungsmelder",
    img: Bewegungsmelder,
    menuOverlay: <BewegungsmelderOverlay />,
  },
  {
    title: (
      <>
        Busch-Welcome<sup>®</sup>
      </>
    ),
    img: BuschWelcome,
    menuOverlay: <TuerkommunikationOverlay />,
  },
  {
    title: "Allwetter 44®",
    img: Allwetter44,
    menuOverlay: <AllwetterOverlay />,
  },
  {
    title: "Busch-AccessControl",
    img: BuschAccessControl,
    menuOverlay: <BuschAccessControlOverlay />,
  },
  {
    title: "Kamera",
    img: Kamera,
    menuOverlay: <DomeKamera />,
  },
];

export default function Haustuer() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.45,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Haustür" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Ein Ort, an dem sich Privatsphäre und öffentlicher Raum treffen
                Ein Ort für den komfortablen und sicheren Empfang Ihrer
                Besucher. Bei Tag und bei Nacht!
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
