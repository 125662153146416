import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/11.jpg";
import Overlay from "../../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-welcome/overlay.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-welcome/busch-smarttouch-10-zoll.png";

export default function BuschWelcomeOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-welcome",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://youtu.be/dWpPPOF2FQA",
          target: "_blank",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: Overlay,
              alt: "Kommunikation mit Smartphone und Tablet",
            },
          ],
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-SmartTouch<sup>®</sup> 10"
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Zu einer Sprechanlage gehört neben der Außenstation auch eine
                oder mehrere Innenstationen. Z.B. das SmartTouch 10“
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  <>
                    Zentrales Bedienpanel für Busch-Welcome® 2-Draht und
                    wahlweise Busch-free@home<sup>®</sup> oder
                    Busch-Installationsbus<sup>®</sup> KNX.
                  </>,
                  "In 8 Design- und Farbvarianten",
                  "Hörschleife zur Einkopplung des Audiosignals",
                  "App Anbindung/Fernzugriff für Tablet & Smartphone integriert",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                align="right"
                src={CenteredImg}
                alt='Busch-SmartTouch® 10"'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
