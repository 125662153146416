import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/8.jpg";
import Image9 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/9.jpg";
import Image10 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/10.jpg";
import Image11 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/11.jpg";
import Image12 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/12.jpg";
import Image13 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/13.jpg";
import Image14 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/14.jpg";
import Image15 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/15.jpg";
import Image16 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/16.jpg";
import Image17 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/17.jpg";
import Image18 from "../../../../../../../assets/renovation-und-neubau/funktion/flex/18.jpg";

const bedienelementItems1 = [
  {
    title: (
      <>
        Bedienelement
        <br />
        1-fach
      </>
    ),
    img: Image1,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren-wireless/1",
    },
  },
  {
    title: (
      <>
        Bedienelement
        <br />
        2-fach
      </>
    ),
    img: Image2,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren-wireless/2",
    },
  },
  {
    title: (
      <>
        Wetterstation
        <br />
        free@home
      </>
    ),
    img: Image3,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren-wireless/3",
    },
  },
  {
    title: (
      <>
        Busch-Wächter<sup>®</sup> 180 flex, Basic
      </>
    ),
    img: Image4,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren-wireless/4",
    },
  },
  {
    title: (
      <>
        Busch-Wächter<sup>®</sup> 180 flex, Komfort
      </>
    ),
    img: Image5,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren-wireless/5",
    },
  },
  {
    title: "Präsenzmelder",
    img: Image6,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren-wireless/6",
    },
  },
];

const bedienelementItems2 = [
  {
    title: (
      <>
        Bedienelement
        <br />
        1-fach
      </>
    ),
    img: Image7,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren/1",
    },
  },
  {
    title: (
      <>
        Bedienelement
        <br />
        2-fach
      </>
    ),
    img: Image8,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren/2",
    },
  },
  {
    title: "Komfortschalter",
    img: Image9,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren/3",
    },
  },
  {
    title: (
      <>
        Busch-Wächter<sup>®</sup> 180 flex, Basic
      </>
    ),
    img: Image13,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren/4",
    },
  },
  {
    title: (
      <>
        Busch-Wächter<sup>®</sup> 180 flex, Komfort
      </>
    ),
    img: Image14,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren/6",
    },
  },
  {
    title: "Präsenzmelder",
    img: Image15,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#sensoren/7",
    },
  },
];

const relaiItems = [
  {
    title: (
      <>
        Relais-Einsatz
        <br />
        1-fach
      </>
    ),
    img: Image10,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#uebersicht/5",
    },
  },
  {
    title: (
      <>
        Relais-Einsatz
        <br />
        2-fach
      </>
    ),
    img: Image11,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#uebersicht/6",
    },
  },
  {
    title: (
      <>
        Jalousie-Einsatz
        <br />
        1-fach
      </>
    ),
    img: Image12,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#uebersicht/9",
    },
  },
  {
    title: "eContact",
    img: Image16,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#uebersicht/7",
    },
  },
  {
    title: "LED-Dimmer",
    img: Image17,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#uebersicht/8",
    },
  },
  {
    title: "Nebenstelle",
    img: Image18,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/busch-flex#uebersicht/10",
    },
  },
];

export default function FlexOverlay() {
  return (
    <Slide
      background
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-freeathome-flex",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QHeader title="flex" arrow />
              <QBodyText>
                Flex macht das die Elektroinstallation durch die einheitliche
                Schnittstelle „flex“ besonders flexibel. Heute ein
                konventioneller Jalousieschalter oder LED-Tastdimmer, morgen ein
                Gerät, das man mit Zusatzfunktionen durch Tausch des
                Bedienelements nutzen kann. Z.B. Fernbedienung, Zeitschaltuhr,
                Astrodaten. Und übermorgen vielleicht vernetzen mit anderen
                Geräte zum Smart Room oder Smart Home.
              </QBodyText>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {bedienelementItems1.map((e, i) => {
                  return (
                    <Grid item xs={6} md={4} xl={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {bedienelementItems2.map((e, i) => {
                  return (
                    <Grid item xs={6} md={4} xl={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {relaiItems.map((e, i) => {
                  return (
                    <Grid item xs={6} md={4} xl={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
