import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/37.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/fi-schukomat/fi-schukomat.png";
import FISchukomat from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/fi-schukomat/fi-schukomat-bauteil.png";
import Overlay1 from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/fi-schukomat/overlay-1.jpg";
import Overlay2 from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/fi-schukomat/overlay-2.jpg";

export default function FISchukomatOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/fi-schukomat",
          target: "_blank",
        },
        {
          id: "distance",
          type: "galery",
          img: "info",
          images: [
            {
              src: Overlay1,
              alt: "Installationsbeispiele 1",
            },
            {
              src: Overlay2,
              alt: "Installationsbeispiele 2",
            },
          ],
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="FI-SCHUKOMAT" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Strom ist nützlich, kann aber auch sehr gefährlich sein. Wenn es
                im Badezimmer nass wird, hilft eine Steckdose mit
                Fehlerstrom-Schutzschalter. Sie trennt den Strom, wenn es darauf
                ankommt, in Millisekunden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Höchster Schutz bei Feuchtigkeit direkt vor Ort",
                  "Der FI Schutzschalter schützt vor gefährlichen Körperströmen",
                  "Erhöhter Berührungsschutz",
                  "Vermeidung von Unfällen",
                  "Einfachste Nachrüstung, als Unterputz- oder Aufputz-Variante",
                ]}
              />
              <ImageComponent
                wrapperWidth="100%"
                align="left"
                col={4}
                images={[
                  {
                    src: FISchukomat,
                    alt: "FI-Schukomat",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="FI Schukomat"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
