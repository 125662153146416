import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/9.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/AllwetterIP44/allwetter-ip-44-up-3-fach.png";
import Schuko from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/AllwetterIP44/allwetter-ip-44-up.jpg";
import Bewegungsmelder from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/AllwetterIP44/allwetter-ip-44-bewegungsmelder.jpg";
import SchluesselJalousieSteuerung from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/AllwetterIP44/allwetter-ip-44-schliessung.jpg";

export default function AllwetterIP44Overlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/allwetter-44",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Allwetter IP44 uP" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Dieses Programm trotzt mit Schutzart IP 44 Wind wie auch Wetter
                und trägt damit seinen Namen völlig zu Recht. In der Werkstatt
                oder auf der Terrasse sind die robusten Geräte dieser
                bruchfesten und witterungsbeständigen Serie die richtige Wahl.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Kombinationen 1-3 fach",
                  "Adapter zu ReflexSI erlaubt die Aufnahme von Daten-, Sat, Mulimedia-Anschlussdosen im Außenbereich",
                  "Farben in Weiß, alpinweiß, alusilber, braun und anthrazit greifen die farbliche Architektur des Gebäudes auf.",
                ]}
              />
              <ImageComponent
                wrapperWidth="80%"
                align="left"
                col={4}
                images={[
                  {
                    src: Schuko,
                    alt: "Allwetter IP44 uP Schuko",
                  },
                  {
                    src: Bewegungsmelder,
                    alt: "Allwetter IP44 uP Bewegungsmelder",
                  },
                  {
                    src: SchluesselJalousieSteuerung,
                    alt: "Allwetter IP44 uP Jalousie Schlüsselschalter",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
