import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../../../components/CenteredImage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/31.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/schlafen/Timer-Jalousiesteuerung/timer-jaloisiesteuerung.png";

export default function TimerJalousiesteuerungOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-jalousiecontrol-ii",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Standard/Komfort-Timer" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Busch-Jalousiecontrol® II ist ein modulares System, das mit den
                unterschiedlichen Bedienelementen zu maximalem Wohnkomfort
                führt. Ausgestattet mit einem Standard-/Komfort lässt sich die
                Jalousie komfortabel steuern
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Jalousiesteuerung für jeden Tag individuell programmierbar",
                  "Beleuchtetes hochauflösendes Display",
                  "Auf-/Ab-/Urlaubsfunktion",
                  "Astro",
                  "Optionaler Glasbruch/Sonnensensor",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Rauchwarnmelder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
