import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../../components/CenteredImage";
import ImageComponent from "../../../../../../../components/ImageComponent";
import PDFViewer from "../../../../../../../components/PDFViewer";

export default function Heizungssteuerung() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/heizungssteuerung.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../../assets/pdf/preisbeispiele/Smarter-Home/Preisbeispiel Smarter Home - Heizungssteuerung.pdf",
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../../assets/tuerkommunikation/smartphone/heizungssteuerung.png"
        alt={<>Busch-free@home<sup>®</sup> Next App</>}
        height="50vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Box style={{ paddingRight: "100px" }}>
              <QBodyText fontWeight="bold" marginBottom="0px">
                Heizungssteuerung
              </QBodyText>
              <QBodyText>
                Die ideale Raumtemperatur zu jeder Tages- und Nachtzeit dank
                präziser Steuerung.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Komfort- & Eco-Betrieb",
                  "Zeitfunktion für unterschiedliche Sollwerte im Tagesverlauf",
                  "Alle Heizungssysteme wie Fußbodenheizung, Radiatorheizung oder Kühlsysteme",
                  "Geofencing (Heizen nur bei Anwesenheit)",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Heizungssteuerung",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/heizungssteuerung-left.jpg",
                  },
                  {
                    alt: "Thermostat",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/heizungssteuerung-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
