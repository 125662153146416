import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/38.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/led-dimmer-flex/led-dimmer-flex.jpg";

export default function LEDDimmerFlexOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-tastdimmer",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="LED-Dimmer flex" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Auch im Kinderzimmer sind Dimmer einer sinnvolle Investition.
                Das Licht vor dem Schlafengehen schon mal etwas dimmen damit die
                Kinder zur Ruhe kommen. Auch praktisch: Auf dem Bedienelement
                2-fach können Lichtwerte abgespeichert werden. Z.B. leicht
                angedimmtes Licht für einen nächtlichen „Kontrollgang“.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Komfortable Bedienung",
                  "Nebenstellenbetrieb",
                  "Presets/Helligkeits-Werte speicherbar",
                  "Symbole auf den Wippen erleichtern die Zuordnung",
                  "Fernbedienbar vom Bett (Bedienelement WL)",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                shadow
                wrapperHeight="35vh"
                src={CenteredImg}
                alt="LED-Dimmer flex"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
