import { h } from "preact";
import { Grid } from "@material-ui/core";
import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/schalter/allwetter.jpg";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import CenteredImage from "../../../../../../components/CenteredImage";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

export default function Allwetter() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/allwetter-44",
          target: "_blank",
        },
      ]}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/allwetter.png"
        alt={
          <>
            Allwetter 44<sup>®</sup> alusilber
          </>
        }
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                Allwetter 44<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QList
                items={[
                  "Mit allen Wassern gewaschen",
                  "In der Werkstatt oder auf der Terrasse sind die robusten Geräte dieser bruchfesten und witterungsbeständigen Serie die richtige Wahl",
                  "Erhältlich in 1-fach bis 3-fach",
                  "Waagerechte Montage möglich",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/allwetter/braun.jpg",
                    alt: (
                      <>
                        Allwetter 44<sup>®</sup> braun
                      </>
                    ),
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/allwetter/studioweiss.jpg",
                    alt: (
                      <>
                        Allwetter 44<sup>®</sup> studioweiß
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
