import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/9.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/bewegungsmelder-70-110/bewegungsmelder-70-110.png";

export default function Bewegungsmelder1Overlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/bewegungsmelder",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Bewegungsmelder" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Oft ist weniger mehr!
                <br />
                Die Busch-Wächter® 70/110 MasterLINE haben eine reduzierte
                Erfassung und bieten sich an für Durchgänge oder für Bereiche
                zum Nachbargrundstück. Die Wächter schalten die Beleuchtung dann
                nur bei Bewegung auf dem eigenen Grundstück ein. Das spart
                zusätzlich Energie und reduziert die Lichtemission
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Verschiede Erfassungsreichweiten",
                  "Verschiedene Bauformen und Farben",
                  "Automatische Rauschunterdrückung",
                  "Automatische Reichweitenstabilisieurung (Sommer/Winter)",
                  "Optional: Fernbedienung",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="70vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
