import Uebersicht from "./slides/Uebersicht";
import FlexOverlay from "./slides/Flex";
import JalousiemanagementOverlay from "./slides/Jalousiemanagement";
import RaumklimaOverlay from "./slides/Raumklima";
import BewegungsmelderOverlay from "./slides/BewegungsmelderOutdoor";
import IP44Overlay from "./slides/IP44";
import BeleuchtungssteuerungOverlay from "./slides/Beleuchtungssteuerung";
import MultimediaOverlay from "./slides/Multimedia";
import KommunikationOverlay from "./slides/Kommunikation";
import BewegungsmelderIndoor from "./slides/BewegungsmelderIndoor";
import Tuerkommunikation from "./slides/Tuerkommunikation";
import Zutrittskontrolle from "./slides/Zutrittskontrolle";
import Sicherheit from "./slides/Sicherheit";

export default function Funktion() {
  return [
    <Uebersicht key="uebersicht" />,
    <FlexOverlay key="flex-tronics" />,
    <JalousiemanagementOverlay key="jalousie-management" />,
    <RaumklimaOverlay key="raumklima" />,
    <BewegungsmelderIndoor key="bewegungsmelder-indoor" />,
    <IP44Overlay key="ip-44" />,
    <BeleuchtungssteuerungOverlay key="beleuchtungssteuerung" />,
    <MultimediaOverlay key="multimedia" />,
    <KommunikationOverlay key="kommunikation" />,
    <BewegungsmelderOverlay key="bewegungsmelder" />,
    <Tuerkommunikation key="tuerkommunikation" />,
    <Zutrittskontrolle key="zutrittskontrolle" />,
    <Sicherheit key="sicherheit" />,
  ];
}
