import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../../../components/CenteredImage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/30.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/diele/rauchwarnmelder/rauchwarnmelder.png";

export default function RauchwarnmelderOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/rauchmelder-waermemelder",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://youtu.be/dWpPPOF2FQA",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Rauchwarnmelder" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Busch-Rauchalarm® Rauchwarnmelder sind VDS zertifiziert und
                müssen gesetzlich mindestens in allen Fluchtwegbereichen und
                Schlafräumen montiert werden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Stand alone oder vernetzbar",
                  "Auch mit CO-Melder und Wärmemelder kombinierbar",
                  "VDS/Q-Label zertifiziert",
                  "Anbindung free@home",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="25vh"
                src={CenteredImg}
                alt="Rauchwarnmelder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
