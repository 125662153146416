import {
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "preact/compat";
import QHeader from "../../../../../../../../components/QHeader";
import Slide from "../../../../../../../../components/Slide";

// Background Images
import BackgroundHolzEiche from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/holz-eiche.jpg";
import BackgroundSchieferTerraRosso from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/schiefer-terra-rosso.jpg";
import BackgroundAluminium from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/aluminium.jpg";
import BackgroundGlasSchwarzStrukturiert from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/glas-schwarz-strukturiert.jpg";
import BackgroundGlasGoldVerspiegelt from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/glas-gold-verspiegelt.jpg";
import BackgroundHolzWalnuss from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/holz-walnuss.jpg";
import BackgroundAluminiumSchwarz from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/aluminium-schwarz.jpg";
import BackgroundPapierSchwarz from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/papier-schwarz.jpg";
import BackgroundGlasBlau from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/background/glas-blau.png";

// Schalter Images
import HolzEiche from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/holz-eiche.png";
import SchieferTerraRosso1 from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/schiefer-terra-rosso-1.png";
import Aluminium1 from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/aluminium-1.png";
import Aluminium2 from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/aluminium-2.png";
import GlasSchwarzStrukturiert1 from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/glas-schwarz-strukturiert-1.png";
import GlasGoldVerspiegelt from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/glas-gold-verspiegelt.png";
import HolzWalnuss from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/holz-walnuss.png";
import SchieferTerraRosso2 from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/schiefer-terra-rosso-2.png";
import AluminiumSchwarz from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/aluminium-schwarz.png";
import PapierSchwarz from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/papier-schwarz.png";
import GlasSchwarzStrukturiert2 from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/glas-schwarz-strukturiert-2.png";
import GlasBlau from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-viertel/glas-blau.png";

export default function BuschArtlInearOverlay({ swiperRef }) {
  const itemData = [
    {
      title: ["Holz", "Eiche"],
      backgroundImg: BackgroundHolzEiche,
      schalterImg: HolzEiche,
      id: 1,
    },
    {
      backgroundImg: BackgroundSchieferTerraRosso,
      schalterImg: SchieferTerraRosso1,
      rows: 2,
      id: 2,
    },
    {
      title: ["Aluminium"],
      backgroundImg: BackgroundAluminium,
      schalterImg: Aluminium1,
      cols: 2,
      id: 3,
    },
    {
      schalterImg: Aluminium2,
      id: 3,
    },
    {
      backgroundImg: BackgroundGlasSchwarzStrukturiert,
      schalterImg: GlasSchwarzStrukturiert1,
      rows: 2,
      id: 4,
    },
    {
      title: ["Glas", "Gold", "Verspiegelt"],
      backgroundImg: BackgroundGlasGoldVerspiegelt,
      schalterImg: GlasGoldVerspiegelt,
      id: 5,
    },
    {
      title: ["Holz", "Walnuss"],
      backgroundImg: BackgroundHolzWalnuss,
      schalterImg: HolzWalnuss,
      id: 6,
    },
    {
      title: ["Schiefer", "Terra Rosso"],
      backgroundImg: "",
      schalterImg: SchieferTerraRosso2,
      id: 2,
    },
    {
      title: ["Aluminium", "Schwarz"],
      backgroundImg: BackgroundAluminiumSchwarz,
      schalterImg: AluminiumSchwarz,
      id: 7,
    },
    {
      title: ["Papier", "Schwarz"],
      backgroundImg: BackgroundPapierSchwarz,
      schalterImg: PapierSchwarz,
      id: 8,
    },
    {
      title: ["Glas", "Schwarz", "Strukturiert"],
      schalterImg: GlasSchwarzStrukturiert2,
      id: 4,
    },
    {
      title: ["Glas", "Blau"],
      backgroundImg: BackgroundGlasBlau,
      schalterImg: GlasBlau,
      id: 9,
    },
  ];
  const useStyles = makeStyles((theme) => ({
    root: {
      cursor: "pointer",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      width: "100%",
      height: "100%",
    },
    listItem: {
      padding: "0 !important",
      height: "calc((100vh - 194px) / 2) !important",
      aspectRatio: "1 / 1.8",
      zIndex: 20,
    },
    listItemDoubleHeight: {
      padding: "0 !important",
      height: "calc((100vh - 194px) / 1) !important",
      aspectRatio: "1 / 1.8",
      zIndex: 20,
    },
    imageListItemBar: {
      left: "inherit",
      right: "inherit",
      top: 0,
      bottom: 0,
      width: "100%",
      height: "auto",
      background: "none",
    },
    schalterClass: {
      width: "65%",
      aspectRatio: "1",
      position: "absolute",
    },
    backgroundImageList: {
      position: "absolute",
      zIndex: 10,
      width: "100%",
      height: "auto",
    },
  }));
  const classes = useStyles();

  const slideTo = (id) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(id);
    }
  };

  return (
    <Slide disablePadding>
      <div
        style={{
          padding: "3.5rem 3.5rem 3.5rem 3.5rem",
        }}
      >
        <QHeader
          title="Busch-art linear"
          subTitle="Nachhaltiges DESIGN für Raum und Zeit"
        />
      </div>
      <div className={classes.root}>
        <ImageList
          className={classes.imageList}
          cols={6}
          sx={{ position: "absolute", zIndex: 20 }}
        >
          {itemData.map((item, index) => {
            index++;
            let position = {};
            if (index < 7) {
              position.bottom = 0;
            }
            if (index > 7) {
              position.top = 0;
            }

            if (index % 2 === 0) {
              position.left = 0;
            } else {
              position.right = 0;
            }
            return (
              <ImageListItem
                key={`${index}-schalter`}
                cols={1}
                className={classes.listItem}
              >
                <div
                  onClick={() => slideTo(item.id)}
                  style={{
                    backgroundColor: "transparent",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {item.schalterImg && (
                    <img
                      src={item.schalterImg}
                      className={classes.schalterClass}
                      style={position}
                    />
                  )}
                  <ImageListItemBar
                    className={classes.imageListItemBar}
                    title={
                      <Typography
                        paragraph
                        style={{
                          textAlign: "left",
                          margin: "0px auto",
                          display: "block",
                          width: "fit-content",
                          fontSize: 16,
                          userSelect: "none",
                        }}
                      >
                        {item?.title?.map((text) => (
                          <>
                            {text}
                            <br />
                          </>
                        ))}
                      </Typography>
                    }
                    position="bottom"
                  />
                </div>
              </ImageListItem>
            );
          })}
        </ImageList>
        <ImageList
          className={classes.backgroundImageList}
          cols={6}
          variant="quilted"
        >
          {itemData.map((item, index) => {
            index++;
            return item?.backgroundImg ? (
              <ImageListItem
                key={`${index}-bg`}
                cols={item.cols || 1}
                rows={item.rows || 1}
                style={{
                  backgroundImage: "url(" + item.backgroundImg + ")",
                  backgroundSize: "cover",
                  transform: index > 6 ? "translateY(-100%)" : "none",
                }}
                className={
                  item.rows === 2
                    ? classes.listItemDoubleHeight
                    : classes.listItem
                }
              />
            ) : (
              index > 6 && (
                <ImageListItem
                  key={`${index}-bg`}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                />
              )
            );
          })}
        </ImageList>
      </div>
    </Slide>
  );
}
