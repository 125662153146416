import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../../../../components/ImageComponent";

// Images
import CenteredImg from "../../../../../../../../../../assets/renovation-und-neubau/raume/kueche/BuschRadioDABPlus/bluetooth-uebertragung.png";

export default function BluetoothUebertragung() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Bluetooth Übertragung" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Beim Busch-Radio BTconnect DAB+ kann man nicht nur seine
                Lieblingsmusik vom Handy zum Radio streamen und diese über die
                angeschlossenen Lautsprecher abspielen. Mann kann auch einen
                Bluetooth-Lautsprecher mit dem Radio koppeln und den
                eigenstellten Radiosender, z.B. im Garten wiedergeben.
              </QBodyText>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="40vh"
                align="center"
                src={CenteredImg}
                alt="Bluetooth Übertragung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
