import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../../../components/CenteredImage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/19.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/schlafen/Radio-inet-wecker/radio-inet-wecker.png";

export default function RadioiNetWeckerOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-radio-btconnect-dab",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Radio iNet" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Immer mit dem Lieblingssender geweckt werden. Digital und
                grenzenlos. Aus der Heimat oder vom Ende der Welt. Einfach über
                WLAN mit dem Busch-Radio iNet. In tadelloser Klangqualität, ohne
                Rauschen und Störungen. Inklusive komfortabler Zusatzfunktionen
                wie Wecker und automatischem Ein- oder Ausschalten.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Ausschließlicher Empfang über WLAN",
                  "Senderwahl über tunein.com",
                  "Steuerung über Smartphone und Tablet möglich",
                  "Wiedergabe von Audiodateien eines UPnP-Servers",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Rauchwarnmelder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
