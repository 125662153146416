import { Box, Grid } from "@material-ui/core";

import backgroundLichtschalter from "../../../../../../assets/cwa/background/titelbilder/schalter/axcent-pur-glas.jpg";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QProductGroup from "../../../../../../components/QProductGroup";
import QBodyText from "../../../../../../components/QBodyText";
import Preisliste from "../preisliste";
import CenteredImage from "../../../../../../components/CenteredImage";

const itemlist = [
  {
    src: "../../../../../../assets/cwa/system/22_1.png",
    alt: <>Busch-axcent<sup>®</sup> pur - Glas Auster</>,
  },
  {
    src: "../../../../../../assets/cwa/system/axcent_crystalAvocado.png",
    alt: <>Busch-axcent<sup>®</sup> pur - Glas Avocado</>,
  },
  {
    src: "../../../../../../assets/cwa/system/axcent_crystalOcean.png",
    alt: <>Busch-axcent<sup>®</sup> pur - Glas Ocean</>,
  },
  {
    src: "../../../../../../assets/cwa/system/axcent_crystalPurpur.png",
    alt: <>Busch-axcent<sup>®</sup> pur - Glas Purpur</>,
  },
  {
    src: "../../../../../../assets/cwa/system/axcent_crystalSonne.png",
    alt: <>Busch-axcent<sup>®</sup> pur - Glas Sonne</>,
  },
  {
    src: "../../../../../../assets/cwa/system/22_2.png",
    alt: <>Busch-axcent<sup>®</sup> pur - Glas Koralle</>,
  },
];

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produkte/schalterdesign/busch-balance-si",
    target: "_blank",
  },
  {
    type: "dialog",
    img: "preisliste",
    children: <Preisliste anchor="busch-axcent-pur" />,
  },
];

export default function AxcentPurGlas() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundLichtschalter,
      }}
    >
      <CenteredImage
        images={itemlist}
        width="30vw"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Lichtschalter" subTitle="Busch-axcent® pur Glas" />
        </Grid>
        <Grid item container>
          <Grid item xs={4} style={{ paddingRight: "25px" }}>
            <QBodyText>Setzt farbige axcente</QBodyText>
            <QList
              title="Vorteile"
              items={[
                "Echtglas Rahmen",
                "Rückseitig bedruckt für brilliantere Farben",
                "Satin matt",
                "Erhältlich in 1-fach bis 5-fach",
              ]}
              dense
              
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
