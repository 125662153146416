import {
  Box,
  CircularProgress,
  Dialog,
  Fade,
  IconButton,
  Paper,
  Slide,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import useWindowSize from "../../hooks/windowSize";
import ControlPanel from "./ControlPanel";
import CloseIcon from "../SvgIcons/CloseIcon";
import { setAllowScrolling } from "../../static/fullpageHelpers";
import PDFDrawer from "./PDFDrawer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: `cmaps/`,
  cMapPacked: true,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    "& .react-pdf__Page__canvas": {
      height: "calc(100dvh - 64px)",
      width: "auto",
    },
    "& .MuiPaper-root": {
      width: "fit-content",
    },
  },
  paper: {
    width: "fit-content",
    margin: "0px auto",
    "& .react-pdf__Page__textContent": {
      overflow: "hidden",
    },
  },
  closeButton: {
    position: "fixed",
    right: "0",
    top: "10px",
    right: "10px",
    zIndex: "999",
  },
}));

export default function BetterPDFViewer({ path, onCancel, open }) {
  const classes = useStyles();
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const windowSize = useWindowSize();
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  React.useEffect(() => {
    setAllowScrolling(!open);
  }, [open]);

  const toggleDrawer = () => {
    setIsDrawerOpen((v) => !v);
  };
  return (
    <>
      <PDFDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
        pdf={{
          options: options,
          file: path,
          pageNumber: pageNumber,
          numPages: numPages,
          setPageNumber: setPageNumber,
        }}
      />{" "}
      <Dialog onCancel={onCancel} open={open} fullScreen>
        <IconButton
          className={classes.closeButton}
          color="inherit"
          onClick={onCancel}
          aria-label="close"
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        {isLoading && (
          <Box
            sx={{
              height: "100dvh",
              width: "100dvw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              userSelect: "none",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}
        <Slide
          direction="up"
          timeout={1000}
          in={!isLoading}
          mountOnEnter
          unmountOnExit
        >
          <ControlPanel
            toggleDrawer={toggleDrawer}
            path={path}
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </Slide>
        <Box p={3} overflow="scroll">
          <Fade in={!isLoading} timeout={1000}>
            <Paper square elevation={8} className={classes.paper}>
              <Document
                loading={<></>}
                options={options}
                onLoadSuccess={onDocumentLoadSuccess}
                title="Test"
                file={path}
              >
                <Page
                  height={(windowSize.height - 64 - 8) * 0.9}
                  pageNumber={pageNumber}
                  scale={scale}
                />
              </Document>
            </Paper>
          </Fade>
        </Box>
      </Dialog>
    </>
  );
}
