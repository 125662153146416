import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/40.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/schuko-safety-plus/schuko-safety.jpg";

export default function SchukoSteckdoseSafetyPlusOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/schuko-steckdose-mit-integriertem-erhoehtem-beruehrungsschutz",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="SCHUKO® Steckdose Safety+" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Die SCHUKO® Steckdose mit integriertem erhöhtem Berührungsschutz
                bietet mehr Sicherheit vor den Gefahren des Stroms. Im Gegensatz
                zu nachträglich montierten Einsätzen besteht die Produktgarantie
                des VDE-Prüfzeichens. Die Steckdose ist problemlos nutzbar und
                das Design unbeeinträchtigt.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Verriegelung öffnet nur bei gleichzeitigem Druck auf beide Öffnungen",
                  "Gewohnte bequeme Bedienung",
                  "Nachgerüstete „Teddys“ sind trügerisch und entsprechen nicht den VDE-Bestimmungen",
                  "Ganzheitliches Schalterdesign ohne störende Aufsätze",
                  "In allen Schalterdesigns erhältlich",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                shadow
                src={CenteredImg}
                alt="SCHUKO® Steckdose Safety+"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
