import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import QAccordion from "../../../../../../../components/QAccordion";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Radio() {
  const itemlist = [
    {
      title: "Vorteile",
      content: (
        <QList
          items={[
            "Beste Klangqualität, ohne Störungen",
            "Hochauflösendes Display",
            "Technisch hochwertige Komponenten",
            "Innovatives Design",
          ]}
          dense
          variant="arrow"
        />
      ),
    },
    {
      title: "Hoher Komfort durch Funktionen",
      content: (
        <QList
          items={[
            "Sleep-/Weckfunktion",
            "Stereo-/Monoumschaltung",
            "Bluetooth Sender/Empfänger",
          ]}
          dense
          variant="arrow"
        />
      ),
    },
  ];

  return (
    <Slide
      background={{
        src: "../../../../../../../assets/SchaltenUndSteuern/background/radio.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        component={() => (
          <iframe
            src="https://js-staging.s3.eu-central-1.amazonaws.com/bje-radio-dab/build/index.html"
            width="466"
            height="466"
            frameBorder="none"
            style={{ transform: "scale(0.7)" }}
          />
        )}
        alt="USB-C & USB Ladebuchse"
        height="33vh"
        containerWidth="auto"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Multimedia" />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
              <QBodyText fontWeight="bold" marginBottom="0px">
                Radio
              </QBodyText>
              <QBodyText>
                Platzsparend installiert in einer UP-Dose, sorgen das
                Busch-Radio DAB+, das Busch-Radio BTconnect DAB+ und das
                Busch-Radio iNet höchst dezent für die passende Atmosphäre
              </QBodyText>
              <QAccordion items={itemlist} fontSize="1rem" />
            <Box display="flex">
              <ImageComponent
                wrapperWidth="50%"
                align="left"
                alt="DAB+ Radio"
                src="../../../../../../../assets/SchaltenUndSteuern/produkte/radio-left.jpg"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
