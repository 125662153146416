import { h } from "preact";
import { Grid } from "@material-ui/core";
import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/schalter/carat-porzellan.jpg";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import CenteredImage from "../../../../../../components/CenteredImage";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produkte/schalterdesign/carat",
    target: "_blank",
  },
  {
    type: "dialog",
    img: "preisliste",
    children: <Preisliste anchor="carat" />,
  },
];

export default function CaratPorzellan() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/carat.png"
        alt={
          <>
            carat<sup>®</sup> Porzellan
          </>
        }
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                carat<sup>®</sup> Porzellan
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid conainer>
            <Grid item xs={4}>
              <QList
                items={[
                  "Echtmaterial",
                  "Unikat",
                  <>
                    Ergänzung der exklusiven Materialien von carat<sup>®</sup>
                    um eine Variante aus Porzellan
                  </>,
                  "Außenkanten sind materialtypisch abgerundet",
                  "Edles Finish in mattem Anthrazit",
                  "Porzellan jetzt auch als quadratischer Schalterrahmen",
                  "Jeweils 1-fach bis 3-fach Rahmen",
                  "Flat-Installation möglich",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/carat/chrom.jpg",
                    alt: "studioweiß / Chrom",
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/carat/glas-ws.jpg",
                    alt: "studioweiß / Glas weiß",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
