import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function AutomatischesLicht() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/SchaltenUndSteuern/background/automatisches-licht.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/SchaltenUndSteuern/produkte/automatisches-licht.jpg"
        alt="Bewegungssensor"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Bewegungsmelder" />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Automatisches Licht
            </QBodyText>
            <QBodyText>
              Beleuchtungen lassen sich komfortabel und effizient durch
              Bewegungsmelder steuern und bieten mehr Sicherheit vor
              Stolperfallen.
            </QBodyText>
            <Box style={{ paddingRight: "130px" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Energieeffizient",
                  "Licht nur dann wenn man es braucht",
                  "Unterschiedliche Erfassungslinsen für verschiedenen Einbausituationen",
                  "Optional mit Vor-Ort-Bedienung",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <ImageComponent
              col={4}
              align="left"
              images={[
                {
                  alt: "Busch-Wächter® 180 flex",
                  src: "../../../../../../../assets/SchaltenUndSteuern/produkte/automatisches-licht-left.jpg",
                },
                {
                  alt: "Bewegungssensor",
                  src: "../../../../../../../assets/SchaltenUndSteuern/produkte/automatisches-licht-right.jpg",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
