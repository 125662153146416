import { h } from "preact";
import { Grid } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";

export default function Sicherheit2() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/sicherheit-2.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Sicherheit
            </QBodyText>
            <QBodyText>
              Sicherheit hört bei Einbruchschutz nicht auf. Auch ein
              Smart-Home-System sollte so sicher wie möglich sein, um
              Eindringlingen den Zutritt zu den eigenen vier Wänden zu
              verwehren.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Neutrale externe Überprüfung",
                "Vielseitige Prüfung wie Cloud, Datenübermittlung, Wireless-Vernetzung, Passwortschutz",
                "Busch-Jaeger und ABB als renommierte verantwortungsbewusste Partner in Europa",
              ]}
              dense
              variant="arrow"
            />
            <ImageComponent
              wrapperWidth="70%"
              alt="VDE Zertifikate"
              align="left"
              src="../../../../../../../assets/tuerkommunikation/produkte/vde-zertifikat.svg"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
