const SET_SALUTATION = "playbook/settings/SET_SALUTATION";
const SET_FIRSTNAME = "playbook/settings/SET_FIRSTNAME";
const SET_LASTNAME = "playbook/settings/SET_LASTNAME";
const SET_EMAIL = "playbook/settings/SET_EMAIL";
const SET_PHONENR = "playbook/settings/SET_PHONENR";
const SET_FAXNR = "playbook/settings/SET_FAXNR";
const SET_STREETANDNR = "playbook/settings/SET_STREETANDNR";
const SET_ZIPCODE = "playbook/settings/SET_ZIPCODE";
const SET_PLACE = "playbook/settings/SET_PLACE";
const SET_COMPANY = "playbook/settings/SET_COMPANY";
const SET_ZVPHONENR = "playbook/settings/SET_ZVPHONENR";
const SET_THEME = "playbook/settings/SET_THEME";
const ADD_CATEGORY = "playbook/settings/ADD_CATEGORY";
const REMOVE_CATEGORY = "playbook/settings/REMOVE_CATEGORY";
const REST_CUSTOM_CATEGORIES = "playbook/settings/REST_CUSTOM_CATEGORIES";

const initialState = {
  salutation: "Herr",
  firstname: "",
  lastname: "",
  email: "",
  phonenr: "",
  faxnr: "",
  streetandnr: "Freisenbergstr. 2",
  zipcode: "58513",
  place: " Lüdenscheid",
  company: "Busch-Jaeger Elektro GmbH",
  zvphonenr: "02351 956-1600",
  theme: "bje",
  customCategories: [],
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SET_SALUTATION: {
      return {
        ...state,
        salutation: action.salutation,
      };
    }
    case SET_FIRSTNAME: {
      return {
        ...state,
        firstname: action.firstname,
      };
    }
    case SET_LASTNAME: {
      return {
        ...state,
        lastname: action.lastname,
      };
    }
    case SET_EMAIL: {
      return {
        ...state,
        email: action.email.toLowerCase().trim(),
      };
    }
    case SET_PHONENR: {
      return {
        ...state,
        phonenr: action.phonenr,
      };
    }
    case SET_FAXNR: {
      return {
        ...state,
        faxnr: action.faxnr,
      };
    }
    case SET_STREETANDNR: {
      return {
        ...state,
        streetandnr: action.streetandnr,
      };
    }
    case SET_ZIPCODE: {
      return {
        ...state,
        zipcode: action.zipcode,
      };
    }
    case SET_PLACE: {
      return {
        ...state,
        place: action.place,
      };
    }
    case SET_COMPANY: {
      return {
        ...state,
        company: action.company,
      };
    }
    case SET_ZVPHONENR: {
      return {
        ...state,
        zvphonenr: action.zvphonenr,
      };
    }
    case SET_THEME: {
      return {
        ...state,
        theme: action.theme,
      };
    }
    case ADD_CATEGORY: {
      return {
        ...state,
        customCategories: [...state.customCategories, action.category],
      };
    }
    case REMOVE_CATEGORY: {
      return {
        ...state,
        customCategories: state.customCategories.filter(
          (c) => c !== action.category
        ),
      };
    }
    case REST_CUSTOM_CATEGORIES: {
      return {
        ...state,
        customCategories: [],
      };
    }
    default:
      return state;
  }
};

//actions
export const setADMSalutation = (salutation) => ({
  type: SET_SALUTATION,
  salutation,
});

export const setADMFirstname = (firstname) => ({
  type: SET_FIRSTNAME,
  firstname,
});

export const setADMLastname = (lastname) => ({
  type: SET_LASTNAME,
  lastname,
});

export const setADMEmail = (email) => ({
  type: SET_EMAIL,
  email,
});

export const setADMPhoneNumber = (phonenr) => ({
  type: SET_PHONENR,
  phonenr,
});

export const setADMFaxNumber = (faxnr) => ({
  type: SET_FAXNR,
  faxnr,
});

export const setADMStreetAndNumber = (streetandnr) => ({
  type: SET_STREETANDNR,
  streetandnr,
});

export const setADMZipcode = (zipcode) => ({
  type: SET_ZIPCODE,
  zipcode,
});

export const setADMPlace = (place) => ({
  type: SET_PLACE,
  place,
});

export const setADMCompany = (company) => ({
  type: SET_COMPANY,
  company,
});

export const setZVPhoneNr = (zvphonenr) => ({
  type: SET_ZVPHONENR,
  zvphonenr,
});

export const setTheme = (theme) => {
  if (typeof window !== "undefined") {
    document.body.classList.remove(theme === "abb" ? "bje" : "abb");
    document.body.classList.add(theme);
  }
  return {
    type: SET_THEME,
    theme,
  };
};

export const addCategory = (category) => ({
  type: ADD_CATEGORY,
  category,
});

export const removeCategory = (category) => ({
  type: REMOVE_CATEGORY,
  category,
});

export const resetCustomCategories = () => ({
  type: REST_CUSTOM_CATEGORIES,
});

//selector
export const getADMSalutation = (state) => state.settings.salutation;
export const getADMFirstname = (state) => state.settings.firstname;
export const getADMLastname = (state) => state.settings.lastname;
export const getADMEmail = (state) => state.settings.email;
export const getADMPhoneNumber = (state) => state.settings.phonenr;
export const getADMFaxNumber = (state) => state.settings.faxnr;
export const getADMStreetAndNumber = (state) => state.settings.streetandnr;
export const getADMZipcode = (state) => state.settings.zipcode;
export const getADMPlace = (state) => state.settings.place;
export const getADMCompany = (state) => state.settings.company;
export const getZVPhoneNumber = (state) => state.settings.zvphonenr;
export const getTheme = (state) => state.settings.theme;
export const getSettingsData = (state) => state.settings;
export const getCustomCategories = (state) => state.settings.customCategories;

export default settings;
