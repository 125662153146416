import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import QSwiperJS from "../../../../../../../../../components/QSwiperJS";
import BuschWaechter180Flex from "./Overlays/Busch-Waechter180-flex";
import Nebenstellenbetrieb from "./Overlays/Nebenstellenbetrieb";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/28.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder-multi/busch-waechter-180-flex.png";

export default function BewegungsmelderMultiOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-freeathome-flex",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://www.busch-jaeger.de/bje-flex/static/media/Treppenaufgang-Video_DE.af3430d876d1b337d33c.mp4",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "info",
          children: (
            <QSwiperJS>
              <BuschWaechter180Flex />
              <Nebenstellenbetrieb />
            </QSwiperJS>
          ),
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Wächter® 180 flex" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Bewegungsmeldersensor mit Umgebungslichtmessung. Zum
                automatischen Schalten von Verbrauchern in Abhängigkeit von
                Bewegung und Helligkeit, sind komfortabel, energieeffizient und
                schaffen Sicherheit. Z.B. in Dielen und Treppenbereichen.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Verschiedene Erfassungsreichweiten und Erfassungslinse für jede Anwendung",
                  "Ins Schalterdesign integrierbar",
                  "Manuelles Schalten vor Ort möglich",
                  "Erweiterbar durch Nebenstellenbetrieb",
                  "Tag-/Nachtumschaltung in Verbindung mit LED-Dimmer flex",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Bewegungsmelder Multilinse"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
