import { Button, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../../../../components/ImageComponent";

// Images
import NebenstellenbetriebImg from "../../../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder-multi/nebenstellenbetrieb.png";

export default function Nebenstellenbetrieb({ swiperRef }) {
  const goToSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Nebenstellenbetrieb" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Man kann mehrere Bewegungs-, Präsenzmelder und Komfortschalter
                miteinander kombinieren. Dadurch wird die Erfassung bei
                komplexen Flurverläufen sicher gewährleistet.
              </QBodyText>
              <Button variant="contained" onClick={goToSlide}>
                Tag-/Nachtbetrieb
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperWidth="100%"
                src={NebenstellenbetriebImg}
                alt="Darstellung Nebenstellenbetrieb"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
