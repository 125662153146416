import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import SVGInteraktionFunktion from "../SVGInteraktionFunktion";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export default function Uebersicht() {
  const theme = useTheme();
  const upLG = useMediaQuery(theme.breakpoints.up(2200));

  const switchClick = (e, v) => {
    fullpage_api.silentMoveTo("raeume");
  };

  const MuiToggleButton = styled(ToggleButton)({
    backgroundColor: "#000 !important",
    borderRadius: "100px",
    border: "1px solid black",
    color: "#fff !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    transition: "box-shadow 250ms ease-in-out, border 250ms ease-in-out",
    "&.Mui-selected": {
      backgroundColor: "#fff !important",
      color: "#000 !important",
    },
  });

  return (
    <>
      <Slide
        disablePadding
        actions={[
          {
            type: "icon",
            img: "laptop",
            target: "_blank",
            text: "Lebens(t)räume",
            link: "https://playbookb2c.lightsail-aws.qmarketing.de/smarter-home#lebensraeume",
          },
          {
            type: "icon",
            img: "konfiguratoren",
            target: "_blank",
            text: "Baustellenplaner",
            link: "https://baustellenplaner.busch-jaeger.de",
          },
        ]}
      >
        <Box padding="1.5rem 1.5rem 3.5rem 1.5rem">
          <Box
            height="100vh"
            position="absolute"
            top={upLG ? "-8h" : 0}
            left={0}
            sx={{
              background:
                "linear-gradient(90deg, rgba(170,175,173,1) 0%, rgba(178,178,178,1) 100%)",
            }}
          >
            <SVGInteraktionFunktion />
          </Box>
          <Grid container direction="row">
            <Grid item xs={12} style={{ zIndex: 1 }}>
              <QHeader
                colWidth={12}
                title="Lösungen nach Funktion"
              />
              <ToggleButtonGroup>
                <MuiToggleButton onClick={switchClick} selected={true}>
                  Räume
                </MuiToggleButton>
                <MuiToggleButton selected={false}>Funktionen</MuiToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </>
  );
}
