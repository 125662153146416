import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../../components/ChapterCard";

// Background Images
import BackgroundImgOcean from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/50.jpg";

// Placeholder
import AbschlSteckdosenImg from "../../../../../../../../assets/renovation-und-neubau/raume/garage/abschl-steckdose.jpg";
import WaermemelderImg from "../../../../../../../../assets/renovation-und-neubau/raume/garage/waermemelder.jpg";
import FISchukomatImg from "../../../../../../../../assets/renovation-und-neubau/raume/garage/fi-schukomat.jpg";
import TerraACWallboxImg from "../../../../../../../../assets/renovation-und-neubau/raume/garage/terra-ac-walbox.jpg";
import VerteilerMistralImg from "../../../../../../../../assets/renovation-und-neubau/raume/garage/verteiler-mistral.jpg";

// Overlay
import OceanIP44Overlay from "../../../../../_globalSlides/OceanIP44Overlay";
import WaermemelderOverlay from "./slides/Waermemelder";
import FISchukomatOverlay from "./slides/FISchukomat";
import TerraACWalboxOveray from "./slides/TerraACWallbox";
import VerteilerMistralOverlay from "./slides/VerteilerMistral";

const items = [
  {
    title: "Abschl. Steckdose",
    img: AbschlSteckdosenImg,
    menuOverlay: <OceanIP44Overlay backgroundImg={BackgroundImgOcean} />,
  },
  {
    title: "Wärmemelder",
    img: WaermemelderImg,
    menuOverlay: <WaermemelderOverlay />,
  },
  {
    title: "FI-Schukomat",
    img: FISchukomatImg,
    menuOverlay: <FISchukomatOverlay />,
  },
  {
    title: "Terra AC Wallbox",
    img: TerraACWallboxImg,
    menuOverlay: <TerraACWalboxOveray />,
  },
  {
    title: "Verteiler Mistral",
    img: VerteilerMistralImg,
    menuOverlay: <VerteilerMistralOverlay />,
  },
];

export default function Garage() {
  return (
    <Slide
      background={{
        src: BackgroundImgOcean,
        width: 0.45,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Garage/Hobby" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Wir parken hier nicht nur unsere Autos. Eine Garage sollte
                praktisch und komfortabel sein. Hauptsächlich um alles
                unterzubringen, was im Freien verwendet wird.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
