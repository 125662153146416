import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/21.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/friends-of-hue/bluetooth-smart-switch.png";

export default function FriendsOfHueOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/friends-of-hue",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Friends of Hue" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Friends of Hue smart switch. Damit wird die Steuerung Ihrer
                Philips Hue-Leuchtmittel zum stimmungsvollen Vergnügen. Und das
                von jeder beliebigen Stelle im Raum aus bis in den Garten
                hinein. Frei und ungebunden. Ganz einfach drahtlos ohne
                Batterien – per smarter Funktechnologie.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Smarter Lichtschalter zum Schalten/Dimmen von Philips Hue Leuchtmitteln",
                  "Platzierung eines zusätzlichen Lichtschalters an einer beliebigen Stelle im Haus, ohne dass eine Verkabelung erforderlich ist",
                  "Erfordert Philips Hue Bridge v2 (Eckige Bauform) und Philips Hue app (v3.2 oder höher)",
                  "Maximale Funktionssicherheit durch Zigbee-Mesh-Technologie",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
