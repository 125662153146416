import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/43.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/SchukoBeschriftungsfeld/schuko-steckdose-mit-kennzeichnung.jpg";
import EDVImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/SchukoBeschriftungsfeld/schuko-steckdose-mit-kennzeichnung.jpg";

export default function USBLadegeraetOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/schuko-steckdose-mit-beschriftungsfeld",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                SCHUKO<sup>®</sup> Steckdose mit Beschriftungsfeld
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Mit dem praktischen Beschriftungsfeld ist die eindeutige
                Kennzeichnung von Steckdosen besonders einfach und dezent.
                Schnell die zugehörige Sicherung finden oder das kennzeichnen
                von Besonderen Schaltkreisen oder schaltbaren Steckdosen.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Eindeutige Kennzeichnung",
                  "Zuordnung von Sicherungen",
                  "Feste Bedruckung (s.u.) oder individuell beschriftbar",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="35vh"
                src={CenteredImg}
                shadow
                alt={
                  <>
                    SCHUKO<sup>®</sup> Steckdose mit Beschriftungsfeld
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
