// Komponenten
import ChapterWrapper from "../../../components/ChapterWrapper";

// Sections
import Intro from "./sections/Intro";
import Unterstuetzung from "./sections/Unterstuetzung";
import Marketing from "./sections/Marketing";
import Raeume from "./sections/Raeume";
import Funktion from "./sections/Raeume/Funktion";

export default function ModerneElektroinstallation() {
  return (
    <ChapterWrapper chapterKey="moderneelektroinstallation">
      <Intro anchor="intro" />
      <Raeume anchor="raeume" />
      <Funktion anchor="funktion" />
      <Unterstuetzung anchor="unterstuetzung" />
      <Marketing anchor="marketing-unterstuetzung" />
    </ChapterWrapper>
  );
}
