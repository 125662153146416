import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../components/QList";
import QHeader from "../../../../../components/QHeader";
import Slide from "../../../../../components/Slide/index";
import QBodyText from "../../../../../components/QBodyText";
import ImageComponent from "../../../../../components/ImageComponent";
import CenteredImage from "../../../../../components/CenteredImage";

export default function DreiDLiveView() {
  return (
    <Slide
      background={{
        src: "../../../../../assets/SchaltenUndSteuern/background/drei-d-liveview.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../assets/SchaltenUndSteuern/produkte/drei-d-liveview.png"
        alt="Multimedia"
        height="27vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Multimedia" />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              3D LiveView App
            </QBodyText>
            <QBodyText>
              Beeindruckende Visualisierung der Busch-Jaeger Produkte mit der
              neuen Augmented Reality App 3D LiveView.
            </QBodyText>
            <Box style={{ paddingRight: "13vw" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Verblüffend echte Darstellung durch dreidimensionale Ansicht",
                  "Alle Schalterprogramme im aktuellen Überblick",
                  "Schalter-in-Foto-Funktion",
                  "Fachhändlersuche nach PLZ oder Ortung mit Link-Funktion und Kontaktdaten",
                  "Die ganze Vielfalt von Schalterprogrammen, Busch-free@home®, KNX und Türkommunikation in einer App",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={3}
                align="left"
                images={[
                  {
                    alt: "App-Icon 3D LiveView App",
                    src: "../../../../../assets/SchaltenUndSteuern/produkte/drei-d-liveview-left.jpg",
                  },
                  {
                    alt: "QR-Code",
                    src: "../../../../../assets/SchaltenUndSteuern/produkte/drei-d-liveview-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
