import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import CenterImage from "../../../../../../../assets/cwa/3d-liveview-busch-jaeger.png";

export default function Uebersicht() {
  return (
    <Slide>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
            subTitle="Lösungen"
          />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <svg
              width={"auto"}
              height={"72vh"}
              viewBox="0 0 911 656"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect
                x="381"
                y="253"
                width="150"
                height="150"
                rx="35"
                fill="url(#pattern0)"
              />
              <path
                d="M858.568 91H798.432L797 92.4318V152.568L798.432 154H858.568L860 152.568V92.4318L858.568 91ZM857.136 151.136H799.864V93.8636H857.136V151.136Z"
                fill="black"
              />
              <path
                d="M812.75 148.272H844.25C849.777 148.272 854.273 143.775 854.273 138.249V106.749C854.273 101.224 849.777 96.7266 844.25 96.7266H812.75C807.223 96.7266 802.727 101.224 802.727 106.749V138.249C802.727 143.775 807.223 148.272 812.75 148.272ZM805.591 106.749C805.591 102.802 808.802 99.5902 812.75 99.5902H844.25C848.198 99.5902 851.409 102.802 851.409 106.749V138.249C851.409 142.197 848.198 145.408 844.25 145.408H812.75C808.802 145.408 805.591 142.197 805.591 138.249V106.749Z"
                fill="black"
              />
              <path
                d="M828.5 148.272H844.25C849.776 148.272 854.272 143.775 854.272 138.249V106.749C854.272 101.224 849.776 96.7266 844.25 96.7266L828.5 96.7287V148.272ZM831.364 99.5891L844.25 99.5902C848.197 99.5902 851.409 102.802 851.409 106.749V138.249C851.409 142.197 848.197 145.409 844.25 145.409L831.364 145.406V99.5891Z"
                fill="#00AEEF"
              />
              <path
                d="M828.5 99.5898H825.636V145.408H828.5V99.5898Z"
                fill="black"
              />
              <path
                d="M585.008 505.594V510.523L591.008 526.594M570.008 525.094L576.008 510.523V505.594M580.508 532.594V505.594M573.008 505.594H588.008"
                stroke="#009DE0"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M592.508 534.094H568.508M571.508 546.094V550.594C572.842 551.128 575.687 552.094 579.508 552.094C581.02 552.094 586.136 551.943 589.508 550.594V546.094H571.508ZM570.008 534.094V525.094L561.007 510.094C561.007 510.094 560.708 489.094 580.508 489.094C600.308 489.094 600.008 510.094 600.008 510.094L591.008 525.094V534.094H570.008ZM567.008 540.094H594.008V534.094H567.008V540.094ZM568.508 546.094H592.508V540.094H568.508V546.094Z"
                stroke="black"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M842.291 450.047L837.791 442.547M852.791 435.047H843.791M819.791 445.547V454.547M801.791 442.547L797.291 450.047M795.791 435.047H786.791M840.791 426.047L839.291 433.547L836.291 438.047H803.291L800.291 433.547L798.791 426.047H840.791ZM843.791 426.047H795.791L792.791 420.047H846.791L843.791 426.047Z"
                stroke="black"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M804.125 460.547C804.125 460.547 798.875 464.297 804.125 469.547C809.375 474.797 804.125 480.047 804.125 480.047M819.792 460.547C819.792 460.547 814.542 464.297 819.792 469.547C825.042 474.797 819.792 480.047 819.792 480.047M835.458 460.547C835.458 460.547 830.208 464.297 835.458 469.547C840.708 474.797 835.458 480.047 835.458 480.047"
                stroke="#009DE0"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M601 113.5L541 239.213"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M382.5 132.5L425.5 228.5"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M378.143 125.877C378.497 125.117 379.399 124.788 380.159 125.143C380.918 125.496 381.247 126.4 380.893 127.16C380.538 127.92 379.635 128.248 378.875 127.895C378.116 127.539 377.788 126.637 378.143 125.877Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M378.143 125.877C378.497 125.117 379.399 124.788 380.159 125.143C380.918 125.496 381.247 126.4 380.893 127.16C380.538 127.92 379.635 128.248 378.875 127.895C378.116 127.539 377.788 126.637 378.143 125.877Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                d="M758.156 452.543L547.789 372.102"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M765.749 453.711C766.276 454.361 766.175 455.318 765.524 455.846C764.872 456.375 763.917 456.272 763.39 455.621C762.862 454.969 762.962 454.016 763.614 453.486C764.265 452.959 765.222 453.059 765.749 453.711Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M765.749 453.711C766.276 454.361 766.175 455.318 765.524 455.846C764.872 456.375 763.917 456.272 763.39 455.621C762.862 454.969 762.962 454.016 763.614 453.486C764.265 452.959 765.222 453.059 765.749 453.711Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                d="M548.759 469.033L524.48 434.898"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M554.573 473.71C554.79 474.52 554.307 475.353 553.499 475.57C552.69 475.787 551.858 475.306 551.641 474.496C551.425 473.687 551.905 472.855 552.714 472.638C553.522 472.421 554.356 472.9 554.573 473.71Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M554.573 473.71C554.79 474.52 554.307 475.353 553.499 475.57C552.69 475.787 551.858 475.306 551.641 474.496C551.425 473.687 551.905 472.855 552.714 472.638C553.522 472.421 554.356 472.9 554.573 473.71Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                d="M152.193 529.387L326.187 429.602"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M146.379 534.472C145.542 534.435 144.892 533.728 144.929 532.89C144.965 532.052 145.674 531.402 146.511 531.439C147.349 531.476 147.998 532.185 147.962 533.023C147.925 533.861 147.216 534.509 146.379 534.472Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M146.379 534.472C145.542 534.435 144.892 533.728 144.929 532.89C144.965 532.052 145.674 531.402 146.511 531.439C147.349 531.476 147.998 532.185 147.962 533.023C147.925 533.861 147.216 534.509 146.379 534.472Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                d="M229.081 336.191L317.796 339.495"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M221.429 337.016C220.816 336.444 220.781 335.483 221.351 334.871C221.925 334.256 222.884 334.223 223.497 334.795C224.109 335.368 224.146 336.327 223.572 336.942C223.001 337.553 222.042 337.588 221.429 337.016Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M221.429 337.016C220.816 336.444 220.781 335.483 221.351 334.871C221.925 334.256 222.884 334.223 223.497 334.795C224.109 335.368 224.146 336.327 223.572 336.942C223.001 337.553 222.042 337.588 221.429 337.016Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M382.787 574.973C382.787 576.216 381.78 577.223 380.537 577.223C379.294 577.223 378.287 576.216 378.287 574.973C378.287 573.73 379.294 572.723 380.537 572.723C381.78 572.723 382.787 573.73 382.787 574.973Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M343.787 605.723H406.787V542.723H343.787V605.723ZM391.787 599.723H358.787C353.837 599.723 349.787 595.673 349.787 590.723V557.723C349.787 552.773 353.837 548.723 358.787 548.723H391.787C396.737 548.723 400.787 552.773 400.787 557.723V590.723C400.787 595.673 396.737 599.723 391.787 599.723Z"
                stroke="black"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M375.287 559.223C367.003 559.223 360.287 565.939 360.287 574.223C360.287 582.507 367.003 589.223 375.287 589.223C383.571 589.223 390.287 582.507 390.287 574.223C390.287 565.939 383.571 559.223 375.287 559.223ZM375.287 559.223V563.723"
                stroke="#009DE0"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M372.287 574.973C372.287 576.216 371.28 577.223 370.037 577.223C368.794 577.223 367.787 576.216 367.787 574.973C367.787 573.73 368.794 572.723 370.037 572.723C371.28 572.723 372.287 573.73 372.287 574.973Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M69 185.039H66.57L55.77 174.539H44.7L42 171.914V156.164L44.7 153.539H55.77L66.57 143.039H69V185.039Z"
                stroke="#111111"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M85.5967 144.993C90.2227 149.619 93.7587 155.875 93.7587 164.037C93.7587 172.2 90.2227 178.73 85.5967 183.082M77.4347 153.155C79.6117 155.332 82.8767 158.597 82.8767 164.037C82.8767 169.48 79.6117 172.745 77.4347 174.92M93.7587 138.246C100.023 144.51 104.813 152.984 104.813 164.037C104.813 175.091 100.023 183.935 93.7587 189.83"
                stroke="#229CDC"
                stroke-width="3"
              />
              <path
                d="M140.538 173.895L324.874 261.822"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M133.072 171.924C132.646 171.202 132.886 170.273 133.609 169.847C134.332 169.421 135.261 169.663 135.687 170.385C136.113 171.108 135.873 172.036 135.15 172.462C134.427 172.888 133.498 172.647 133.072 171.924Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M133.072 171.924C132.646 171.202 132.886 170.273 133.609 169.847C134.332 169.421 135.261 169.663 135.687 170.385C136.113 171.108 135.873 172.036 135.15 172.462C134.427 172.888 133.498 172.647 133.072 171.924Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                d="M379.157 509.539L394.611 448.121"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M378.62 517.404C377.987 517.955 377.03 517.886 376.481 517.254C375.931 516.621 375.995 515.662 376.628 515.111C377.261 514.56 378.222 514.629 378.772 515.261C379.321 515.894 379.253 516.853 378.62 517.404Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M378.62 517.404C377.987 517.955 377.03 517.886 376.481 517.254C375.931 516.621 375.995 515.662 376.628 515.111C377.261 514.56 378.222 514.629 378.772 515.261C379.321 515.894 379.253 516.853 378.62 517.404Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                d="M750.5 293L551.3 300.477"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M758.386 291.274C759.073 291.754 759.241 292.702 758.761 293.389C758.281 294.075 757.333 294.241 756.646 293.76C755.96 293.28 755.794 292.335 756.275 291.649C756.755 290.962 757.7 290.794 758.386 291.274Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M758.386 291.274C759.073 291.754 759.241 292.702 758.761 293.389C758.281 294.075 757.333 294.241 756.646 293.76C755.96 293.28 755.794 292.335 756.275 291.649C756.755 290.962 757.7 290.794 758.386 291.274Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M185.147 360.095H182.079V358.56C182.079 357.714 182.766 357.027 183.612 357.027C184.459 357.027 185.146 357.714 185.146 358.56L185.147 360.095ZM197.422 298.727V295.66H129.918V298.727H182.079V301.796H129.918V304.865H182.079V307.933H129.918V311.002H182.079V314.07H129.918V317.139H182.079V320.208H129.918V323.276H182.079V326.345H129.918V329.413H182.079V354.243C180.297 354.878 179.01 356.564 179.01 358.562V361.631L180.543 363.164H186.68L188.215 361.631V358.562C188.215 356.564 186.928 354.878 185.146 354.243V329.413H197.42V326.345H185.146V323.276H197.42V320.208H185.146V317.139H197.42V314.07H185.146V311.002H197.42V307.933H185.146V304.865H197.42V301.796H185.146V298.727H197.422Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M136.504 346.738L130.367 352.874L132.535 355.043L136.054 351.527V363.164H139.122V351.527L142.64 355.043L144.81 352.874L138.673 346.738H136.504Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M152.93 346.617V358.254L149.412 354.737L147.242 356.906L153.379 363.044H155.548L161.685 356.906L159.515 354.737L155.998 358.254V346.617H152.93Z"
                fill="#009DE0"
              />
              <path
                d="M321.173 26V66.5M321.173 66.5C318.688 66.5 316.673 68.515 316.673 71C316.673 73.485 318.688 75.5 321.173 75.5C323.659 75.5 325.673 73.485 325.673 71C325.673 68.515 323.659 66.5 321.173 66.5ZM327.178 60.608C330.762 62.683 333.174 66.56 333.174 71C333.174 77.627 327.801 83 321.173 83C314.546 83 309.174 77.627 309.174 71C309.174 66.559 311.586 62.682 315.172 60.606L315.174 60.5V26C315.174 22.686 317.86 20 321.173 20C324.487 20 327.174 22.686 327.174 26V60.5L327.178 60.608Z"
                stroke="black"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M331.673 27.5H343.673M331.673 35H343.673M331.673 42.5H337.673"
                stroke="#009DE0"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M78.5083 553.914V538.778M78.5083 538.778L72.7813 529.233M78.5083 538.778L84.2353 529.233M67.0533 561.687H89.9623V554.051H67.0533V561.687ZM70.8723 561.687V567.414H86.1443V561.687H70.8723ZM68.9623 553.914V542.596L59.4173 525.414V523.505C59.4173 512.962 67.9643 504.414 78.5083 504.414C89.0523 504.414 97.5993 512.962 97.5993 523.505V525.414L88.0533 542.596V553.914H68.9623Z"
                stroke="black"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M99.69 561.414H120.69M99.69 543.414H120.69M110.19 532.914V553.914"
                stroke="#009DE0"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="565.097" y="575.297">
                  LED
                </tspan>
              </text>
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="592.044" y="89.1094">
                  Licht
                </tspan>
              </text>
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="803.234" y="179.539">
                  Design
                </tspan>
              </text>
              <path
                d="M611 4V13"
                stroke="#009FE3"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M621 60H602V66H621V60Z"
                stroke="#1D1D1B"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M605 66V71H617V66"
                stroke="#1D1D1B"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M611 62V47.8571L606 40"
                stroke="#1D1D1B"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M611 48L616 40"
                stroke="#1D1D1B"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M603.75 58V50.2L596 36.16V34.6C596 25.9844 602.94 19 611.5 19C620.06 19 627 25.9844 627 34.6V36.16L619.25 50.2V58"
                stroke="#1D1D1B"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M588 12L596 20"
                stroke="#009FE3"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M578 37H589"
                stroke="#009FE3"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M626 20L634 12"
                stroke="#009FE3"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <path
                d="M633 37H644"
                stroke="#009FE3"
                stroke-width="2"
                stroke-linejoin="bevel"
              />
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="775.609" y="503.824">
                  Warnmelder
                </tspan>
              </text>
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="58.2493" y="590.895">
                  Dimmer
                </tspan>
              </text>
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="132.281" y="384.574">
                  Jalousie
                </tspan>
              </text>
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="282" y="109.281">
                  Raumklima
                </tspan>
              </text>
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="333.945" y="631.23">
                  Steckdosen
                </tspan>
              </text>
              <path
                d="M800.453 310.828V318.5M786.935 302L813.935 301.98V273.351L786.935 273.371V302ZM791.285 272V254H809.585V272H791.285Z"
                stroke="black"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M795.935 266H798.935M801.935 266H804.935"
                stroke="#009DE0"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <path
                d="M797.435 302V309.5H803.435V302"
                stroke="black"
                stroke-width="3"
                stroke-linejoin="bevel"
              />
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="776" y="341.094">
                  Laden
                </tspan>
              </text>
              <text
                fill="black"
                xmlSpace="preserve"
                style="white-space: pre"
                font-family="Averta-Semibold"
                font-size="16"
                letter-spacing="0em"
              >
                <tspan x="47.8173" y="209.969">
                  Radio
                </tspan>
              </text>
              <circle cx="603.5" cy="107.5" r="3.5" fill="#00AEEF" />
              <path
                d="M764.5 167.5L565 251.895"
                stroke="black"
                stroke-width="1.638"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M772.386 162.274C773.073 162.754 773.241 163.702 772.761 164.389C772.281 165.075 771.333 165.241 770.646 164.76C769.96 164.28 769.794 163.335 770.275 162.649C770.755 161.962 771.7 161.794 772.386 162.274Z"
                fill="#009DE0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M772.386 162.274C773.073 162.754 773.241 163.702 772.761 164.389C772.281 165.075 771.333 165.241 770.646 164.76C769.96 164.28 769.794 163.335 770.275 162.649C770.755 161.962 771.7 161.794 772.386 162.274Z"
                stroke="#009DE0"
                stroke-width="3.277"
              />
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use xlinkHref="#image0_48_6" transform="scale(0.00211864)" />
                </pattern>
                <image
                  id="image0_48_6"
                  width="472"
                  height="472"
                  xlinkHref={CenterImage}
                />
              </defs>
              <a target="_self" href="#loesungen/6">
                <rect
                  x="264"
                  y="7"
                  width="122"
                  height="114"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/7">
                <rect
                  x="557"
                  y="3"
                  width="108"
                  height="103"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#schalten-und-bedienen">
                <rect
                  x="779"
                  y="82"
                  width="100"
                  height="106"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/2">
                <rect
                  x="750"
                  y="246"
                  width="100"
                  height="106"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/8">
                <rect
                  x="766"
                  y="405"
                  width="110"
                  height="111"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/9">
                <rect
                  x="525"
                  y="478"
                  width="110"
                  height="111"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/3">
                <rect
                  x="322"
                  y="531"
                  width="110"
                  height="111"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/4">
                <rect
                  x="33"
                  y="494"
                  width="110"
                  height="111"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/5">
                <rect
                  x="108"
                  y="286"
                  width="110"
                  height="111"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
              <a target="_self" href="#loesungen/1">
                <rect
                  x="15"
                  y="121"
                  width="110"
                  height="111"
                  rx="6"
                  fill="black"
                  fill-opacity="0"
                />
              </a>
            </svg>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
