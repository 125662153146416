import { Grid } from "@material-ui/core";
import Slide from "../../../../components/Slide";
import QHeader from "../../../../components/QHeader";
import QBodyText from "../../../../components/QBodyText";
import QList from "../../../../components/QList";
import ImageComponent from "../../../../components/ImageComponent";

// Images
import CenteredImg from "../../../../assets/renovation-und-neubau/raume/garten-outdoor/ocean/ocean.png";
import Image1 from "../../../../assets/renovation-und-neubau/raume/garten-outdoor/ocean/ocean-jalousie-schlüsselschalter.jpg";
import Image2 from "../../../../assets/renovation-und-neubau/raume/garten-outdoor/ocean/ocean-leer.jpg";
import Image3 from "../../../../assets/renovation-und-neubau/raume/garten-outdoor/ocean/ocean-waschmaschine.jpg";

export default function OceanIP44Overlay({ backgroundImg }) {
  return (
    <Slide
      background={{
        src: backgroundImg,
        backgroundPosition: "right",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/ocean",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ocean<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                ocean<sup>®</sup> glänzt durch Form und Farbe und wurde für sein
                Äußeres sogar preisgekrönt. Aber nicht nur das Design, sondern
                auch die Funktionalität überzeugt, denn das Programm ist robust,
                montagefreundlich, vielseitig, wassergeschützt und abschließbar.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Kombinationen 1-3 fach",
                  "In den Farben weiß und blaugrün/grau",
                  "Breites Sortiment von der Steckdose über Schalter bis zur Daten-/Satdose",
                ]}
              />
              <ImageComponent
                wrapperWidth="80%"
                align="left"
                col={4}
                images={[
                  {
                    src: Image1,
                    alt: (
                      <>
                        ocean<sup>®</sup> Jalousie Schlüsselschalter
                      </>
                    ),
                  },
                  {
                    src: Image2,
                    alt: (
                      <>
                        ocean<sup>®</sup>
                      </>
                    ),
                  },
                  {
                    src: Image3,
                    alt: (
                      <>
                        ocean<sup>®</sup> mit Beschriftungsfeld und
                        Schlüsselschalter
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                wrapperHeight="50vh"
                src={CenteredImg}
                alt={
                  <>
                    ocean<sup>®</sup>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
