import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/34.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/busch-waechter/busch-waechter-180-flex.png";

export default function BuschWaechterOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-freeathome-flex",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://www.busch-jaeger.de/bje-flex/static/media/Badezimmer-Video_DE.947709065f66cf91066e.mp4",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Wächter® 180 flex" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Bewegungsmeldersensor mit Umgebungslichtmessung. Zum
                automatischen Schalten von Verbrauchern in Abhängigkeit von
                Bewegung und Helligkeit, sind komfortabel, energieeffizient und
                schaffen Sicherheit. Z.B. in Dielen und Treppenbereichen.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Verschiedene Erfassungsreichweiten und Erfassungslinse für jede Anwendung",
                  "Ins Schalterdesign integrierbar",
                  "Manuelles Schalten vor Ort möglich",
                  "Erweiterbar durch Nebenstellenbetrieb",
                  "Mit dem Busch-Radio DAB+ kombinierbar (Schaltet ein bei Bewegung)",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="35vh"
                src={CenteredImg}
                alt="Busch-Wächter® 180 flex"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
