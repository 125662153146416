import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/54.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/5.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/8.jpg";
import Image9 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/9.jpg";
import Image10 from "../../../../../../../assets/renovation-und-neubau/funktion/kommunikation/10.jpg";

const items = [
  {
    title: "Antenne/Sat",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=220&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=d042672eefc9672eeff94e00f0db7ca1",
    },
  },
  {
    title: "ISDN/LAN/UAE",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=222&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=472ccc8011273bf511dc3f5caa7c59ab",
    },
  },
  {
    title: "LAN",
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=224&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=b19e87759a9338115204391c344bab52",
    },
  },
  {
    title: "Lautsprecher",
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=217&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=540d3f31f14a84641ed6b5a92870f915",
    },
  },
  {
    title: "Cinch/Klinke",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=216&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=6a5292f5fcdef37b1b184d7650ee3116",
    },
  },
  { placeholder: true },
  {
    title: "Telefon",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=218&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=9f9c7048d911e9fe7125f8967ab64598",
    },
  },
  {
    title: "USB",
    img: Image8,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=216&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=6a5292f5fcdef37b1b184d7650ee3116",
    },
  },
  {
    title: "VGA/HDMI",
    img: Image9,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=216&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=6a5292f5fcdef37b1b184d7650ee3116",
    },
  },
  {
    title: "DKS",
    img: Image10,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=225&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=73b6523bc0d8e37fc5a5422215e712c2",
    },
  },
];

export default function KommunikationOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatKapitel%5D=24&tx_nlbjproducts_catalog%5Bcontroller%5D=CatDokument&cHash=922d078130f3d79c98496a808584474b",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Kommunikation" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Die passenden Anschlussdosen sind eine der wichtigsten
                Komponenten für die saubere Verdrahtung von Smart TV,
                HiFi-Anlage, Computer und Tablet
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={2}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
