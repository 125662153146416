import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/17.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/busch-powerDock/busch-powerdock.png";

export default function BuschPowerDockOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-powerdock",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-powerDock" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Ladestationen direkt in einer Unterputzdose werden immer
                beliebter in Privathäusern, Hotels und Zweckbauten. Busch-Jaeger
                bietet mit dem Busch-powerDock eine optimale Lösung – und das
                entweder für den Lightning-Anschluss für Apple*-Geräte oder auch
                für die zukünftig standardisierte Smartphone-Ladeschnittstelle,
                den USB-C-Anschluss.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Mit Ladeanschluss für Apple*-Lightning oder USB Typ-C",
                  "Die ideale Dockingstation, um iPhones* und iPods* anzudocken",
                  "Kein lästiges Suchen mehr nach einem Kabel",
                  "Der Ladeplatz ist immer an der gleichen Stelle",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="Dreh-/Tastdimmer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
