import Arbeitszimmer from "./Raeume/slides/Arbeitszimmer";
import Bad from "./Raeume/slides/Bad";
import Diele from "./Raeume/slides/Diele";
import Garage from "./Raeume/slides/Garage";
import Garten from "./Raeume/slides/Garten";
import Haustuer from "./Raeume/slides/Haustuer";
import Kind from "./Raeume/slides/Kind";
import Kueche from "./Raeume/slides/Kueche";
import Schlafen from "./Raeume/slides/Schlafen";
import Wohnen from "./Raeume/slides/Wohnen";
import Uebersicht from "./Raeume/Uebersicht";

export default function Raeume() {
  return [
    <Uebersicht key="uebersicht" />,
    <Arbeitszimmer key="arbeitszimmer" />,
    <Kind key="kind" />,
    <Schlafen key="schlafen" />,
    <Bad key="bad" />,
    <Garten key="garten" />,
    <Wohnen key="wohnen" />,
    <Kueche key="kueche" />,
    <Garage key="garage" />,
    <Haustuer key="haustuer" />,
    <Diele key="diele" />,
  ];
}
