import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/49.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/8.jpg";
import Image9 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/9.jpg";
import Image10 from "../../../../../../../assets/renovation-und-neubau/funktion/installation-im-aussenbereich/10.jpg";

const items = [
  {
    title: "Bewegungsmelder 220/280",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=242&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=e9e55cf2a1cf44a1f20d52ad322eb930",
    },
  },
  {
    title: "Bewegungsmelder 70/110",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=242&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=e9e55cf2a1cf44a1f20d52ad322eb930",
    },
  },
  {
    title: "Allwetter 44",
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatKapitel%5D=18&tx_nlbjproducts_catalog%5Bcontroller%5D=CatDokument&cHash=18ccacd6d6cae07111ea3451ce9bd674",
    },
  },
  {
    title: "Zwischenring zu Reflex SI",
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=1947&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=9648757534f48c230c173119ca927633",
    },
  },
  {
    title: "Klappdeckel abschließbar",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=1946&tx_nlbjproducts_catalog%5BcatStdArtikel%5D=7581&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=d30d6e552d560f19ce06237236cbda05",
    },
  },
  { placeholder: true },
  {
    title: "Ocean IP 44 mit Beschriftungsfeld",
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=206&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=f112c0d085403add8f5dd72392553858",
    },
  },
  {
    title: "FI-Schukomat",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=53864&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=6caba32da90e2e48d918975ff556d6c6",
    },
  },
  {
    title: "Dämmerungsschalter",
    img: Image8,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2094&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=31c4fe9f36f9e83ff552dceaffde5221",
    },
  },
  {
    title: "Leergehäuse",
    img: Image9,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2095&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=7182f136d5da9add5a84432c03fc168d",
    },
  },
  {
    title: "Steckdose abschließbar",
    img: Image10,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2078&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=be680ee8bfce9f25ff1ed5b6a487bad6",
    },
  },
];

export default function IP44Overlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatKapitel%5D=24&tx_nlbjproducts_catalog%5Bcontroller%5D=CatDokument&cHash=922d078130f3d79c98496a808584474b",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Installation im Außenbereich" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Installation im Außenbereich muss sicher sein! Sicher gegen
                Witterungseinflüsse und Feuchtigkeit – Sicher gegen unbefugten
                Zugriff! Neben Sicherheit spielt im Outdoorbereich immer mehr
                Funktionalität eine Rolle. Outdoor-Küchen sind im Trend und
                Fußball wird auch gerne bei einer Bratwurst im Freien geguckt.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={2}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
