import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/52.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/multimedia/8.jpg";

const items = [
  {
    title: 'Lautsprechereinsatz 2"',
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2510&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=e49beb141d41e4487a94eec23d758aad",
    },
  },
  {
    title: 'Lautsprechereinsatz 3"',
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2539&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=f25cbf6eab56d98e37182381978c31bb",
    },
  },
  {
    title: 'Lautsprechereinsatz 5"',
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2540&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=e92e4814474a9f53ca9c72ef1c2ba3b6",
    },
  },
  {
    title: 'HIFI Lautsprechereinsatz 5"',
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2541&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=dd17b89e39ace61f76385edb57f4b721",
    },
  },
  {
    title: 'Feuchtraumlautsprecher 5"',
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=45517&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=b80b2af89266130c0a8c1871d6a82765",
    },
  },
  {
    title: "Busch-Radio BTConnect DAB+",
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-radio-btconnect-dab",
    },
  },
  {
    title: "Busch-Radio DAB+",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-radio-dab",
    },
  },
  {
    title: "Busch-Radio iNet",
    img: Image8,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-radio-inet",
    },
  },
];

export default function MultimediaOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/multimedia",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Multimedia" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Ein Radio, das keinen Platz einnimmt und gut aussieht. Ein
                Soundsystem mit einem Design, das zu Ihrem Verdrahtungszubehör
                passt. Vom Lieblingslied über die neuesten Nachrichten bis hin
                zu Hörbüchern
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
