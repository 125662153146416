import { Box, Grid } from "@material-ui/core";

import backgroundLichtschalter from "../../../../../../assets/cwa/background/titelbilder/schalter/axcent.jpg";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QProductGroup from "../../../../../../components/QProductGroup";
import QBodyText from "../../../../../../components/QBodyText";
import Preisliste from "../preisliste";
import CenteredImage from "../../../../../../components/CenteredImage";

const itemlist = [
  {
    src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/axcent/1.jpg",
    alt: (
      <>
        Busch-axcent<sup>®</sup> Schwarz
      </>
    ),
  },
  {
    src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/axcent/5.jpg",
    alt: (
      <>
        Busch-axcent<sup>®</sup> Studioweiß
      </>
    ),
  },
];

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produkte/schalterdesign/busch-axcent",
    target: "_blank",
  },
  {
    type: "dialog",
    img: "preisliste",
    children: <Preisliste anchor="busch-axcent" />,
  },
];

export default function Axcent() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundLichtschalter,
        backgroundPosition: "left",
      }}
    >
      <CenteredImage images={itemlist} width="22vw" left="0" top="30px" />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                Busch-axcent<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={5}>
              <QBodyText>Zeitgemäße Akzente</QBodyText>
              <QList
                title="Vorteile"
                items={[
                  "Kontrastreich und lebendig",
                  "Konsequent, gradliniges Design",
                  "Erhältlich in 1-fach und 5-fach",
                  "Auch als Fan-schalter erhältlich",
                ]}
                dense
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
