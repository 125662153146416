import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/48.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/fi-schukomat/fi-schukomat-schuko.png";

export default function FISchukomatOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/fi-schukomat",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="FI-Schukomat Schuko" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Strom ist nützlich, kann aber auch sehr gefährlich sein. Eine
                FI-SCHUKOMAT SCHUKO® Steckdose mit Fehlerstrom-Schutzschalter
                bietet zusätzlichen Schutz wenn es nass wird. Z.B. beim
                Rasenmähen oder bei der Versorgung des Pools. Er trennt den
                Strom, wenn es darauf ankommt, in Millisekunden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Höchster Schutz bei Feuchtigkeit direkt vor Ort",
                  "Der FI Schutzschalter schützt vor gefährlichen Körperströmen",
                  "Erhöhter Berührungsschutz",
                  "Vermeidung von Unfällen",
                  "Einfachste Nachrüstung, als Unterputz- oder Aufputz-Variante",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
