import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/43.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/ueberspannungsschutz/busch-protector.jpg";

export default function BuschProtectorOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-protector",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Protector<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Sicherer als eine Versicherung. Schützt teure elektronische
                Geräte vor Überspannung durch Blitze oder Störungen im
                Stromnetz. So werden gleichzeitig wertvolle Daten geschützt.
                Durch einen einfachen Austausch der Steckdosen kann man seine
                Geräte davor schützen. Der Busch-Protector® leitet
                Überspannungen sicher ab und schützt somit vor Beschädigungen.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Schützt elektronische Geräte vor Überspannungen",
                  "Mit der Überspannungsschutz Steckdose gibt es keinen Ärger mit Wiederbeschaffungen, sofern überhaupt ein Versicherungsschutz vorhanden ist)",
                  "Eine Steckdose schützt den näheren „Bereich“ (z.B. Arbeitszimmer)",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="35vh"
                shadow
                src={CenteredImg}
                alt={
                  <>
                    Busch-Protector<sup>®</sup>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
