import { h } from "preact";
import ChapterWrapper from "../../../components/ChapterWrapper";
import Freiheit from "./sections/Freiheit";
import Lebensraeume from "./sections/Lebensraeume";
import Loesungen from "./sections/Loesungen";
import System from "./sections/System";

export default function Smarterhome() {
  return (
    <ChapterWrapper chapterKey="smarterhome">
      <Freiheit anchor="freiheit" />
      <Loesungen anchor="loesungen" />
      <System anchor="system" />
      <Lebensraeume anchor="lebensraeume" />
    </ChapterWrapper>
  );
}
