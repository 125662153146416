import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/diele/objektsteckdose/busch-objektsteckdose.png";

export default function BuschObjektsteckdoseOverlay() {
  return (
    <Slide
      background
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-objektsteckdose",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Objektsteckdose<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Die Objektsteckdose reduziert die Unfallgefahr. Stolpert man
                über ein Kabel oder die Leitung des Staubsaugers, gibt sie den
                Stecker leichter frei. So wird zudem das Herausreißen der
                gesamten Steckdose verhindert.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "In bestehendes Schalterprogramm integrierbar",
                  "Begünstigt ein schräges Herausziehen des Steckers aufgrund nachgebender Seitenwände",
                  "Reduziert die Unfallgefahr",
                  "Senkung der Instandhaltungskosten, weil die Steckdose nicht aus der Wand gerissen wird",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Busch-Objektsteckdose®"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
