import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function USBLaden() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/SchaltenUndSteuern/background/usb-laden.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/SchaltenUndSteuern/produkte/usb-laden.jpg"
        alt="Heizungssteuerung"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Stromversorgung" />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              USB-Laden
            </QBodyText>
            <QBodyText>
              Die Busch-Jaeger Lösungen bieten für jeden Anwendungsfall das
              Richtige. Dank der USB-Ladegeräte ist stets alles sauber im
              Schalterprogramm integriert.
            </QBodyText>
            <Box style={{ paddingRight: "16vw" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Stationäre Versorgung, als fester Ladepunkt fürs Handy oder als Kombination aus Steckdose und Ladegerät",
                  "Hoher Ladestrom für Tablet oder Smartphone",
                  "Schutz vor Überlast",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Doppel-Steckdose",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/usb-laden-left.jpg",
                  },
                  {
                    alt: "Smartphone-Aufladung",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/usb-laden-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
