import { Button, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../../../../components/ImageComponent";

// Images
import TagbetriebImg from "../../../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder-multi/tagbetrieb.jpg";
import NachtbetriebImg from "../../../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder-multi/nachtbetrieb.jpg";

export default function BuschWaechter180Flex({ swiperRef }) {
  const goToSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Tag-/Nachtumschaltung" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                In Verbindung mit einem Dimmer kann der BWM das Licht tagsüber
                und in den Abendstunden bei Bewegung hell einschalten. Nachts
                dagegen wird das Licht nur leicht angedimmt. Dadruch steht man
                nachts nicht im grellen Licht und kommt schneller wieder in den
                Schlaf.
              </QBodyText>
              <Button variant="contained" onClick={goToSlide}>
                Nebenstellenbetrieb
              </Button>
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                wrapperWidth="100%"
                col={6}
                images={[
                  {
                    src: TagbetriebImg,
                    alt: "Tagbetrieb",
                  },
                  {
                    src: NachtbetriebImg,
                    alt: "Nachtbetrieb",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
