import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { chapters } from "../../../helpers/data";
import { useSelector, useStore } from "react-redux";
import { getActiveModule } from "../../../redux/modules/consulting";
import Notes from "./Notes";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import FilledArrowRight from "../../SvgIcons/FilledArrowRight";
import FilledArrowLeft from "../../SvgIcons/FilledArrowLeft";
import * as R from "ramda";
import CheckIcon from "@material-ui/icons/Check";
import { getActiveModules } from "../../../redux/modules/backendState";
import ActionSection from "../../ActionSection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "var(--grey-10)",
  },
  tabPanel: {
    padding: "0.5rem",
    backgroundColor: "var(--grey-10)",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "var(--grey-10)",
    width: `calc(100% - ${drawerWidth}px)`,
    height: "100vh",
    marginLeft: drawerWidth,
  },
  contentClosed: {
    flexGrow: 1,
    backgroundColor: "var(--grey-10)",
    height: "100vh",
    marginLeft: 25,
    width: `calc(100% - 25)`,
  },
  activeChapter: {
    "& span": {
      fontWeight: "bold",
    },
  },
  head: {
    "& span": {
      fontWeight: "bold",
      fontSize: "clamp(21px, 1.2vw, 1.5rem)",
    },
  },
  drawerPaper: {
    top: "auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    top: "auto",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 20,
  },
  drawerButton: {
    position: "fixed",
    zIndex: 9999,
    top: "10%",
  },
  openDrawer: {
    position: "fixed",
    left: 220,
  },
  closedDrawer: {
    position: "fixed",
    left: 0,
  },
}));

export default function ConsultingPage() {
  const classes = useStyles();
  const [value, setValue] = useState("welcome");
  const [open, setOpen] = useState(true);
  const store = useStore();

  const activeModule = useSelector((state) => getActiveModule(state));
  const activeModules = useSelector((state) => getActiveModules(state));

  const modules = activeModules.filter((module) => module.fields.length > 0);

  useEffect(() => {
    if (activeModule !== "") {
      modules.forEach((module) => {
        if (activeModule === module.chapter.key) {
          setValue(module.chapter.key);
        }
      });
    } else {
      setValue("notizen");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setChapter = (e, value) => {
    setValue(value);
  };

  const handleDrawerChange = () => {
    setOpen(!open);
  };

  let tabs = [];
  tabs.push(
    <ListItem key="head-gesprächsverlauf">
      <ListItemText primary="Kapitel" className={classes.head} />
    </ListItem>
  );

  modules.forEach((module) => {
    let active = false;
    let diffrences = 0;

    tabs.push(
      <ListItem
        button
        key={module.chapter.key}
        value={module.chapter.key}
        {...a11yProps(module.chapter.key)}
        onClick={(event) => setChapter(event, module.chapter.key)}
      >
        <ListItemText
          primary={
            <span>
              {module.chapter.name} {active && <CheckIcon fontSize="small" />}
            </span>
          }
          secondary={
            active &&
            diffrences !== 0 &&
            `${diffrences} ${diffrences === 1 ? "Bestellung" : "Bestellungen"}`
          }
          className={value === module.chapter.key ? classes.activeChapter : ""}
        />
      </ListItem>
    );
  });

  let tabPanels = modules.map((module) => (
    <TabPanel
      key={`tabPanel-${module.chapter.key}`}
      value={value}
      index={module.chapter.key}
    >
      <ActionSection className={classes.tabPanel} module={module} />
    </TabPanel>
  ));

  tabs.push(<Divider variant="body2" component="li" />);

  //notes
  tabs.push(
    <ListItem
      button
      key="notizen"
      value="notizen"
      {...a11yProps("notizen")}
      onClick={(event) => setChapter(event, "notizen")}
    >
      <ListItemText
        primary="Notizen"
        className={value === "notizen" ? classes.activeChapter : ""}
      />
    </ListItem>
  );

  tabPanels.push(
    <TabPanel value={value} index="notizen">
      <div className={classes.tabPanel}>
        <Notes />
      </div>
    </TabPanel>
  );

  return (
    <div className={classes.root}>
      <IconButton
        className={clsx({
          [classes.openDrawer]: open,
          [classes.closedDrawer]: !open,
          [classes.drawerButton]: true,
        })}
        onClick={handleDrawerChange}
        variant="contained"
      >
        {open ? (
          <FilledArrowLeft color="primary" fontSize="small" />
        ) : (
          <FilledArrowRight color="primary" fontSize="small" />
        )}
      </IconButton>
      <Drawer
        variant="permanent"
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.drawerPaper]: true,
          }),
        }}
      >
        <List>{open && tabs}</List>
      </Drawer>
      <main
        className={clsx({
          [classes.content]: open,
          [classes.contentClosed]: !open,
        })}
      >
        {tabPanels}
      </main>
    </div>
  );
}
