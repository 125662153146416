import { h } from "preact";

/*
  key ist case-sensitive, muss also anders als im B2B
  klein geschrieben werden - insofern dies auch in der Datenbank der fall ist.
*/
export const chapters = {
  doorcommunication: {
    img: "../../assets/_titelbilder/tuerkommunikation.jpg",
    frontpage: {
      titlePosition: "bottomright",
    },
    actionPage: null,
    initialState: null,
    getData: null,
  },
  smarterhome: {
    img: "../../assets/_titelbilder/smarter-home.jpg",
    frontpage: {
      titlePosition: "topleft",
    },
    actionPage: null,
    initialState: null,
    getData: null,
  },
  switchcontrol: {
    img: "../../assets/_titelbilder/schalten-und-steuern.jpg",
    frontpage: {
      titlePosition: "bottomright",
    },
  },
  moderneelektroinstallation: {
    img: "../assets/_titelbilder/moderne-elektroinstallation.jpg",
    frontpage: {
      titlePosition: "bottomleft",
    },
  },
};
