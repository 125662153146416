import {
  makeStyles,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  Card,
  styled,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import QDialog from "../QDialog";
import { useState } from "preact/hooks";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCustomCategories } from "../../redux/modules/settingsReducer";
import { ToggleButton } from "@material-ui/lab";
import root from "window-or-global";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "initial",
  },
  cardMedia: {
    borderRadius: "4px 4px 0px 0px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // Playbook B2B
    // height: "45px",
    // Playbook B2C (etwas stimmt mit dem Padding nicht, hier im B2C)
    height: "77px",
  },
  card: {
    overflow: "initial",
  },
  cardActionAreaFullWidth: {
    width: "100% !important",
  },
  cardActionArea: {
    width: "250px",
  },
  cardText: {
    fontSize: "clamp(15px, 1.2vw, 1.5rem)",
    hyphens: "inital",
  },
  chapterActive: {
    filter: "drop-shadow(0 0 0.09rem rgb(0, 0, 0))",
  },
  cardTextActive: {
    fontFamily: "Averta-SemiBold",
  },
  favorite: {
    padding: 0,
  },
}));

export default function ChapterCard({
  chapter,
  active,
  cardWidth,
  favorite,
  onCardClick,
  onIconClick,
  smallPadding,
}) {
  const classes = useStyles();
  const { showModal } = useModal();
  const [open, setOpen] = useState();
  const dispatch = useDispatch();
  const customCategories = useSelector((state) => getCustomCategories(state));
  const theme = useTheme();
  const upLG = useMediaQuery(theme.breakpoints.up("lg"));

  const MuiToggleButton = styled(ToggleButton)({
    border: "none",
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  });

  const dialog = (children) => {
    const d = showModal(QDialog, {
      children,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  return (
    <Card
      style={{ display: chapter.placeholder ? "none" : "block" }}
      className={clsx(classes.root, active ? classes.chapterActive : null)}
    >
      <CardActionArea
        style={cardWidth ? { width: cardWidth } : null}
        className={
          cardWidth ? classes.cardActionAreaFullWidth : classes.cardActionArea
        }
        onClick={
          onCardClick
            ? onCardClick
            : chapter.menuOverlay
            ? () => dialog(chapter.menuOverlay)
            : chapter.link
            ? () =>
                root.open(
                  chapter.link.href,
                  chapter.link.target ? chapter.link.target : "_blank",
                  "noopener"
                )
            : undefined
        }
      >
        <CardMedia
          component="img"
          image={"imgSmall" in chapter ? chapter.imgSmall : chapter.img}
          alt={chapter.slug}
          className={classes.cardMedia}
        />

        <CardContent
          className={classes.cardContent}
          style={{ padding: smallPadding && !upLG ? ".6rem" : "1rem" }}
        >
          <Typography
            className={clsx(
              classes.cardText,
              active ? classes.cardTextActive : null
            )}
            variant="h5"
          >
            {chapter.label ? chapter.label : chapter.title}
          </Typography>

          {typeof favorite !== "undefined" ? (
            <MuiToggleButton
              value={chapter.slug}
              selected={favorite}
              onChange={onIconClick}
            >
              {customCategories.indexOf(chapter.slug) !== -1 ? (
                <StarIcon />
              ) : (
                <StarBorderIcon />
              )}
            </MuiToggleButton>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
