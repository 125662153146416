import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";
import { useModal } from "mui-modal-provider";
import QDialog from "../../../../../../../components/QDialog";
import Button from "@material-ui/core/Button";
import Simulation from "@qmarketing/dimu-simulation";
import "@qmarketing/dimu-simulation/build/index.css";
import QButton from "../../../../../../../components/QButton";

export default function Indoor2() {
  const { showModal } = useModal();

  const openSimulation = () => {
    const d = showModal(QDialog, {
      children: (
        <Box width="100vw" height="100vh">
          <Simulation type="indoor" />
        </Box>
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  return (
    <Slide
      background={{
        src: "../../../../../../../assets/smarterhome/background/indoor-2.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/smarterhome/produkte/indoor-2.jpg"
        alt="Busch-ComfortPanel"
        height="28vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup> IP
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Innenstationen
            </QBodyText>
            <QBodyText>
              Für die Inbetriebnahme wird lediglich ein Internetanschluss
              benötigt. Das macht die Planung, die Installation und den Betrieb
              zum Kinderspiel.
            </QBodyText>
            <Box style={{ paddingRight: "15vw" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Modulare Lösungen",
                  "Einfache Montage und Installation – ein Internetanschluss genügt",
                  "App-Anbindung",
                  "Hohe Flexibilität bei der Steuerung",
                  "Einheitliche und intuitive Benutzeroberfläche",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <QButton variant="contained" onClick={openSimulation}>digital ausprobieren</QButton>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
