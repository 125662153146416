import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/50.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garage/Waermemelder/busch-waermemelder.png";

export default function WaermemelderOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/rauchmelder/busch-waermealarm-professionalline",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://youtu.be/dWpPPOF2FQA",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Wärmealarm" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Als Alternative zum Rauchmelder ist die Wärmeerkennung für den
                Einsatz in der Garage, HWR und im Hobbybereich geeignete
                Technik. Häufig auftretender Staub führt hier zum Fehlalarm. Der
                Wärmealarm dagegen liefert konstante Wachsamkeit – über zehn
                Jahre durch die Lithiumbatterie. Die Funkvernetzung schafft
                weitere Sicherheit.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Der Wärmemelder ist für Garagen, Küchen, HWR und Hobbyräume entwickelt",
                  "Wasserdampf könnte hier einen Rauchwarnmelder aktivieren, beim Wärmemelder löst die Dampftemperatur keinen Alarm aus",
                  "Langsam anschwellender Alarmton bei Funktionstest",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperWidth="80%"
                col={12}
                src={CenteredImg}
                alt="Busch-Wärmealarm"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
