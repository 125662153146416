import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/49.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/daemmerungsschalter/daemmerungsschalter.png";

export default function DaemmerungsschalterOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/daemmerungsschalter-ap",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Dämmerungsschalter" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                An einigen Orten bietet dauerhaftes Licht mehr Sicherheit. Gut,
                wenn es zuverlässig bei jedem Wetter bei Dämmerung einschaltet
                und die dunklen Ecken erleuchtet.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Der Dämmerungsschalter schaltet die Beleuchtung in Abhängigkeit von der Helligkeit",
                  "Über eine Zeitschaltuhr lässt sich die Beleuchtung in den Nachtstunden auch ausschalten",
                  "Schutzart IP 44",
                  "Anwendungsorte: Garage, Schaufensterbeleuchtung, Eingang, Hofbeleuchtung",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
