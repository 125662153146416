import { h } from "preact";
import { Grid } from "@material-ui/core";

import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QProductGroup from "../../../../../../components/QProductGroup";

//Images
import BuschArtLinear from "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/busch-art-linear.png";
import Carat from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/carat.jpg";
import Decento from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/decento.jpg";
import BuschDynasty from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/busch-dynasty.jpg";
import PurEdelstahl from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/pur-edelstahl.jpg";
import Solo from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/solo.jpg";
import BuschAxcentPur1 from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/busch-axcent-pur-1.jpg";
import BuschAxcentPurGlass from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/busch-axcent-pur-glass.jpg";
import BuschAxcentPur2 from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/busch-axcent-pur-2.jpg";
import FutureLinear from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/future-linear.jpg";
import BuschBalanceSI from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/busch-balance-si.jpg";
import ReflexSI from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/reflex-si.jpg";
import Allwetter44 from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/allwetter.jpg";
import Ocean from "../../../../../../assets/cwa/SchaltenUndBedienen/Uebersicht/ocean.jpg";

const itemlist = [
  {
    title: (
      <>
        carat<sup>®</sup>
      </>
    ),
    img: Carat,
    link: "#schalten-und-bedienen/2",
    target: "_self",
  },
  {
    title: (
      <>
        Decento<sup>®</sup>
      </>
    ),
    img: Decento,
    link: "#schalten-und-bedienen/3",
    target: "_self",
  },
  {
    title: (
      <>
        Busch-dynasty<sup>®</sup>
      </>
    ),
    img: BuschDynasty,
    link: "#schalten-und-bedienen/4",
    target: "_self",
  },
  {
    title: "Pur edelstahl",
    img: PurEdelstahl,
    link: "#schalten-und-bedienen/5",
    target: "_self",
  },
  {
    title: (
      <>
        solo<sup>®</sup>
      </>
    ),
    img: Solo,
    link: "#schalten-und-bedienen/6",
    target: "_self",
  },
  {
    title: (
      <>
        Busch-axcent<sup>®</sup> pur
      </>
    ),
    img: BuschAxcentPur1,
    link: "#schalten-und-bedienen/7",
    target: "_self",
  },
  {
    title: (
      <>
        Busch-axcent<sup>®</sup> pur Glas
      </>
    ),
    img: BuschAxcentPurGlass,
    link: "#schalten-und-bedienen/8",
    target: "_self",
  },
  {
    title: (
      <>
        Busch-axcent<sup>®</sup>
      </>
    ),
    img: BuschAxcentPur2,
    link: "#schalten-und-bedienen/9",
    target: "_self",
  },
  {
    title: (
      <>
        future<sup>®</sup> linear
      </>
    ),
    img: FutureLinear,
    link: "#schalten-und-bedienen/10",
    target: "_self",
  },
  {
    title: (
      <>
        Busch-balance<sup>®</sup> SI
      </>
    ),
    img: BuschBalanceSI,
    link: "#schalten-und-bedienen/11",
    target: "_self",
  },
  {
    title: "Reflex SI",
    img: ReflexSI,
    link: "#schalten-und-bedienen/12",
    target: "_self",
  },
  {
    title: (
      <>
        Allwetter 44<sup>®</sup>
      </>
    ),
    img: Allwetter44,
    link: "#schalten-und-bedienen/13",
    target: "_self",
  },
  {
    title: (
      <>
        ocean<sup>®</sup>
      </>
    ),
    img: Ocean,
    link: "#schalten-und-bedienen/14",
    target: "_self",
  },
];

export default function SchaltenUndBedienen() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "elektrospicker",
          target: "_blank",
          link: "https://www.busch-jaeger.de/media/download/Broschueren/de/bje/1713_Planungshandbuch_6_20_final_web.pdf",
        },
      ]}
      background={{
        alignment: "left",
        width: "40%",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Schalten & Bedienen" subTitle="Übersicht" />
        </Grid>
        <Grid container>
          <Grid item xs={4} lg={3}>
            <QProductGroup
              width="100%"
              height="100%"
              colWidthSM={12}
              textBoxHight="fit-content"
              items={[
                {
                  title: "Busch-art linear",
                  img: BuschArtLinear,
                  link: "#schalten-und-bedienen/1",
                  target: "_self",
                  date: "2023-08-31",
                  chiplabel: "ab September"
                },
              ]}
            />
          </Grid>
          <Grid item xs={8} lg={9}>
            <QProductGroup
              width="100%"
              colWidthSM={3}
              colWidthLG={2}
              textBoxHight="fit-content"
              items={itemlist}
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
