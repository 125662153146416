const ADD_CATEGORY = "playbook/settings/ADD_CATEGORY";
const REMOVE_CATEGORY = "playbook/settings/REMOVE_CATEGORY";
const REST_CUSTOM_CATEGORIES = "playbook/settings/REST_CUSTOM_CATEGORIES";

const initialState = {
  customCategories: [],
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY: {
      return {
        ...state,
        customCategories: [...state.customCategories, action.category],
      };
    }
    case REMOVE_CATEGORY: {
      return {
        ...state,
        customCategories: state.customCategories.filter(
          (c) => c !== action.category
        ),
      };
    }
    case REST_CUSTOM_CATEGORIES: {
      return {
        ...state,
        customCategories: [],
      };
    }
    default:
      return state;
  }
};

//actions
export const addCategory = (category) => ({
  type: ADD_CATEGORY,
  category,
});

export const removeCategory = (category) => ({
  type: REMOVE_CATEGORY,
  category,
});

export const resetCustomCategories = () => ({
  type: REST_CUSTOM_CATEGORIES,
});

//selector
export const getSettingsData = (state) => state.settings;
export const getCustomCategories = (state) => state.settings.customCategories;

export default settings;
