import Uebersicht from "./slides/Uebersicht";
import Appnext from "./slides/APPnext";
import Marke from "./slides/Marke";
import NeubauRenovation from "./slides/NeubauRenovation";
import Sicherheit2 from "./slides/Sicherheit-2";
import Sprachsteuerung from "./slides/Sprachsteuerung";
import Visualisierung from "./slides/Visualisierung";

export default function DasSystem() {
  return [
    <Uebersicht anchor="uebersicht" />,
    <Appnext anchor="App-next" />,
    <Sprachsteuerung anchor="sprachsteuerung" />,
    <NeubauRenovation anchor="neubau-renovation" />,
    <Visualisierung anchor="visualisierung" />,
    <Marke anchor="marke" />,
    <Sicherheit2 anchor="sicherheit-2" />,
  ];
}
