import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/49.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/beleuchtungssteuerung/beleuchtungssteuerung.jpg";

export default function BuschFreeAtHomeBeleuchtungOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-freeathome-flex",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://www.busch-jaeger.de/bje-flex/static/media/Garten-Video_DE.91ccc9ee50de5e8014da.mp4",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup> flex, Beleuchtungssteuerung
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Busch-free@home® flex bietet die Möglichkeit den Garten noch
                besser in Szene zu setzen. Den bestehenden Kontrollschalter
                durch einen Relaiseinsatz 1-fach oder 2-fach ersetzen. Über das
                Bedienelement wireless lässt sich die Beleuchtung fernbedienen
                oder über Astrofunktion beliebig ein- und ausschalten.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Vor-Ort-Bedienung",
                  "Zeitschaltuhr mit Astro",
                  "Szenen/Gruppenbedienung",
                  "Mit Busch- free@home kombiniert: Erweiterung um Philips Hue, Ledvance und Sprachsteuerung",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
