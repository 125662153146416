import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/26.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/led-dimmer-flex/led-dimmer-und-relais-flex.png";

export default function LEDDimmerUndRelaisFlexOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "right",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-tastdimmer",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="LED-Dimmer & Relais flex" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Im Laufe der Jahre werden immer mehr Leuchten geschaltet oder
                gedimmt. In architektonisch offenen Wohn-/Essbereichen sind es
                eine Vielzahl von Verbrauchern geworden. Hilfreich ist dann das
                Bedienen von Gruppen oder das Nutzen von Szenen
                (Grundbeleuchtung, Alles Ein, Alles Aus, etc.). Die
                Relaiseinsätze und LED-Dimmer flex können drahtgebunden oder
                wireless in Gruppen oder Szenen zusammengefasst werden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Komfortable Bedienung",
                  "Nebenstellenbetrieb",
                  "Beim Betreten oder Verlassen nicht alles einzeln schalten müssen",
                  "Bei Dimmern können Presets/Werte gespeichert warden",
                  "Symbole auf den Wippen erleichtern die Zuordnung",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="Dreh-/Tastdimmer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
