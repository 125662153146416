import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "../SvgIcons/CloseIcon";

const useStyles = makeStyles(() => ({
  closeButton: {
    position: "fixed",
    right: "0",
    top: "10px",
    right: "10px",
    zIndex: "999",
  },
  inheritHeight: {
    height: "inherit",
    overflow: "hidden",
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function QDialog({ onCancel, open, children }) {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onCancel}
      TransitionComponent={Transition}
    >
      <IconButton
        className={classes.closeButton}
        color="inherit"
        onClick={onCancel}
        aria-label="close"
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      <Grid
        container
        justify="center"
        alignItems="center"
        className={clsx(classes.inheritHeight)}
      >
        <Grid item>
          <Box>{children}</Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
