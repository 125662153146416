import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/9.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/eingang/tuerkommunikation/tuerkommunikation.png";
import Overlay from "../../../../../../../../../assets/renovation-und-neubau/raume/eingang/tuerkommunikation/overlay.jpg";

export default function TuerkommunikationOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-welcome",
          target: "_blank",
        },
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/smarter-home/systeme/busch-welcome-ip",
          target: "_blank",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: Overlay,
              alt: "Alt/Neu Vergleich",
            },
          ],
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Türkommunikation" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Audio- oder Videolösungen mit Langlebigkeit und Stil. Perfekter,
                ungestörter Klang oder eine Kombination aus Ton und Video. Mit
                der Video Außenstation können Sie jeden Besucher klar sehen, Tag
                oder Nacht.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "In den Farben Alu, Anthrazit, Weiß oder Edelstahl",
                  "Als 2-Draht oder IP-Variante",
                  "Audio oder Video",
                  "Mit Zutrittsteuerung kombinierbar",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="60vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
