import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import PlaceholderImg from "../../../../../../../assets/renovation-und-neubau/placeholder.jpg";
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/49.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder/8.jpg";

const items = [
  {
    title: "Busch-Wächter® 90/220",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2885&tx_nlbjproducts_catalog%5BcatStdArtikel%5D=3601&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=d17c3cca0cbbb0672598d5697a1ffd40",
    },
  },
  {
    title: "Busch-Wächter® 110",
    img: Image2,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-aussen/1",
    },
  },
  {
    title: "Busch-Wächter® 220",
    img: Image3,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-aussen/2",
    },
  },
  {
    title: "Busch-Wächter® 280",
    img: Image4,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-aussen/3",
    },
  },
  { placeholder: true },
  {
    title: "Decken-/Eckadapter",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2887&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=5c7da865c21bd15e2e35eb9c0f466b8c",
    },
  },
  {
    title: "IR-Handsender",
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2888&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=eeeceaae1d002c881b9a3510b6608983",
    },
  },
  {
    title: "Service-Handsender",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=2889&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=a41975cb85cdc94a90faae5844851c35",
    },
  },
];

export default function BewegungsmelderOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "right",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/raumtemperaturregler",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Bewegungsmelder Outdoor" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Automatisches Licht bietet neben mehr Sicherheit durch gut
                ausgeleuchtete Wege, besonders auch mehr Komfort und Effizienz.
                Sie sollten zuverlässig sein – bei jedem Wetter!
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
