import { Box, Grid } from "@material-ui/core";

import backgroundLichtschalter from "../../../../../../../../../assets/_backgrounds/cwa/busch-art-linear/Holz-Walnuss.jpg";

import QHeader from "../../../../../../../../../components/QHeader";
import Slide from "../../../../../../../../../components/Slide";
import QList from "../../../../../../../../../components/QList";
import CenteredImage from "../../../../../../../../../components/CenteredImage";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

import SchalterImage from "../../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-gesamt/holz-walnuss.png";

// Images
import Image1 from "../../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/produktvarianten/holz-walnuss-1.jpg";
import Image2 from "../../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/produktvarianten/holz-walnuss-2.jpg";
import Image3 from "../../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/produktvarianten/holz-walnuss-3.jpg";

export default function HolzWalnuss({ swiperRef }) {
  const backToFirstSlide = () => {
    swiperRef.current.swiper.slideTo(0);
  };
  return (
    <Slide
      background={{
        src: backgroundLichtschalter,
        backgroundPosition: "right",
      }}
    >
      <CenteredImage
        shadow
        src={SchalterImage}
        alt="Busch-art linear | Holz Walnuss"
        height="40vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Busch-art linear"
            subTitle="Holz Walnuss"
            arrowOverride={backToFirstSlide}
          />
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Box
              height="64vh"
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <QList
                items={[
                  "Rahmen in Echtmaterial",
                  "Bedienwippe in studioweiß, studioweiß matt, schwarz matt",
                ]}
                dense
              />
              <ImageComponent
                col={4}
                wrapperWidth="100%"
                align="left"
                images={[
                  {
                    src: Image1,
                    alt: "Busch-art linear | Jalousiesteuerung",
                  },
                  {
                    src: Image2,
                    alt: "Busch-art linear | Schuko/USB A/C",
                  },
                  {
                    src: Image3,
                    alt: "Busch-art linear | Schuko Steckdose",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
