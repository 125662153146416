/* Decento */
import decentoImage from "../assets/cwa/system/tiny/1_1.jpg";

/* carat */
import caratImage1 from "../assets/cwa/system/tiny/2_1.jpg";
import caratImage2 from "../assets/cwa/system/tiny/2_2.jpg";
import caratImage3 from "../assets/cwa/system/tiny/3_1.jpg";
import caratImage4 from "../assets/cwa/system/tiny/3_2.jpg";
import caratImage5 from "../assets/cwa/system/tiny/3_3.jpg";
import caratImage6 from "../assets/cwa/system/tiny/4_1.jpg";
import caratImage7 from "../assets/cwa/system/tiny/4_2.jpg";
import caratImage8 from "../assets/cwa/system/tiny/4_3.jpg";
import caratImage9 from "../assets/cwa/system/tiny/5_1.jpg";
import caratImage10 from "../assets/cwa/system/tiny/7_1.jpg";

/* pur edelstahl */
import purEdelstahlImage from "../assets/cwa/system/tiny/8_1.jpg";

/* solo */
import soloImage1 from "../assets/cwa/system/tiny/13_1.jpg";
import soloImage2 from "../assets/cwa/system/tiny/14_1.jpg";
import soloImage3 from "../assets/cwa/system/tiny/15_1.jpg";

/* busch axcent pur */
import buschAxcentPurImage1 from "../assets/cwa/system/tiny/16_1.jpg";
import buschAxcentPurImage2 from "../assets/cwa/system/tiny/17_1.jpg";
import buschAxcentPurImage3 from "../assets/cwa/system/tiny/18_1.jpg";
import buschAxcentPurImage4 from "../assets/cwa/system/tiny/19_1.jpg";
import buschAxcentPurImage5 from "../assets/cwa/system/tiny/19_2.jpg";
import buschAxcentPurImage6 from "../assets/cwa/system/tiny/19_3.jpg";
import buschAxcentPurImage7 from "../assets/cwa/system/tiny/19_4.jpg";
import buschAxcentPurImage8 from "../assets/cwa/system/tiny/20_1.jpg";
import buschAxcentPurImage9 from "../assets/cwa/system/tiny/20_2.jpg";
import buschAxcentPurImage10 from "../assets/cwa/system/tiny/20_3.jpg";
import buschAxcentPurImage11 from "../assets/cwa/system/tiny/20_4.jpg";
import buschAxcentPurImage12 from "../assets/cwa/system/tiny/24_1.jpg";
import buschAxcentPurImage13 from "../assets/cwa/system/tiny/26_2.jpg";
import buschAxcentPurImage14 from "../assets/cwa/system/tiny/22_1.jpg";
import buschAxcentPurImage15 from "../assets/cwa/system/tiny/22_2.jpg";
import buschAxcentPurImage16 from "../assets/cwa/system/tiny/22_3.jpg";
import buschAxcentPurImage17 from "../assets/cwa/system/tiny/23_1.jpg";
import buschAxcentPurImage18 from "../assets/cwa/system/tiny/23_2.jpg";
import buschAxcentPurImage19 from "../assets/cwa/system/tiny/23_3.jpg";

/* busch axcent */
import buschAxcentImage1 from "../assets/cwa/system/tiny/24_1.jpg";
import buschAxcentImage2 from "../assets/cwa/system/tiny/25_1.jpg";
import buschAxcentImage3 from "../assets/cwa/system/tiny/25_2.jpg";
import buschAxcentImage4 from "../assets/cwa/system/tiny/25_3.jpg";
import buschAxcentImage5 from "../assets/cwa/system/tiny/26_1.jpg";
import buschAxcentImage6 from "../assets/cwa/system/tiny/26_2.jpg";

/* busch art linear */
import buschArtLinearImage1 from "../assets/cwa/system/tiny/42_1.jpg";
import buschArtLinearImage2 from "../assets/cwa/system/tiny/42_2.jpg";
import buschArtLinearImage3 from "../assets/cwa/system/tiny/42_3.jpg";

/* future linear */
import futureLinearImage1 from "../assets/cwa/system/tiny/31_1.jpg";
import futureLinearImage2 from "../assets/cwa/system/tiny/32_1.jpg";
import futureLinearImage3 from "../assets/cwa/system/tiny/33_1.jpg";
import futureLinearImage4 from "../assets/cwa/system/tiny/34_1.jpg";
import futureLinearImage5 from "../assets/cwa/system/tiny/35_1.jpg";
import futureLinearImage6 from "../assets/cwa/system/tiny/36_1.jpg";

/* busch balance si */
import buschBalanceSIImage1 from "../assets/cwa/system/tiny/37_1.jpg";

/* busch duro 2000 si si linear */
import buschDuro200Image1 from "../assets/cwa/system/tiny/38_1.jpg";
import buschDuro200Image2 from "../assets/cwa/system/tiny/39_1.jpg";

/* reflex so si linear */
import reflexSOSILinearImage1 from "../assets/cwa/system/tiny/40_1.jpg";
import reflexSOSILinearImage2 from "../assets/cwa/system/tiny/41_1.jpg";

export const tableData = [
  <>
    SCHUKO<sup>®</sup> Steckdose
  </>,
  <>
    SCHUKO<sup>®</sup> Doppelsteckdose
  </>,
  "Universal-Aus- und -Wechselschalter",
  "Serienschalter",
  "Taster mit Symbol",
  <>
    2-fach Kombination
    <br />2 SCHUKO<sup>®</sup> Steckdosen
  </>,
  <>
    Kombination Aus/Wechselschalter
    <br />
    und SCHUKO<sup>®</sup> Steckdose
  </>,
  <>
    2-fach-Kombination Serienschalter
    <br />
    SCHUKO<sup>®</sup> Steckdose
  </>,
  "Tastdimmer",
];

export const lichtschalterData = [
  {
    name: "decento",
    label: (
      <>
        Decento<sup>®</sup>
      </>
    ),
    group: [
      {
        price: [78.28, null, 158.4, 169.0, 174.0, null, null, null, null],
        items: [
          {
            label: "W.R. studioweiß (64) ähnlich RAL 9016",
            image: decentoImage,
          },
        ],
      },
    ],
  },
  {
    name: "carat",
    label: (
      <>
        carat<sup>®</sup>
      </>
    ),
    group: [
      {
        price: [
          214.23,
          null,
          222.35,
          243.24,
          225.38,
          334.11,
          342.23,
          236.73,
          367.32,
        ],
        items: [
          {
            label: "W. studioweiß (84) mit R. Bronze(821)Wippe ähnl. RAL 9016",
            image: caratImage1,
          },
          {
            label: "W. studioweiß (84)mit R. Gold (823)Wippe ähnl. RAL 9016",
            image: caratImage2,
          },
        ],
      },
      {
        price: [
          60.6,
          null,
          68.72,
          79.43,
          71.75,
          109.31,
          117.43,
          121.58,
          213.69,
        ],
        items: [
          {
            label:
              "W. studioweiß (84) mit R. Edelstahl(860)Wippe ähnl. RAL 9016",
            image: caratImage3,
          },
          {
            label:
              "W. studioweiß (84) mit R. weißglas (811)Wippe ähnl. RAL 9016",
            image: caratImage4,
          },
          {
            label:
              "W. studioweiß (84) mit R. Glas schwarz (825)Wippe ähnl. RAL 9016",
            image: caratImage5,
          },
        ],
      },
      {
        price: [65.5, null, 72.99, 84.01, 76.07, 119.11, 126.6, 131.06, 213.69],
        items: [
          {
            label:
              "W. anthrazit (81) mit R. Edelstahl(860)Wippe ähnl. RAL 7021",
            image: caratImage6,
          },
          {
            label:
              "W. anthrazit (81) mit R. weißglas (811)Wippe ähnl. RAL 7021",
            image: caratImage7,
          },
          {
            label:
              "W. anthrazit (81) mit R. Glas schwarz (825)Wippe ähnl. RAL 7021",
            image: caratImage8,
          },
        ],
      },
      {
        price: [
          115.27,
          null,
          120.74,
          131.65,
          123.74,
          190.64,
          196.11,
          218.28,
          264.69,
        ],
        items: [
          {
            label:
              "W. schwarz matt (885) mit R. Porzellan anthrazit (815)Wippe ähnl. RAL 9005",
            image: caratImage9,
          },
        ],
      },
      {
        price: [
          165.23,
          null,
          173.35,
          184.06,
          176.38,
          251.46,
          259.58,
          263.73,
          318.32,
        ],
        items: [
          {
            label: "W. studioweiß (84)mit R. Chrom(826)Wippe ähnl. RAL 9016",
            image: caratImage10,
          },
        ],
      },
    ],
  },
  {
    name: "pur-edelstahl",
    label: <>pur edelstahl</>,
    group: [
      {
        price: [45.7, null, 49.68, 60.79, 52.69, 82.02, 86.0, 115.29, 188.33],
        items: [
          {
            label: "W./R. edelstahl(866)",
            image: purEdelstahlImage,
          },
        ],
      },
    ],
  },
  {
    name: "solo",
    label: (
      <>
        solo<sup>®</sup>
      </>
    ),
    group: [
      {
        price: [19.98, null, 28.1, 38.81, 31.13, 33.18, 41.3, 45.45, 173.07],
        items: [
          {
            label: "W./R. studio weiß (84) ähnl. RAL 9016",
            image: soloImage1,
          },
        ],
      },
      {
        price: [23.33, null, 31.45, 42.17, 34.48, 45.43, 53.55, 57.71, 176.42],
        items: [
          {
            label:
              "W. elfenbein weiß (82) mit R. chrom matt (80) W. ähnl. RAL 1013",
            image: soloImage2,
          },
        ],
      },
      {
        price: [31.32, null, 36.51, 47.63, 39.59, 61.45, 66.64, 71.2, 179.87],
        items: [
          {
            label: "W. graumetallic (803) mit R. chrom glanz (80G)",
            image: soloImage3,
          },
        ],
      },
    ],
  },
  {
    name: "busch-axcent-pur",
    label: (
      <>
        Busch-axcent<sup>®</sup> pur
      </>
    ),
    group: [
      {
        price: [30.58, null, 38.7, 59.59, 41.73, 52.05, 60.17, 64.32, 183.67],
        items: [
          {
            label: "W. studioweiß (84)mit R. Titan (276)",
            image: buschAxcentPurImage1,
          },
        ],
      },
      {
        price: [35.48, null, 42.97, 53.99, 46.05, 61.85, 69.34, 73.8, 183.67],
        items: [
          {
            label: "W. anthrazit (81) ähnl. RAL 7021 mit R. Platin (270)",
            image: buschAxcentPurImage2,
          },
        ],
      },
      {
        price: [38.17, null, 43.64, 54.55, 46.64, 67.23, 72.7, 94.87, 187.59],
        items: [
          {
            label: "W./R. schwarz matt (275) ähnl. RAL 9005",
            image: buschAxcentPurImage3,
          },
        ],
      },
      {
        price: [
          67.19,
          null,
          72.85,
          83.63,
          75.8,
          122.57,
          128.23,
          132.45,
          217.15,
        ],
        items: [
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. betongrau (298)",
            image: buschAxcentPurImage4,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. schiefergrau(290)",
            image: buschAxcentPurImage5,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. papierblau (248)",
            image: buschAxcentPurImage6,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. papierbraun (243)",
            image: buschAxcentPurImage7,
          },
        ],
      },
      {
        price: [67.73, null, 73.2, 84.11, 76.2, 123.65, 129.12, 133.47, 217.15],
        items: [
          {
            label: "W. schwarz matt (885)ähnl. RAL 9005 mit R. betongrau (298)",
            image: buschAxcentPurImage8,
          },
          {
            label:
              "W. schwarz matt (885)ähnl. RAL 9005 mit R. schiefergrau(290)",
            image: buschAxcentPurImage9,
          },
          {
            label: "W. schwarz matt (885)ähnl. RAL 9005 mit R.papierblau (248)",
            image: buschAxcentPurImage10,
          },
          {
            label:
              "W. schwarz matt (885)ähnl. RAL 9005 mit R. papierbraun (243)",
            image: buschAxcentPurImage11,
          },
        ],
      },
      {
        price: [
          60.14,
          null,
          68.26,
          89.15,
          71.29,
          108.47,
          116.59,
          120.74,
          213.23,
        ],
        items: [
          {
            label: "W. schwarz matt (885)ähnl. RAL 9005 mit R. betongrau (298)",
            image: buschAxcentPurImage12,
          },
          {
            label:
              "W. schwarz matt (885)ähnl. RAL 9005 mit R. schiefergrau(290)",
            image: buschAxcentPurImage13,
          },
        ],
      },
      {
        price: [
          67.19,
          null,
          72.85,
          83.63,
          75.8,
          122.57,
          128.23,
          132.45,
          217.15,
        ],
        items: [
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. Glas Auster (229)",
            image: buschAxcentPurImage14,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. Glas Koralle (227)",
            image: buschAxcentPurImage15,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. Glas Purpur (223)",
            image: buschAxcentPurImage16,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. Glas Avocado (226)",
            image: buschAxcentPurImage17,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. Glas Ozean (228)",
            image: buschAxcentPurImage18,
          },
          {
            label:
              "W. studioweiß matt (884)ähnl. RAL 9016 mit R. Glas Sonne (225)",
            image: buschAxcentPurImage19,
          },
        ],
      },
    ],
  },
  {
    name: "busch-axcent",
    label: (
      <>
        Busch-axcent<sup>®</sup>
      </>
    ),
    group: [
      {
        price: [20.43, null, 28.55, 49.44, 31.58, 33.77, 41.89, 46.04, 173.52],
        items: [
          {
            label: "W. studioweiß (84) mit R. weiß (284) W. ähnl. RAL 9016",
            image: buschAxcentImage1,
          },
        ],
      },
      {
        price: [23.35, null, 31.47, 52.36, 34.5, 45.45, 53.57, 57.72, 176.44],
        items: [
          {
            label: "W. studioweiß (84) mit R. gelb (285) ähnl. RAL 1018",
            image: buschAxcentImage2,
          },
          {
            label: "R. grün (286)ähnl. RAL 6032",
            image: buschAxcentImage3,
          },
          {
            label: "R. rot (287) ähnl. RAL 3020",
            image: buschAxcentImage4,
          },
          {
            label: "R. blau (288) ähnl. RAL 5002",
            image: buschAxcentImage5,
          },
          {
            label: "R. schwarz (281) ähnl. RAL 9005",
            image: buschAxcentImage6,
          },
        ],
      },
    ],
  },
  {
    name: "busch-art-linear",
    label: (
      <>
        Busch-art linear<sup>®</sup>
      </>
    ),
    group: [
      {
        price: [24.4, null, 29.57, 40.48, 32.57, 46.23, 51.4, 55.65, 170.8],
        items: [
          {
            label: "W./R. studioweiß(44G)ähnl. RAL 9016",
            image: buschArtLinearImage1,
          },
          {
            label: "W./R. studioweißmatt(44M)ähnl. RAL 9016",
            image: buschArtLinearImage2,
          },
          {
            label: "W./R. schwarz matt(45M)ähnl. RAL 9005",
            image: buschArtLinearImage3,
          },
        ],
      },
    ],
  },
  {
    name: "future-linear",
    label: (
      <>
        future<sup>®</sup> linear
      </>
    ),
    group: [
      {
        price: [15.65, 21.85, 23.77, 44.66, 26.8, 26.58, 34.7, 38.85, 168.74],
        items: [
          {
            label: "W./R. studioweiß(84/184 K)ähnl. RAL 9016",
            image: futureLinearImage1,
          },
        ],
      },
      {
        price: [16.03, 21.85, 24.15, 34.87, 27.18, 27.0, 35.12, 39.28, 169.12],
        items: [
          {
            label: "W./R. elfenbeinweiß(82/182 K)ähnl. RAL 1013",
            image: futureLinearImage2,
          },
        ],
      },
      {
        price: [22.98, 27.26, 30.47, 41.49, 33.55, 43.26, 50.75, 55.21, 171.17],
        items: [
          {
            label: "W./R. anthrazit(81/181 K)ähnl. RAL 7021",
            image: futureLinearImage3,
          },
        ],
      },
      {
        price: [28.79, 30.81, 33.98, 44.64, 37.06, 46.21, 56.33, 60.43, 177.34],
        items: [
          {
            label: "W./R. alusilber(83/183 K)ähnl. RAL 9006",
            image: futureLinearImage4,
          },
        ],
      },
      {
        price: [26.88, 29.37, 32.54, 43.32, 35.49, 47.66, 53.32, 57.54, 176.84],
        items: [
          {
            label: "W./R. studioweiß matt (884)ähnl. RAL 9016",
            image: futureLinearImage5,
          },
        ],
      },
      {
        price: [27.74, 29.37, 33.21, 44.12, 36.21, 49.13, 54.6, 58.95, 177.16],
        items: [
          {
            label: "W./R. schwarz matt (885)ähnl. RAL 9005",
            image: futureLinearImage6,
          },
        ],
      },
    ],
  },
  {
    name: "busch-balance-si",
    label: (
      <>
        Busch-balance<sup>®</sup> SI
      </>
    ),
    group: [
      {
        price: [8.6, 17.6, 19.12, 29.82, 30.51, 21.55, 29.46, 33.6, 165.04],
        items: [
          {
            label: "W./R. alpinweiß (914) ähnl. RAL 9010",
            image: buschBalanceSIImage1,
          },
        ],
      },
    ],
  },
  {
    name: "busch-duro-200-si-si-linear",
    label: (
      <>
        Busch-Duro 2000<sup>®</sup> SI/SI Linear
      </>
    ),
    group: [
      {
        price: [9.24, 18.94, 19.73, 30.76, 24.47, 23.15, 30.83, 35.3, 165.37],
        items: [
          {
            label: "W./R. weiß (212) ähnl. RAL 1013",
            image: buschDuro200Image1,
          },
          {
            label: "W./R. weiß (212) ähnl. RAL 1013",
            image: buschDuro200Image2,
          },
        ],
      },
    ],
  },
  {
    name: "reflex-so-si-linear",
    label: <>Reflex SI/SI Linear</>,
    group: [
      {
        price: [8.6, 17.6, 19.12, 25.68, 23.77, 21.55, 29.46, 33.6, 165.04],
        items: [
          {
            label: "W./R. alpinweiß(214) ähnl. RAL 9010",
            image: reflexSOSILinearImage1,
          },
          {
            label: "W./R. alpinweiß(214) ähnl. RAL 9010",
            image: reflexSOSILinearImage2,
          },
        ],
      },
    ],
  },
];
