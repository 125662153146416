import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

export const colors = {
  primary: "#009FE3",
  secondary: "#ffff",
};

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: "light",
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#ebebeb",
      },
      common: {
        blue: "#007bff",
        indigo: "#6610f2",
        purple: "#6f42c1",
        pink: "#e83e8c",
        red: "#dc3545",
        orange: "#fd7e14",
        yellow: "#ffc107",
        green: "#28a745",
        teal: "#20c997",
        cyan: "#17a2b8",
        white: "#fff",
        gray: "#6c757d",
        grayDark: "#343a40",
        primary: "#009FE3",
        secondary: "#F2F2F2",
        success: "#28a745",
        info: "#17a2b8",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        dark: "#343a40",
        // bjePrimary: "#00acec",
        // bjePrimaryBlack: "#000000",
        // abbPrimary: "#ff000f",
        // abbPrimaryWhite: "#ffffff",
        // grey10: "#ebebeb",
        // grey20: "#dbdbdb",
        // grey30: "#bababa",
        // grey40: "#9f9f9f",
        // grey50: "#868686",
        // grey60: "#696969",
        // grey70: "#464646",
        // grey80: "#333333",
        // grey90: "#1f1f1f",
        // grey100: "#0f0f0f",
      },
    },
    typography: {
      fontFamily: "Averta-Regular",
      fontWeightBold: "400",
      fontWeightLight: "400",
      fontWeightMedium: "400",
      fontWeightRegular: "400",
    },
    spacing: 8,
    shape: {
      borderRadius: 4,
    },
    props: {
      MuiTooltip: {
        arrow: true,
      },
      MuiAccordion: {
        square: true,
      },
      MuiButtonBase: {
        disableTouchRipple: true,
      },
    },
    overrides: {
      MuiListItemText: {
        primary: { fontSize: "1em" },
      },
      MuiGrid: {
        root: {},
      },
      MuiListItemIcon: {
        root: {
          fontSize: ".5em",
          minWidth: "25px",
          color: "black",
        },
      },
      MuiTab: {
        wrapper: {
          flexDirection: "row",
        },
      },
    },
  })
);
