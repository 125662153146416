import { h, Component, createRef } from "preact";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import {
  isLoggedIn,
  fetchUserLogout,
  fetchUserDataIfNeeded,
} from "../../../redux/modules/userReducer";
import { toast } from "react-toastify";
import { resetApp } from "../../../redux/modules/reducers";
import ChangeLogModal from "./ChangeLogModal";
import * as Sentry from "@sentry/react";
import QHeader from "../../QHeader/index";
import {
  getConsultantData,
  setConsultantCompany,
  setConsultantEmail,
  setConsultantFirstname,
  setConsultantLastname,
  setConsultantSalutation,
} from "../../../redux/modules/consultant";

/**
import {
  userHasPermission
} from "../../../redux/modules/userReducer";
**/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  setupPage: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
  },
  resetButton: {
    display: "flex !important",
    margin: "30px auto",
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  noUnderline: {
    borderBottom: "none",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  resetApp: {
    display: "flex !important",
    margin: "30px auto",
    float: "left",
  },
  version: {
    float: "right",
  },
}));

class ADMProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationLabelWidth: 0,
      regions: [],
      storageEstimate: null,
      localStorageEstimate: null,
      isFetchingCrmProto: true,
      companiesCount: 0,
      customerCount: 0,
      regionCount: 0,
    };

    // @TODO
    // @SVEN so wird dann abgerufen ob rechte da sind
    /**
    const request = {
      action: "create",
      object: "consultation",
      application: "b2c_playbook"
    }
    console.dir(request)
    userHasPermission(request).then(
      response => {
        console.dir(response.getValue())
      })

    request.action = "read"
    console.dir(request)
    userHasPermission(request).then(
      response => {
        console.dir(response.getValue())
      })

    // abruf der globalen policies
    request.application = null
    request.action = "create"
    request.object = "consultation"
    console.dir(request)
    userHasPermission(request).then(
      response => {
        console.dir(response.getValue())
      })

    request.action = "edit"
    console.dir(request)
    userHasPermission(request).then(
      response => {
        console.dir(response.getValue())
      })
      **/

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.logout = this.logout.bind(this);
    this.inputLabelSalutation = createRef();
    this.inputLabelRegion = createRef();
    this.refreshCacheAndReload = this.refreshCacheAndReload.bind(this);
    this.storageEstimateWrapper = this.storageEstimateWrapper.bind(this);
    this.formatBytes = this.formatBytes.bind(this);
    this.localStorageEstimate = this.localStorageEstimate.bind(this);
    this.feedback = this.feedback.bind(this);
  }

  async componentDidMount() {
    this.setState({
      salutationLabelWidth: this.inputLabelSalutation.current.offsetWidth,
      storageEstimate: await this.storageEstimateWrapper(),
      localStorageEstimate: this.localStorageEstimate(),
    });

    this.props.fetchUserDataIfNeeded();
  }

  storageEstimateWrapper() {
    if ("storage" in navigator && "estimate" in navigator.storage) {
      // We've got the real thing! Return its response.
      return navigator.storage.estimate();
    }

    if (
      "webkitTemporaryStorage" in navigator &&
      "queryUsageAndQuota" in navigator.webkitTemporaryStorage
    ) {
      // Return a promise-based wrapper that will follow the expected interface.
      return new Promise((resolve, reject) => {
        navigator.webkitTemporaryStorage.queryUsageAndQuota((usage, quota) => {
          resolve({ usage, quota });
        }, reject);
      });
    }

    // If we can't estimate the values, return a Promise that resolves with NaN.
    return Promise.resolve({ usage: NaN, quota: NaN });
  }

  localStorageEstimate() {
    let results = [];
    let _lsTotal = 0,
      _xLen,
      _x;
    for (_x in localStorage) {
      if (!localStorage.hasOwnProperty(_x)) {
        continue;
      }
      _xLen = (localStorage[_x].length + _x.length) * 2;
      _lsTotal += _xLen;
      results.push(`${_x.substr(0, 50)} = ${this.formatBytes(_xLen)}`);
    }
    return results;
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  handleFieldChange(e) {
    this.props.setSetupField({
      id: e.currentTarget.id,
      value: e.currentTarget.value,
    });
  }

  handleSelectChange(e) {
    this.props.setSetupField({
      id: e.target.name,
      value: e.target.value,
    });
  }

  logout() {
    this.props.fetchUserLogout();
    toast.success("Abmeldung erfolgreich.");
  }

  refreshCacheAndReload() {
    this.props.resetApp();
    this.props.fetchUserLogout();
  }

  feedback(e) {
    this.props.handleDialogClose(e);
    e.preventDefault();
    e.stopPropagation();

    Sentry.showReportDialog({
      eventId: Sentry.captureMessage("User Feedback Submitted"),
      title: "Playbook",
      subtitle: "",
      subtitle2:
        "Wenn Sie uns helfen möchten, teilen Sie uns bitte unten mit, was geschehen ist.",
      labelName: "Name",
      labelEmail: "Email",
      labelComments: "Was ist passiert?",
      labelClose: "Schließen",
      labelSubmit: "Feedback senden",
      errorGeneric:
        "Beim Einreichen Ihres Berichts ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      errorFormEntry:
        "Einige Felder waren ungültig. Bitte korrigieren Sie die Fehler und versuchen Sie es erneut.",
      successMessage: "Ihr Feedback wurde gesendet. Herzlichen Dank!",
      user: {
        email: this.props.email,
        name: `${this.props.firstname} ${this.props.lastname}`,
      },
    });
  }

  render() {
    const classes = useStyles();
    const { salutationLabelWidth } = this.state;
    const { salutation, firstname, lastname, email, company, isLoggedin } =
      this.props;

    return (
      <Grid container spacing={2} wrap="nowrap" direction="column">
        <Grid item>
          <Paper square>
            <Box p={2}>
              <Grid container direction="column">
                <Grid item>
                  <Grid item container direction="row">
                    <Grid item xs>
                      <QHeader title="Ihre Kontaktdaten" size="small" />
                    </Grid>
                    <Grid item xs>
                      {!isLoggedin ? (
                        <Button
                          style={{ float: "right" }}
                          href={`${process.env.PREACT_APP_SSOURL}authorize?response_type=code&client_id=${process.env.PREACT_APP_SSOCLIENTID}&popup=1`}
                        >
                          Anmelden mit myBUSCH-JAEGER
                        </Button>
                      ) : (
                        <Button
                          style={{ float: "right" }}
                          onClick={this.logout}
                        >
                          Abmelden
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          htmlFor="salutation"
                          ref={this.inputLabelSalutation}
                          id="salutation-select-outlined-label"
                        >
                          Anrede
                        </InputLabel>
                        <Select
                          native
                          labelId="salutation-select-outlined-label"
                          id="salutation"
                          value={salutation}
                          onChange={this.handleSelectChange}
                          labelWidth={salutationLabelWidth}
                          fullWidth="true"
                          inputProps={{
                            name: "salutation",
                            id: "salutation",
                          }}
                        >
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        onChange={this.handleFieldChange}
                        id="firstname"
                        value={firstname}
                        fullWidth
                        label="Vorname"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={this.handleFieldChange}
                        id="lastname"
                        value={lastname}
                        fullWidth
                        label="Nachname"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        onChange={this.handleFieldChange}
                        id="email"
                        type="email"
                        value={email}
                        disabled={isLoggedin}
                        fullWidth
                        label="E-Mail"
                        variant="outlined"
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Paper square>
            <Box p={2}>
              <Grid container direction="column">
                <Grid item>
                  <QHeader title="Firma" size="small" />
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            id="company_name"
                            value={company.name}
                            disabled
                            fullWidth
                            label="Firma"
                            onChange={this.handleFieldChange}
                            InputProps={{
                              classes: { underline: classes.noUnderline },
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            id="company_street"
                            value={company.street}
                            disabled
                            fullWidth
                            label="Straße"
                            onChange={this.handleFieldChange}
                            InputProps={{
                              classes: { underline: classes.noUnderline },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="company_postcode"
                            value={company.postcode}
                            disabled
                            fullWidth
                            label="PLZ"
                            onChange={this.handleFieldChange}
                            InputProps={{
                              classes: { underline: classes.noUnderline },
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            id="company_city"
                            value={company.city}
                            disabled
                            fullWidth
                            label="Stadt"
                            onChange={this.handleFieldChange}
                            InputProps={{
                              classes: { underline: classes.noUnderline },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <img src={company.logo} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Button
                className={`${classes.resetApp}`}
                variant="contained"
                color="secondary"
                size="large"
                onTouchStart={this.refreshCacheAndReload}
                onClick={this.refreshCacheAndReload}
              >
                Anwendungsspeicher leeren
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="flex-end">
            <Grid item>
              <ChangeLogModal />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { salutation, firstname, lastname, email, company } =
    getConsultantData(state);
  const isLoggedin = isLoggedIn(state);
  return {
    salutation,
    firstname,
    lastname,
    email,
    company,
    isLoggedin,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "salutation":
        {
          dispatch(setConsultantSalutation(valueSet.value));
        }
        break;
      case "firstname":
        {
          dispatch(setConsultantFirstname(valueSet.value));
        }
        break;
      case "lastname":
        {
          dispatch(setConsultantLastname(valueSet.value));
        }
        break;
      case "email":
        {
          dispatch(setConsultantEmail(valueSet.value));
        }
        break;
      case "company": {
        dispatch(setConsultantCompany(valueSet.value));
      }
    }
  },
  fetchUserLogout() {
    dispatch(fetchUserLogout());
  },
  resetApp() {
    dispatch(resetApp());
  },
  fetchUserDataIfNeeded() {
    dispatch(fetchUserDataIfNeeded());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ADMProfile);
