import { h } from "preact";
import { Grid } from "@material-ui/core";
import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/schalter/reflex-si.jpg";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import CenteredImage from "../../../../../../components/CenteredImage";
import Preisliste from "../preisliste";

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatKapitel%5D=14&tx_nlbjproducts_catalog%5Bcontroller%5D=CatDokument&cHash=cbf4d2611e53632652addc403c3c11cf",
    target: "_blank",
  },
  {
    type: "dialog",
    img: "preisliste",
    children: <Preisliste anchor="reflex-so-si-linear" />,
  },
];

export default function ReflexSI() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/reflex-si.png"
        alt="ReflexSI"
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Lichtschalter" subTitle="Reflex SI" />
        </Grid>
        <Grid item container>
          <Grid item xs={5} style={{ paddingRight: "50px" }}>
            <QList
              items={[
                "Funktional und variabel",
                "Materialität zwischen Thermo- und Duroplast wählbar",
                "Zeitloser Klassiker",
                "Gerundete oder sachlich quadratische Form",
                "Aufputzmontage möglich",
                "Erhältlich in 1-fach bis 5-fach",
                "Fügt sich perfekt in jedes Interieur ein",
              ]}
              dense
              
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
