import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/36.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/deckenlautsprecher/deckenlautsprecher.png";
import Deckenlautsprecher3Zoll from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/deckenlautsprecher/deckenlautsprecher-3.png";

export default function DeckenLautsprecherOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=229&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=7cea7f888fde1d52667db5e5f369a2f6",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Deckenlautsprecher" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Darfs ein wenig mehr sein? Oft bietet es sich an die
                Lautsprecher in Decken einzubringen. Z.B. wenn diese abgehängt
                werden. Das bietet mehr Klangqualität und es werden keine
                zusätzlichen Schalterdosen in der Wand benötigt, die man
                vielleicht lieber für Steckdosen hätte.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Deckenlautsprecher 3“",
                  "Deckenlautsprecher 5“",
                  "HiFi Deckenlautsprecher 5“",
                  "Lautsprechergitter mit Magnetverschluss",
                  "Mit Federbügeln",
                ]}
              />
              <ImageComponent
                wrapperWidth="100%"
                align="left"
                col={4}
                images={[
                  {
                    src: Deckenlautsprecher3Zoll,
                    alt: "Deckenlautsprecher 3“",
                  },
                  {
                    src: CenteredImg,
                    alt: "Deckenlautsprecher 5“",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Deckenlautsprecher 5“"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
