import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/39.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/busch-welcome-innenstation/innenstation-video.jpg";

export default function BuschWelcomeInnenstationVideo() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-welcome",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
                <br />
                Innenstation Video 12,6 cm (5")
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Wissen wer vor der Tür steht. Durch die kleine Innenstation
                wissen auch die Kinder, wer vor der Tür steht und können Freund
                in Empfang nehmen. Oder wenn die Eltern mal nicht zuhause sind
                und Fremde vor der Tür stehen auch mal nicht.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Schmales Hochformat",
                  "Hochauflösendes Touch-Display",
                  "Videohistorie",
                  "Induktionsschleife für Schwerhörige",
                  "Für UP-Standarddose",
                  "Aufputzmontage mithilfe des mitgelieferten Rahmens möglich",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="45vh"
                src={CenteredImg}
                shadow
                alt={
                  <>
                    Busch-Welcome<sup>®</sup>
                    <br />
                    Innenstation Video 12,6 cm (5")
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
