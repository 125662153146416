import { useEffect, useRef } from "preact/hooks";
import { StickyTable, Row, Cell } from "react-sticky-table";
import QHeader from "../../../../../components/QHeader";
import { Tooltip, Typography, makeStyles } from "@material-ui/core";
import {
  lichtschalterData,
  tableData,
} from "../../../../../databases/lichtschalter-preisbeispiele";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "98dvh",
    display: "grid",
    alignItems: "stretch",
    gridAutoFlow: "row",

  },
  table: {
    height: "83dvh",
    width: "calc(100vw - 32px)",
    "& img": { height: 50, width: 50, marginRight: "10px" },
  },
  fontWeightBold: {
    fontFamily: "Averta-Bold",
  },
  schalterStart: {
    borderRight: "none !important",
  },
  schalterMiddle: {
    borderRight: "none !important",
  },
  active: {
    backgroundColor: `${grey[100]} !important`,
  },
  thead: {
    span: {
      padding: "0px 5px",
      display: "block",
    },
    "span img": {
      width: "35px",
      height: "auto",
    },
  },
  fontSizeBig: {
    fontSize: "14px",
  },
}));
export default function Preisliste({ anchor }) {
  const classes = useStyles();
  const anchorRef = useRef(null);

  useEffect(() => {
    console.log(anchorRef);
    if (anchor && anchorRef.current) {
      if (typeof window !== "undefined") {
        anchorRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [anchor]);

  useEffect(() => {
    console.log(anchorRef);
  }, [anchorRef]);

  return (
    <div className={classes.container}>
      <QHeader title="Lichtschalter Preisbeispiele" colWidth={12} />
      <StickyTable stickyHeaderCount={2} className={classes.table}>
        {/* SCHALTER TABLE HEAD start */}
        <Row>
          <Cell />
          {lichtschalterData.map((serie) => {
            const placeholders = [];
            for (let i = 1; i < serie.group.length; i++) {
              placeholders.push(
                <Cell
                  className={anchor === serie.name ? classes.active : null}
                  ref={anchor === serie.name ? anchorRef : null}
                />
              );
            }
            return (
              <>
                <Cell
                  className={anchor === serie.name ? classes.active : null}
                  ref={anchor === serie.name ? anchorRef : null}
                >
                  <Typography
                    variant="body2"
                    className={classes.fontWeightBold}
                  >
                    {serie.label}
                  </Typography>
                </Cell>
                {placeholders}
              </>
            );
          })}
        </Row>
        <Row className={`${classes.thead}`}>
          <Cell>
            <Typography variant="body2" className={classes.fontWeightBold}>
              Kombinationsmöglichkeiten
            </Typography>
          </Cell>
          {lichtschalterData.map((serie) => {
            const group = [];
            serie.group.map((item) => {
              const items = [];
              item.items.map((subitem) => {
                items.push(
                  <Tooltip title={subitem.label}>
                    <img src={subitem.image} alt={subitem.label} />
                  </Tooltip>
                );
              });
              group.push(
                <Cell className={anchor === serie.name ? classes.active : null}>
                  <div className="d-flex">{items}</div>
                </Cell>
              );
            });
            return group;
          })}
        </Row>
        {tableData.map((label, i) => {
          return (
            <Row>
              <Cell>
                <Typography variant="body2">{label}</Typography>
              </Cell>
              {lichtschalterData.map((serie) => {
                const group = [];
                serie.group.map((item) => {
                  const priceNetto =
                    item.price[i] === null
                      ? "-"
                      : new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })
                          .format(item.price[i])
                          .replace("€", "");
                  const priceBrutto =
                    item.price[i] === null
                      ? "-"
                      : new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })
                          .format(item.price[i] * 1.19)
                          .replace("€", "");
                  group.push(
                    <Cell
                      className={anchor === serie.name ? classes.active : null}
                    >
                      <Typography
                        variant="body2"
                        className={classes.fontWeightBold}
                      >
                        {priceNetto}
                      </Typography>
                      <Typography variant="body2">{priceBrutto}</Typography>
                    </Cell>
                  );
                });
                return group;
              })}
            </Row>
          );
        })}
      </StickyTable>
      <Typography variant="body2">
        W. = Wippe, R = Rahmen. Die{" "}
        <strong>
          fett gedruckten Preise sind unverbindliche Preisempfehlung
        </strong>
        . Die Darunter notierten Preise enthalten 19 % MwSt.
      </Typography>
    </div>
  );
}
