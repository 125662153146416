import { h } from "preact";
import { useEffect } from "preact/hooks";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoggedIn,
  ssoAuth,
  fetchUserLogout,
  fetchUserDataIfNeeded,
  ssoRefresh
} from "./redux/modules/userReducer";
import * as R from "ramda";
import root from "window-or-global";
import { toast } from "react-toastify";
import { route } from "preact-router";
import * as B2CSDK from "@qmarketing/b2c-js-sdk";
import {
  fetchBackendState,
  getStateVersion,
} from "./redux/modules/backendState";

export default function AppWrap({ children, ...props }) {
  const dispatch = useDispatch();
  const isLoggedin = useSelector((state) => isLoggedIn(state));
  const version = useSelector((state) => getStateVersion(state));

  const getBackendState = async () => {
    let apiInstance = new B2CSDK.StateApi();
    await apiInstance.apiStateGet().then(
      (data) => {
        if (version !== data.version) {
          dispatch(
            fetchBackendState({
              modules: data.modules,
              version: data.version,
            })
          );
        }
      },
      (error) => {
        console.error(error);
        toast.error("Backend nicht erreichbar!");
      }
    );
  };

  useEffect(() => {
    getBackendState();
  }, []);

  useEffect(() => {
    if (isLoggedin) {
      ssoRefresh();
    }
  }, [isLoggedin]);

  useEffect(() => {
    const auth = async (code) => {
      await ssoAuth(code).then((resp) => {
        if (resp) {
          dispatch(fetchUserDataIfNeeded());
          toast.success("Anmeldung erfolgreich.");
        } else {
          fetchUserLogout();
          toast.error("Anmeldung fehlgeschlagen.");
        }
        route("/", true);
      });
    };

    dispatch(fetchUserDataIfNeeded());

    if (root) {
      const urlParams = new URLSearchParams(root.location.search);
      const code = urlParams.get("code");
      if (code && code !== "") {
        auth(code);
      }
    }

    return () => { };
  }, []);

  return <Box>{children}</Box>;
}
