import { Grid } from "@material-ui/core";

import backgroundLichtschalter from "../../../../../../../../../assets/_backgrounds/cwa/2.jpg";

import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import Slide from "../../../../../../../../../components/Slide";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BuschTrevion1 from "../../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/busch-trevion-keypad-1-4f.jpg";
import BuschTrevion2 from "../../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/busch-trevion-display.jpg";

export default function NatuerlichAuchSmart({ swiperRef }) {
  const backToFirstSlide = () => {
    swiperRef.current.swiper.slideTo(0);
  };
  return (
    <Slide
      background={{
        src: backgroundLichtschalter,
        backgroundPosition: "center",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-art linear" subTitle="Natürlich auch smart" />
        </Grid>
        <Grid item>
          <Grid container wrap="nowrap" spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Busch-art linear<sup>®</sup> stellt das volle Sortiment für alle
                Anwendungen bereit: Steuerung von Jalousie und Licht über
                Steckdosen und Telefon-anschlüssen bis hin zu Sonderfunktionen.
              </QBodyText>
              <QList
                items={[
                  "Elektroeinsätze",
                  <>
                    Busch-free@home<sup>®</sup>
                  </>,
                  "KNX",
                ]}
                dense
              />
              <ImageComponent
                col={4}
                wrapperWidth="100%"
                align="left"
                images={[
                  {
                    src: BuschTrevion1,
                    alt: "Busch-Trevion Keypad 1-4f",
                  },
                  {
                    src: BuschTrevion2,
                    alt: "Busch-Trevion Display 2,4“",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
