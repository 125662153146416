import { h } from "preact";
import { useState } from "preact/hooks";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import QScrollBody from "../QScrollBody";

export default function PDFViewer({
  path,
  scale,
  height,
  maxHeight,
  overrideHeight,
}) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
  };

  return (
    <QScrollBody maxHeight={maxHeight} overrideHeight={overrideHeight}>
      <Document
        file={path}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            height={height}
            scale={scale}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </QScrollBody>
  );
}

PDFViewer.defaultProps = {
  path: "",
  height: 500,
  scale: 2.8,
  maxHeight: "100vh",
  overrideHeight: null,
};
