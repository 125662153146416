import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/19.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kueche/BuschRadioDABPlus/busch-radio-dab-plus.png";
import BluetoothUebertragung from "./overlays/BluetoothUebertragung";

export default function BuschRadioDABPlusOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "right",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-radio-btconnect-dab",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "info",
          children: <BluetoothUebertragung />,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Radio BTconnect DAB+" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Busch-Radio BTconnect DAB+ Komfortabler geht's nicht. Ganz
                einfach regionale Radioprogramme in bester Qualität hören. Und
                obendrein nach Lust und Laune per Bluetooth seine eigene Musik
                vom Smartphone, Tablet oder Laptop streamen. Das Busch-Radio
                BTconnect macht's möglich.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "DAB+ und UKW-Empfang",
                  "Musik-Streaming vom mobilen Endgerät auf das Unterputzradio und die angebunden Einbaulautsprecher",
                  "Kopplung des Radios mit mobilen Bluetooth-Lautsprechern zur Wiedergabe des Radiosender",
                  "Beste Klangqualität, ohne Störungen",
                  "Hochauflösendes Farbdisplay zur Anzeige von mitgelieferten Zusatzdiensten wie z.B. Titelanzeige",
                  "Auch ohne BT erhältlich (s. Bad)",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="USB-Steckdosen"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
