import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../../components/CenteredImage";
import ImageComponent from "../../../../../../../components/ImageComponent";
import PDFViewer from "../../../../../../../components/PDFViewer";

export default function Jalousiesteuerung() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/jalousiesteuerung.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../../assets/pdf/preisbeispiele/Smarter-Home/Preisbeispiel Smarter Home - Jalousiesteuerung.pdf",
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../../assets/tuerkommunikation/smartphone/jalousiesteuerung.png"
        alt={<>Busch-free@home<sup>®</sup> Next App</>}
        height="50vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Box style={{ paddingRight: "100px" }}>
              <QBodyText fontWeight="bold" marginBottom="0px">
                Jalousiesteuerung
              </QBodyText>
              <QBodyText>
                Bietet optimalen Schutz vor Wärme im Sommer und vor Kälte im
                Winter
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Individuelle Zeitschaltuhr für jede Jalousie und Gruppe",
                  "Astrofunktion (Verschiebung der Fahrzeiten mit dem Sonnenauf-/untergang)",
                  "Optionale Anbindung an eine Wetterstation",
                  "Positionsanfahrt zur Beschattung",
                  <span key="logiken/aktionen">
                    Logiken/Aktionen:
                    <br />
                    „Wenn Sonne, dann Beschattung“
                  </span>,
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Jalousiesteuerung",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/jalousiesteuerung-left.jpg",
                  },
                  {
                    alt: "Wetterstation",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/jalousiesteuerung-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
