import { h } from "preact";
import { Grid } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";

export default function NeubauRenovation() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/neubau-und-renovation.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "right",
      }}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Neubau & Renovation
            </QBodyText>
            <QBodyText>
              Ob Neubau, Anbau oder Renovation – Busch-free@home<sup>®</sup>{" "}
              bietet in allen Bereichen eine Lösung durch drahtgebundene oder
              wireless Vernetzung.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Flexibel im Ausbau",
                "Drahtgebunden bietet die größtmögliche Sicherheit; Wireless bietet Flexibilität",
                "Sensor/Aktorkombinationen können dezentral geschaltet werden und bauen auf einer klassischen Elektroinstallation auf",
                "Kombination von wireless und drahtgebunden möglich",
              ]}
              dense
              variant="arrow"
            />
            <ImageComponent
              previewImgWidth="30vw"
              alt="Übersicht"
              src="../../../../../../../assets/tuerkommunikation/produkte/neubau-un-renovation.jpg"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
