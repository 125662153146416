import Uebersicht from "./slides/Uebersicht";
import Gateway from "./slides/Gateway";
import Indoor1 from "./slides/Indoor-1";
import Indoor2 from "./slides/Indoor-2";
import Outdoor1 from "./slides/Outdoor-1";
import Outdoor2 from "./slides/Outdoor-2";
import Wohnungsbau from "./slides/Wohnungsbau";
import Zutrittskontrolle from "./slides/Zutrittskontrolle";

export default function DasSystem() {
  return [
    <Uebersicht anchor="uebersicht" />,
    <Outdoor1 anchor="ourdoor-1" />,
    <Outdoor2 anchor="outdoor-2" />,
    <Indoor1 anchor="indoor-1" />,
    <Indoor2 anchor="indoor-2" />,
    <Wohnungsbau anchor="wohnungsbau" />,
    <Gateway anchor="gateway" />,
    <Zutrittskontrolle anchor="zutrittskontrolle" />,
  ];
}
