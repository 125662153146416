import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/24.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/busch-raumtemperaturregler/busch-raumtemperaturregler.png";
import Raumtemperaturregler1 from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/busch-raumtemperaturregler/busch-raumtemperaturregler-1.png";
import Raumtemperaturregler2 from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/busch-raumtemperaturregler/busch-raumtemperaturregler-2.png";

export default function BuschRaumtemperaturreglerOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/raumtemperaturregler",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Raumtemperaturregler" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Für präzise und pünktliche Wärme. Effiziente
                Raumtemperaturregler gibt es in verschiedenen Varianten. Ganz
                schlicht oder mit Umschalter für die Nachtabsenkung, mit Display
                oder Timer – für pünktliche Wärme in Schalterprogramm sauber
                integriert.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Timer",
                  "IST-Wert-Anzeige",
                  "Heizen/Kühlen",
                  "Nachtabsenkung/ECO-Betrieb",
                  "Fernfühler",
                ]}
              />
              <ImageComponent
                wrapperWidth="60%"
                align="left"
                col={6}
                images={[
                  {
                    src: Raumtemperaturregler1,
                    alt: "Busch-Raumtemperaturregler",
                  },
                  {
                    src: Raumtemperaturregler2,
                    alt: "Busch-Raumtemperaturregler",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Busch-Raumtemperaturregler"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
