import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/21.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/bluetooth-smart-switch/bluetooth-smart-switch.png";
import Montage from "../../../../../../_globalSlides/Montage";

export default function BluetoothSmartSwitchOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/bluetooth-smart-switch",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "info",
          children: <Montage />,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Bluetooth Smart Switch" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Mit dem Bluetooth Smart Switch können Sie Ihre Beleuchtung noch
                smarter fernsteuern. Der Schalter kann kabellos angebracht
                werden und ist dank "Energy Harvesting" besonders
                energiesparend. Mit Bluetooth Smart Switch können alle
                Casambi-fähigen Geräte ferngesteuert werden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Belegung der Wippe mit bis zu vier Funktionen",
                  'Batterielos und wartungsfrei durch "Energy Harvesting"',
                  "Eignet sich ideal für die Nachrüstung",
                  "Inbetriebnahme per App",
                  "Maximale Funktionssicherheit durch Mesh-Technologie",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="Dreh-/Tastdimmer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
