import { h } from "preact";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  sectionContainer: {
    overflow: "hidden",
  },
  content: {
    height: "100%",
    "& > div:nth-of-type(1)": {
      height: "inherit",
    },
  },
}));

export default function Section({ selector, children }) {
  const classes = useStyles();
  return (
    <Box className={`${selector} ${classes.sectionContainer}`}>
      <Box className={classes.content} id="content">
        {children}
      </Box>
    </Box>
  );
}

Section.defaultProps = {
  selector: "fullpage",
  children: [],
};
