import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../../components/ChapterCard";

// Overlay
import Bewegungsmelder1Overlay from "./slides/Bewegungsmelder-70-110";
import Bewegungsmelder2Overlay from "./slides/Bewegungsmelder-220-280";
import AllwetterIP44Overlay from "./slides/AllwetterIP44";
import OceanIP44Overlay from "../../../../../_globalSlides/OceanIP44Overlay";
import WetterstationOverlay from "./slides/Wetterstation";
import FriendsOfHueOverlay from "./slides/FriendsOfHue";
import FISchukomatOverlay from "./slides/FISchukomat";
import BuschFreeAtHomeBeleuchtungOverlay from "./slides/BuschFreeAtHomeBeleuchtung";
import DaemmerungsschalterOverlay from "./slides/Daemmerungsschalter";
import DomeKamera from "../../../../../_globalSlides/Dome-Kamera";

// Background Images
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/48.jpg";
import BewegungsmelderBackgroundImage from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/47.jpg";

// Bilder
import Bewegungsmelder1 from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/bewegungsmelder-220-280.jpg";
import Bewegungsmelder2 from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/bewegungsmelder-80-110.jpg";
import AllwetterIP from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/allwetter-44-up.jpg";
import Wetterstation from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/wetterstation.jpg";
import OceanIP44 from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/ocean-ip-44.jpg";
import FriendsOfHue from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/friends-of-hue.jpg";
import FiSchukomat from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/fi-schukomat.jpg";
import BuschFreeAtHomeFlex from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/busch-fre-at-home-flex.jpg";
import Daemmerungsschalter from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/daemmerungsschalter.jpg";
import Kamera from "../../../../../../../../assets/renovation-und-neubau/raume/garten-outdoor/kamera.jpg";

const items = [
  {
    title: "Bewegungsmelder 220°/280°",
    img: Bewegungsmelder1,
    menuOverlay: <Bewegungsmelder2Overlay />,
  },
  {
    title: "Bewegungsmelder 70°/110°",
    img: Bewegungsmelder2,
    menuOverlay: <Bewegungsmelder1Overlay />,
  },
  {
    title: "Allwetter IP44 uP",
    img: AllwetterIP,
    menuOverlay: <AllwetterIP44Overlay />,
  },
  {
    title: "Wetterstation",
    img: Wetterstation,
    menuOverlay: <WetterstationOverlay />,
  },
  {
    title: (
      <>
        Ozean IP44
        <br />
        Mit Beschriftungsfeld
      </>
    ),
    img: OceanIP44,
    menuOverlay: (
      <OceanIP44Overlay backgroundImg={BewegungsmelderBackgroundImage} />
    ),
  },
  {
    title: "Friends of hue",
    img: FriendsOfHue,
    menuOverlay: <FriendsOfHueOverlay />,
  },
  {
    title: "FI-Schukomat",
    img: FiSchukomat,
    menuOverlay: <FISchukomatOverlay />,
  },
  {
    title: (
      <>
        Busch-free@home<sup>®</sup> flex Beleuchtung
      </>
    ),
    img: BuschFreeAtHomeFlex,
    menuOverlay: <BuschFreeAtHomeBeleuchtungOverlay />,
  },
  {
    title: (
      <>
        Dämmerungs-
        <br />
        schalter
      </>
    ),
    img: Daemmerungsschalter,
    menuOverlay: <DaemmerungsschalterOverlay />,
  },
  {
    title: "Kamera",
    img: Kamera,
    menuOverlay: <DomeKamera />,
  },
];

export default function Garten() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Garten/Outdoor" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Ein Ort der aktiven und passiven Entspannung, des Kinderspiels
                und der Arbeit. Da wir viel Zeit hier verbringen, ist es
                wichtig, dass die elektrische Anlage vor allem sicher und
                praktisch ist.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {items.map((e, i) => {
              return (
                <Grid item xs={2}>
                  <ChapterCard chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
