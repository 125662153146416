import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import QDialog from "../../../../../../../components/QDialog";
import BuschArtlInearOverlay from "./overlay/BuschArtLinearOverlay";
import { useModal } from "mui-modal-provider";
import QSwiperJS from "../../../../../../../components/QSwiperJS";
import PDFViewer from "../../../../../../../components/PDFViewer";
import Nachhaltigkeit from "./overlay/nachhaltigkeit";

//Images
import JungleImage from "../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/green-jungle.png";
import SchwarzMatt from "../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-gesamt/schwarz-matt.jpg";
import StudioweissMatt from "../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-gesamt/studioweiss-matt.jpg";
import Studioweiss from "../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-gesamt/studioweiss.jpg";
import BuschArtLinearWhiteImage from "../../../../../../../assets/cwa/BuschArtLinearWhiteImage.webp"

// Schalter Slides
import HolzEiche from "./overlay/slides/HolzEiche";
import SchieferTerraRosso from "./overlay/slides/SchieferTerraRosso";
import Aluminium from "./overlay/slides/Aluminium";
import GlasSchwarzStrukturiert from "./overlay/slides/GlasSchwarzStrukturiert";
import GlasGoldVerspiegelt from "./overlay/slides/GlasGoldVerspiegelt";
import HolzWalnuss from "./overlay/slides/HolzWalnuss";
import AluminiumSchwarz from "./overlay/slides/AluminiumSchwarz";
import PapierSchwarz from "./overlay/slides/PapierSchwarz";
import GlasBlau from "./overlay/slides/GlasBlau";
import NatuerlichAuchSmart from "./overlay/slides/NatuerlichAuchSmart";
import ImageComponent from "../../../../../../../components/ImageComponent";
import QChip from "../../../../../../../components/QChip";
import Preisliste from "../../preisliste";

const useStyles = makeStyles((theme) => ({
  jungleBackground: {
    backgroundImage: `url("${JungleImage}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  model: {
    width: "100%",
    height: "70vh",
  },
  buttonGroup: {
    "& > *": {
      margin: "10px",
    },
  },
}));
export default function BuschArtLinear() {
  const classes = useStyles();
  const { showModal } = useModal();
  const openDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dialog = showModal(QDialog, {
      children: (
        <QSwiperJS>
          <BuschArtlInearOverlay />
          <HolzEiche />
          <SchieferTerraRosso />
          <Aluminium />
          <GlasSchwarzStrukturiert />
          <GlasGoldVerspiegelt />
          <HolzWalnuss />
          <AluminiumSchwarz />
          <PapierSchwarz />
          <GlasBlau />
        </QSwiperJS>
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };
  const open3DModel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dialog = showModal(QDialog, {
      children: <Nachhaltigkeit />,
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };
  const openSmartScreen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dialog = showModal(QDialog, {
      children: <NatuerlichAuchSmart />,
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const openModel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dialog = showModal(QDialog, {
      children: (
        <Box width="90dvw" height="90dvh">
          <iframe
            src="https://app.vectary.com/p/27ySVjVUbTx8gsq7wa4nZO"
            frameborder="0"
            width="100%"
            height="100%"
          ></iframe>
        </Box>
      ),
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };
  return (
    <Slide
      actions={[
        {
          type: "dialog",
          img: "3d-maximize",
          children: (
            <Box
              height="100vh"
              width="100vw"
              style={{ backgroundColor: "white" }}
            >
              <iframe
                src="https://app.vectary.com/p/27ySVjVUbTx8gsq7wa4nZO"
                frameborder="0"
                width="100%"
                height="100%"
              ></iframe>
            </Box>
          ),
        },
        {
          type: "dialog",
          img: "elektrospicker",
          target: "_blank",
          children: (
            <Grid container>
              <Grid item>
                <PDFViewer path="../../../../../../../assets/pdf/cwa/Bestellunterlage-Busch-art-linear.pdf" />
              </Grid>
            </Grid>
          ),
        },
        {
          type: "dialog",
          img: "pdf",
          target: "_blank",
          children: (
            <Grid container>
              <Grid item>
                <PDFViewer path="../../../../../../../assets/pdf/cwa/Busch-art-linear-Lichtschalterbroschuere.pdf" />
              </Grid>
            </Grid>
          ),
        },
        {
          type: "dialog",
          img: "preisliste",
          children: <Preisliste anchor="busch-art-linear" />,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                Busch-art linear <QChip date="2023-08-31" label="ab September" />
              </>
            }
          />
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <QList
              items={[
                "Zeitloses Design",
                "Nachhaltige Materialien",
                "Nachhaltige Produktion",
                "Klare Konturen",
                "Perfekte Oberflächen die über den Rahmen schweben",
                "Vielzahl von Materialien",
              ]}
              dense
            />

            <div className={classes.buttonGroup}>
              <Button onClick={openDialog} variant="contained" color="primary">
                Design
              </Button>
              <Button onClick={open3DModel} variant="contained" color="primary">
                Nachhaltigkeit
              </Button>
              <Button
                onClick={openSmartScreen}
                variant="contained"
                color="primary"
              >
                Smart
              </Button>
            </div>
            <Box marginTop="10vh">
              <ImageComponent
                col={4}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: Studioweiss,
                    alt: "Busch-art linear | studioweiß",
                  },
                  {
                    src: StudioweissMatt,
                    alt: "Busch-art linear | studioweiß matt",
                  },
                  {
                    src: SchwarzMatt,
                    alt: "Busch-art linear | schwarz matt",
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              position="absolute"
              zIndex={200}
              width="45%"
              height="50%"
              className={classes.jungleBackground}
            >
              <Box onClick={openModel}>
                <img
                  src={BuschArtLinearWhiteImage}
                  style={{
                    width: "80%",
                    marginTop: "15%",
                    marginLeft: "10%",
                    maxWidth: "500px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
