import { h } from "preact";
import { Box, Grid } from "@material-ui/core";
import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/1.jpg";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import CenteredImage from "../../../../../../components/CenteredImage";
import ImageComponent from "../../../../../../components/ImageComponent";

import oceanWhite from "../../../../../../assets/cwa/SchaltenUndSteuern/Produkte/ocean/ocean-ip-44-white.png";
import oceanWhiteBlue from "../../../../../../assets/cwa/SchaltenUndSteuern/Produkte/ocean/ocean-ip-44-white-blue.png";

export default function Ocean() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/ocean",
          target: "_blank",
        },
      ]}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndSteuern/Produkte/ocean/ocean-ip-44-white.png"
        alt={
          <>
            Allwetter 44<sup>®</sup> weiß
          </>
        }
        height="70vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                ocean<sup>®</sup> (IP 44)
              </>
            }
            subTitle="Design trifft Funktion"
            arrow
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QList
                headline="Vorteile"
                items={[
                  "Großer Anschlussraum",
                  "Große Sortimentstiefe",
                  "Beschriftbar / Beleuchtbar",
                  "Montagefreundlich",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/ocean/weiss.jpg",
                    alt: (
                      <>
                        ocean<sup>®</sup> (IP 44) weiß
                      </>
                    ),
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/ocean/blaugruen-grau.jpg",
                    alt: (
                      <>
                        ocean<sup>®</sup> (IP 44) blaugrün/grau
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
