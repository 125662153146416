import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../../components/CenteredImage";
import ImageComponent from "../../../../../../../components/ImageComponent";

export default function Sicherheit1() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/sicherheit-1.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "pdf",
          img: "preisbeispiel",
          path: "../../../../../../../assets/pdf/preisbeispiele/Smarter-Home/Preisbeispiel Smarter Home - Sicherheit.pdf",
        },
      ]}
    >
      <CenteredImage
        src="../../../../../../../assets/tuerkommunikation/produkte/sicherheit-center.png"
        alt="Jalousiesteuerung"
        height="25vw"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Sicherheit
            </QBodyText>
            <QBodyText>
              Ein Brand kommt immer überraschend. Und oft in der Nacht. Da ist
              es ein gutes Gefühl zu wissen, ein Busch-Rauchalarm® wacht über
              einen.
            </QBodyText>
            <Box style={{ paddingRight: "12vw" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Öffnen von Fluchtwegen im Brandfall",
                  "Beleuchtung in Fluchtwegen aktivieren",
                  "Benachrichtigung auf einem Panel oder auf dem Handy (z. B. Angehörige)",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Rauchmelder",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/rauchmelder-left.jpg",
                  },
                  {
                    alt: "Rauchmelder",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/rauchmelder-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
