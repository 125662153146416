import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import Montage from "../../../../../../_globalSlides/Montage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/22.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/bluetooth-smart-switch/bluetooth-smart-switch.png";

export default function FriendsOfHueOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/friends-of-hue",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "info",
          children: <Montage />,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Friends of Hue" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Friends of Hue smart switch. Damit wird die Steuerung Ihrer
                Philips Hue-Leuchtmittel zum stimmungsvollen Vergnügen. Und das
                von jeder beliebigen Stelle im Raum aus. Frei und ungebunden.
                Ganz einfach drahtlos ohne Batterien – per smarter
                Funktechnologie.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Belegung der Wippe mit bis zu vier Funktionen",
                  'Batterielos und wartungsfrei durch "Energy Harvesting"',
                  "Eignet sich ideal für die Nachrüstung",
                  "Inbetriebnahme per App",
                  "Maximale Funktionssicherheit durch Mesh-Technologie",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="Dreh-/Tastdimmer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
