import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../../components/ChapterCard";

//Overlays
import TimerJalousiesteuerungOverlay from "./slides/Timer-Jalousiestauerung";
import RadioiNetWeckerOverlay from "./slides/Radio-iNet-Wecker";
import RauchwarnmelderOverlay from "./slides/Rauchwarnmelder";
import LuftguetesensorOverlay from "./slides/Luftgütesensor";
import DrehTastdimmerOverlay from "./slides/Dreh-Tastdimmer";
import SchukoSteckdoseSteplightOverlay from "./slides/Schuko-Steckdose-Steplight";

// Images
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/29.jpg";
import TimerJalousiesteuerung from "../../../../../../../../assets/renovation-und-neubau/raume/schlafen/timer-jalousie-steuerung.jpg";
import RadioiNetWecker from "../../../../../../../../assets/renovation-und-neubau/raume/schlafen/radio-inet-wecker.jpg";
import Rauchwarnmelder from "../../../../../../../../assets/renovation-und-neubau/raume/schlafen/rauchwarnmelder.jpg";
import Luftgütesensor from "../../../../../../../../assets/renovation-und-neubau/raume/schlafen/luftguetesensor.jpg";
import DrehTastdimmer from "../../../../../../../../assets/renovation-und-neubau/raume/schlafen/dreh-tastimmer.jpg";
import SCHUKOSteckdoseSteplight from "../../../../../../../../assets/renovation-und-neubau/raume/schlafen/schuko-steckdose-steplight.jpg";

const items = [
  {
    title: "Timer / Jalousiesteuerung",
    img: TimerJalousiesteuerung,
    menuOverlay: <TimerJalousiesteuerungOverlay />,
  },
  {
    title: "Radio iNet / Wecker",
    img: RadioiNetWecker,
    menuOverlay: <RadioiNetWeckerOverlay />,
  },
  {
    title: "Rauchwarnmelder",
    img: Rauchwarnmelder,
    menuOverlay: <RauchwarnmelderOverlay />,
  },
  {
    title: "Luftgütesensor",
    img: Luftgütesensor,
    menuOverlay: <LuftguetesensorOverlay />,
  },
  {
    title: "Dreh- / Tastdimmer",
    img: DrehTastdimmer,
    menuOverlay: <DrehTastdimmerOverlay />,
  },
  {
    title: "SCHUKO Steckdose / Steplight",
    img: SCHUKOSteckdoseSteplight,
    menuOverlay: <SchukoSteckdoseSteplightOverlay />,
  },
];

export default function Schlafen() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        width: 0.45,
        backgroundPosition: "left"
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Schlafen" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Unser privater Raum, wo der Tag beginnt und endet, ein Ort, der
                besondere Pflege braucht. Licht und Ton auf Anfrage, thermischer
                Komfort und Sicherheit.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
