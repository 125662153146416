import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { DatePicker } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getActiveModules } from "../../../redux/modules/backendState";
import { getConsultantData } from "../../../redux/modules/consultant";
import { getCustomerData } from "../../../redux/modules/customer";
import {
  getConsultingData,
  setConsultingRunning,
  setNextAppointment,
  setNextAppointmentPlace,
} from "../../../redux/modules/consulting";
import { Offline, Online } from "react-detect-offline";
import * as B2CSDK from "@qmarketing/b2c-js-sdk";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import Link from "@material-ui/core/Link";
import Checkicon from "./checkicon";
import { resetConsulting } from "../../../redux/modules/reducers";

const optionsDate = { weekday: "long", month: "long", day: "numeric" };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  setupPage: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
    // height: '550px'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  paper: {
    margin: "auto",
  },
  paperElements: {
    margin: "0 auto",
    width: "100%",
    height: "100%",
  },
  cardImg: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "25px",
    justifyContent: "center",
  },
  buttonNext: {
    textAlign: "right",
  },
  endConsultation: {
    textAlign: "center",
  },
  paperStepHeight: {
    // paddingTop: '25px',
    height: "265px",
  },
  sendData: {
    display: "flex !important",
    margin: "0 auto",
    width: "max-content",
  },
  buttonS: {
    "&::before": {
      content: "none !important",
    },
  },
  lastStep: {
    background: "none",
    boxShadow: "none",
  },
  instructions: {
    color: "#000000",
    fontWeight: "bold",
  },
  centerContent: {
    textAlign: "center",
  },
}));

export default function ResultPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const consulting = useSelector((state) => getConsultingData(state));
  const consultant = useSelector((state) => getConsultantData(state));
  const customer = useSelector((state) => getCustomerData(state));
  const fieldValues = useSelector((state) => state.fieldValues.fieldValues);
  const activeModules = useSelector((state) => getActiveModules(state));

  const [checkedModules, setCheckedModules] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [uuid, setUUID] = useState("bje");
  const [emailLink, setEmailLink] = useState("bje");

  useEffect(() => {
    activeModules.forEach((module) => {
      let found = false;

      fieldValues.forEach((field) => {
        if (module.fields.filter((mF) => mF.id === field.id).length > 0) {
          found = true;
        }
      });

      setCheckedModules((prevState) => ({
        ...prevState,
        [module.chapter.key]: found,
      }));
    });
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCheckboxes = (e) => {
    setCheckedModules({
      ...checkedModules,
      [e.target.id]: !checkedModules[e.target.id],
    });
  };

  const handleDate = (v) => {
    dispatch(setNextAppointment(v));
  };

  const handleField = (e) => {
    dispatch(setNextAppointmentPlace(e.currentTarget.value));
  };

  const setEmail = (id) => {
    const breakLine = "\r\n";
    const mailTopic = `Busch-eDialog | Unser Gespräch am ${new Date(
      consulting.appointment
    ).toLocaleDateString("de-DE", optionsDate)}`;

    const emailHeader = `${
      customer.salutation == "Frau" ? `Guten Tag Frau ` : `Guten Tag Herr `
    }${customer.lastname},${breakLine}${breakLine}`;
    const emailBody = `die Inhalte unseres Gesprächs können Sie sich noch einmal hier ansehen:${breakLine}${breakLine}`;
    const emailResultMessage = `Busch-eDialog: ${process.env.PREACT_APP_B2CMICROSITE}${id}${breakLine}${breakLine}`;
    const emailPrivacyUrl = `Unsere Hinweise zum Datenschutz finden Sie hier: https://new.abb.com/privacy-notice/de/customer/germany${breakLine}${breakLine}`;
    const emailFooter = `Mit freundlichen Grüßen${breakLine}${breakLine}${consultant.firstname} ${consultant.lastname}${breakLine}${breakLine}`;

    const mailBody = `${emailHeader}${emailBody}${emailResultMessage}${emailPrivacyUrl}${emailFooter}`;

    setEmailLink(
      `${
        `mailto:${customer.email}` + "?subject="
      }${mailTopic}&body=${encodeURIComponent(mailBody)}`
    );
  };

  const shareDialog = () => {
    const shareData = {
      title: "Busch-eDialog",
      text: "",
      url: `${process.env.PREACT_APP_B2CMICROSITE}${uuid}`,
    };

    try {
      navigator.share(shareData);
    } catch (err) {}
  };

  const sendData = (e) => {
    let apiInstance = new B2CSDK.ConsultationApi();
    let consultation = new B2CSDK.Consultation(); // Consultation | Consultation
    let chapterRatings = [];

    // TODO Speicherung des Logo in der DB
    consultation.consultant = {
      ...consultant,
      company: {
        ...consultant.company,
        logo: "",
      },
    };
    consultation.customer = customer;
    consultation.consulting = {
      appointment: consulting.appointment,
      appointment_place: consulting.appointmentPlace,
      next_appointment: consulting.nextAppointment,
      next_appointment_place: consulting.nextAppointmentPlace,
      notes: consulting.notes,
      rating: 0,
      topic: consulting.topic,
    };
    consultation.fieldValues = fieldValues;
    consultation.miscdata = "{}";

    activeModules.forEach((module) => {
      chapterRatings.push({
        chapter_name: module.chapter.key,
        rating: 0,
      });
    });
    consultation.chapterRatings = chapterRatings;
    apiInstance.apiConsultationPost(consultation).then(
      (data) => {
        setUUID(data.id);
        setEmail(data.id);
        setActiveStep(3);
        dispatch(setConsultingRunning(false));
        dispatch(resetConsulting());
      },
      (error) => {
        setActiveStep(0);
        toast.error("Ein Fehler ist aufgetreten.");
      }
    );
  };

  const getSteps = () => {
    return ["Themen", "Nächster Termin", "Abschluss"];
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return `Besprochene Themen am ${new Date(
          consulting.appointment
        ).toLocaleDateString("de-DE", optionsDate)}:`;
      case 1:
        return "Wann sehen wir uns wieder?";
      case 2:
        return "";
      default:
        return "";
    }
  };

  const steps = getSteps();

  if (activeStep === 2) {
    if (!navigator.onLine) {
      toast.error(
        "Keine Interverbindung.\nDie Beratung kann beendet werden, wenn Sie online sind."
      );
    }
  }

  return (
    <Paper
      className={`${classes.setupPage} mb-3 ${
        activeStep === steps.length ? classes.lastStep : ""
      }`}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Grid container className={classes.root} spacing={2}>
              <Grid key="information" item xs={6}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  spacing={2}
                >
                  <Grid item xs>
                    <Checkicon />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h5"
                      component="h2"
                      className={`text-center`}
                    >
                      Vielen Dank!
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={`text-center`}
                    >
                      Beratung erfolgreich beendet.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid key="share" item xs={6} spacing={2}>
                <Paper className={classes.paper}>
                  <div className={classes.paperElements}>
                    <Card className={classes.root}>
                      <CardActionArea>
                        <div className={classes.cardImg}>
                          <Link
                            href={`${process.env.PREACT_APP_B2CMICROSITE}${uuid}`}
                            target="_blank"
                          >
                            <QRCode
                              height={250}
                              widht={250}
                              value={`${process.env.PREACT_APP_B2CMICROSITE}${uuid}`}
                            />
                          </Link>
                        </div>
                        <CardContent className={`pt-2 pb-0`}>
                          <Typography
                            variant="h5"
                            component="h2"
                            className={`text-center`}
                          >
                            Busch-eDialog
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            className={`text-center`}
                          >
                            Digitale Zusammenfassung des Gesprächs
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Grid
                          container
                          alignItems="center"
                          direction="column"
                          spacing={0}
                        >
                          <Grid item xs>
                            <Box py={1}>
                              <Button
                                href={emailLink}
                                target="_blank"
                                variant="contained"
                                size="small"
                                color="primary"
                              >
                                Per E-Mail versenden
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs>
                            <Button
                              onClick={shareDialog}
                              onTouchStart={shareDialog}
                              size="small"
                              color="primary"
                            >
                              Teilen
                            </Button>
                            <Button
                              href={`${process.env.PREACT_APP_B2CMICROSITE}${uuid}`}
                              target="_blank"
                              size="small"
                              color="primary"
                            >
                              Öffnen
                            </Button>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            {/*                         <Button variant="contained" color="primary" onClick={this.handleReset} className={`${classes.button} ${classes}`}>
						Neue Beratung Starten
					</Button> */}
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>

            {activeStep === 0 && (
              <Box>
                <Grid container spacing={4} xs={12}>
                  {checkedModules !== null &&
                    activeModules.map((module) => (
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onChange={handleCheckboxes}
                              checked={checkedModules[module.chapter.key]}
                              value={checkedModules[module.chapter.key]}
                              id={module.chapter.key}
                            />
                          }
                          label={module.chapter.name}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}

            {activeStep === 1 && (
              <div>
                {
                  <div className={classes.formControl}>
                    <Grid container spacing={4} item>
                      <Grid item xs={4}>
                        <DatePicker
                          label="Datum"
                          id="nextAppointment"
                          value={consulting.nextAppointment}
                          onChange={handleDate}
                          cancelLabel="Abbrechen"
                          format="dd.MM.yyyy"
                          animateYearScrolling
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          id="outlined-basic"
                          onChange={handleField}
                          id="nextAppointmentPlace"
                          value={consulting.nextAppointmentPlace}
                          fullWidth
                          label="Ort"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
              </div>
            )}

            {activeStep === 2 && (
              <div className={classes.centerContent}>
                <img
                  src="../../../assets/shared/coffee-empty.png"
                  width="250"
                  height="250"
                />
                <div className={`pl-3`}>
                  <Typography variant="h5" component="h2" className={``}>
                    Fast fertig!
                  </Typography>
                  <Typography
                    variant="p"
                    color="textSecondary"
                    component="p"
                    className={``}
                  >
                    Alles erfasst? Nichts vergessen?
                  </Typography>
                </div>
              </div>
            )}

            <div>
              <Grid
                container
                spacing={3}
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={4}>
                  {activeStep != 0 && (
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={`${classes.button} ${classes.buttonS}`}
                    >
                      Zurück
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4} className={classes.endConsultation}>
                  {activeStep === steps.length - 1 && (
                    <div>
                      <Online
                        polling={{
                          url: `${process.env.PREACT_APP_B2CAPI}/ping`,
                          interval: 60000,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={sendData}
                          disabled={!navigator.onLine}
                        >
                          Beratung beenden
                        </Button>
                      </Online>
                      <Offline
                        polling={{
                          url: `${process.env.PREACT_APP_B2CAPI}/ping`,
                          interval: 60000,
                        }}
                      >
                        <Button variant="contained" color="primary" disabled>
                          Beratung beenden
                        </Button>
                      </Offline>
                    </div>
                  )}
                </Grid>
                <Grid item xs={4} className={classes.buttonNext}>
                  {activeStep !== steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Weiter
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
}
