import { h } from "preact";
import ChapterWrapper from "../../../components/ChapterWrapper";
import Loesungen from "./sections/Loesungen";
import Sicherheit from "./sections/Sicherheit";

export default function DoorCommunication() {
  return (
    <ChapterWrapper chapterKey="doorcommunication">
      <Sicherheit anchor="sicherheit" />
      <Loesungen anchor="loesungen" />
    </ChapterWrapper>
  );
}
