import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../components/QList";
import QHeader from "../../../../../components/QHeader";
import Slide from "../../../../../components/Slide/index";
import QBodyText from "../../../../../components/QBodyText";
import ImageComponent from "../../../../../components/ImageComponent";
import CenteredImage from "../../../../../components/CenteredImage";

export default function Sicherheit() {
  return (
    <Slide
      background={{
        src: "../../../../../assets/smarterhome/background/sicherheit.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../assets/tuerkommunikation/smartphone/sicherheit.png"
        alt="Busch-Welcome App"
        height="50vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6} style={{ paddingRight: "120px" }}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Ein ganzheitliches Konzept
            </QBodyText>
            <QBodyText>
              Mit der breiten Palette durchdachter Produkte kann für jede Art
              von Gebäuden die optimale Türkommunikation verwirklicht werden.
            </QBodyText>
            <QList
              items={[
                "Hochwertige und sorgfältig aufeinander abgestimmte Materialien",
                "Für Renovation und Neubau",
                "Vielfältige Indoor- und Outdoor-Lösungen wie Überwachung, Türkommunikation etc.",
                "Steuerung via Panel, Smartphone, Tablet",
                "Kombinierbar mit anderen Systemen wie free@home, Busch-AccessControl, KNX",
              ]}
              dense
              variant="arrow"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
