import { h } from "preact";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { sectionStart } from "../../helpers/fullpageHelpers";
import clsx from "clsx";
import FilledArrowLeft from "../SvgIcons/FilledArrowLeft";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Averta-Bold",
  },
  subTitle: {
    fontFamily: "Averta-Light",
  },
  arrowWrap: {
    position: "absolute",
    left: "8px",
    marginTop: "3px",
    top: -42,
    cursor: "pointer",
  },
  largeTitle: {
    fontSize: "48px",
  },
  largeSubTitle: {
    fontSize: "22px",
  },
  smallTitle: {
    fontSize: "20px",
  },
  smallSubTitle: {
    fontSize: "15px",
  },
}));

export default function QHeader({
  title,
  subTitle,
  arrow,
  arrowOverride,
  size,
  ...props
}) {
  const classes = useStyles();

  let titleSize;
  let subTitleSize;
  switch (size) {
    case "large":
      {
        titleSize = classes.largeTitle;
        subTitleSize = classes.largeSubTitle;
      }
      break;
    case "small":
      {
        titleSize = classes.smallTitle;
        subTitleSize = classes.smallSubTitle;
      }
      break;
    default: {
      titleSize = classes.largeTitle;
      subTitleSize = classes.largeSubTitle;
    }
  }

  return (
    <Grid container direction="column">
      <Grid item xs={9}>
        <Typography
          variant="h3"
          className={clsx(classes.title, titleSize)}
          gutterBottom={!subTitle}
          {...props}
          onClick={sectionStart}
        >
          {arrow && (
            <div
              id="qBackArrow"
              className={classes.arrowWrap}
              onClick={arrowOverride === null ? sectionStart : arrowOverride}
            >
              <FilledArrowLeft color="primary" fontSize="large" />
            </div>
          )}
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h5"
          className={clsx(classes.subTitle, subTitleSize)}
          gutterBottom
          {...props}
          onClick={sectionStart}
        >
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
}

QHeader.defaultProps = {
  arrow: false,
  arrowOverride: null,
  size: "large",
};
