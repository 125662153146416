import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../../components/ChapterCard";

// Overlays
import BuschWelcomeInnenstationVideo from "./slides/BuschWelcomeInnenstationVideo";
import BuschSteplightOverlay from "./slides/BuschSteplight";
import BluetoothSmartSwitchOverlay from "./slides/BluetoothSmartSwitch";
import BundesligaFanschalterOverlay from "./slides/BundesligaFanschalter";
import MultimediaDatenanschlussOverlay from "./slides/MultimediaDatenanschluss";
import LEDDimmerFlexOverlay from "./slides/LEDDImmerFlex";
import RauchwarnmelderOverlay from "./slides/Rauchwarnmelder";
import SchukoUSBSteckdoseOverlay from "./slides/SchukoUSBSteckdose";
import SchukoSteckdoseSafetyPlusOverlay from "./slides/SchukoSteckdoseSafetyPlus";

// Background
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/38.jpg";

// Images
import SteplightImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/schuko-steckdose-steplight.jpg";
import RauchwarnmelderImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/rauchwarnmelder.jpg";
import LEDDimmerFlexImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/led-dimmer-flex.jpg";
import EDVTelefonImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/edv-telefon.jpg";
import BluetoothSmartSwitchImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bluetooth-smart-switch.jpg";
import FanSchalterImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/fan-schalter.jpg";
import InnenstationVideo5ZollImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/innenstation-video-5-zoll.jpg";
import SchukoSteckdoseSafetyPlusImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/schuko-steckdose-safety-plus.jpg";
import SchukoSteckdoseUSBImg from "../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/schuko-usb-steckdose-usb.jpg";

const items = [
  {
    title: 'Innenstation Video 5"',
    img: InnenstationVideo5ZollImg,
    menuOverlay: <BuschWelcomeInnenstationVideo />,
  },
  {
    title: "Steplight",
    img: SteplightImg,
    menuOverlay: <BuschSteplightOverlay />,
  },
  {
    title: "SCHUKO-Steckdose Safety+",
    img: SchukoSteckdoseSafetyPlusImg,
    menuOverlay: <SchukoSteckdoseSafetyPlusOverlay />,
  },
  {
    title: "SCHUKO-Steckdose/USB",
    img: SchukoSteckdoseUSBImg,
    menuOverlay: <SchukoUSBSteckdoseOverlay />,
  },
  { placeholder: true },
  { placeholder: true },
  {
    title: "Rauchwarnmelder",
    img: RauchwarnmelderImg,
    menuOverlay: <RauchwarnmelderOverlay />,
  },
  {
    title: "LED-Dimmer flex",
    img: LEDDimmerFlexImg,
    menuOverlay: <LEDDimmerFlexOverlay />,
  },
  {
    title: "EDV/Telefon",
    img: EDVTelefonImg,
    menuOverlay: <MultimediaDatenanschlussOverlay />,
  },
  {
    title: "Fan-Schalter",
    img: FanSchalterImg,
    menuOverlay: <BundesligaFanschalterOverlay />,
  },
  {
    title: "Bluetooth Smart Switch",
    img: BluetoothSmartSwitchImg,
    menuOverlay: <BluetoothSmartSwitchOverlay />,
  },
];

export default function Kind() {
  return (
    <Slide background={{ src: BackgroundImg, width: 0.45 }}>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Kinderzimmer" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Ein Raum für diejenigen, die uns am meisten am Herzen liegen.
                Unsere Kinder. Sie brauchen genügend Wohnraum, um ihnen
                Spielraum zu geben, zu lernen und friedlich zu schlafen. Kein
                Raum im Haus wird so oft umgenutzt wie das Kinderzimmer: erst
                Kind-, dann Jugend- und später Hobbyzimmer oder Büro.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {items.map((e, i) => {
              return (
                <Grid item xs={2}>
                  <ChapterCard chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
