import { h } from "preact";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  typography: {
    fontSize: (props) => props.fontSize,
    marginLeft: (props) => props.marginLeft,
    marginRight: (props) => props.marginRight,
    marginTop: (props) => props.marginTop,
    marginBottom: (props) => props.marginBottom,
    fontWeight: (props) => props.fontWeight,
  },
});

export default function QBodyText({
  children,
  fontSize,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  fontWeight,
}) {
  const classes = useStyles({
    fontSize,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    fontWeight,
  });
  return (
    <Typography className={classes.typography} paragraph>
      {children}
    </Typography>
  );
}

QBodyText.defaultProps = {
  children: "",
  fontSize: "clamp(1.1rem, 1.5vw, 1.5rem)",
  marginTop: "0px",
  marginBottom: "16px",
};
