import { Button, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../../../../components/ImageComponent";

// Images
import SelektiveErfassungImg from "../../../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder-select/selektive-erfassung.png";

export default function SelektiveErfassung() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Selektive Erfassung" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Bewegungsmelder mit selektiver Linse erfassen die Bewegung
                scheibenartig (übliche Montagehöhe 1,1m). Haustiere die sich
                unterhalb der Erfassung aufhalten schalten das Licht nicht ein.
                Für die Erfassung in Treppenaufgängen ist diese linse eher
                ungeeignet
              </QBodyText>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperWidth="100%"
                src={SelektiveErfassungImg}
                alt="Darstellung selektiver Erfassung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
