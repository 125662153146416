import { h } from "preact";
import { Grid } from "@material-ui/core";
import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/schalter/decento.jpg";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import CenteredImage from "../../../../../../components/CenteredImage";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=35&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=1b2780acebc6d6e9f8ae9ce18de67c81",
    target: "_blank",
  },
  {
    type: "dialog",
    img: "preisliste",
    children: <Preisliste anchor="decento" />,
  },
];

export default function Decento() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/decento.png"
        alt="Decento"
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Lichtschalter" subTitle="Decento" />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QList
                items={[
                  "Aus Liebe zum Echten",
                  <>
                    Das Porzellanschalterprogramm Decento
                    <sup>®</sup> verkörpert Handwerk Qualität und somit
                    Authentizität
                  </>,
                  "Ideal für den Altbau",
                  "Nostalgischer Charme, Authentische Ästhetik",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/decento/decento-lichtschalter.png",
                    alt: "Decento Lichtschalter",
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/decento/decento-schuko.png",
                    alt: "Decento Schuko",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
