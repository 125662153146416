import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";

export default function Sprachsteuerung() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/sprachsteuerung.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Sprachsteuerung
            </QBodyText>
            <QBodyText>
              Einfache und intuitive Sprachsteuerung für Jung und Alt, die auf
              manuelle Bedienung verzichten kann und somit einen sorglosen
              Umgang auch für ältere Generationen garantiert.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Einfache und intuitive Bedienung",
                "Keine manuelle Bedienung notwendig – z. B. bei Handicap oder schlecht zugänglichen Schaltstellen",
              ]}
              dense
              variant="arrow"
            />
            <Box display="flex">
              <ImageComponent
                previewImgWidth="15vw"
                alt="Amazon Alexa"
                src="../../../../../../../assets/tuerkommunikation/produkte/sprachsteuerung-left.jpg"
              />
              <ImageComponent
                previewImgWidth="15vw"
                alt="Google HomePod"
                src="../../../../../../../assets/tuerkommunikation/produkte/sprachsteuerung-right.jpg"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
