import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/15.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-steplight/busch-steplight.png";

export default function SchukoSteckdoseOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-steplight",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-steplight®" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Zwei in einem: Busch-steplight® ist ein SCHUKO®
                Steckdosen-Einsatz, der über ein integriertes Orientierungslicht
                verfügt. Die LEDs ermöglichen in Räumen, Fluren oder
                Treppenhäusern eine gute Orientierung bei Dunkelheit.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Steckdose mit Orientierungslicht",
                  "Lichtkegel im Dunkeln gibt Orientierung",
                  "Ideal für Krankenhäuser",
                  "Kein Verlegungs- und Montageaufwand für zusätzliches Licht notwendig",
                  "Abschaltbares Orientierungslicht",
                  "Sicherheit im Dunkeln",
                  "Effiziente LED-Technik",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Bewegungsmelder Select"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
