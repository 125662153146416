import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";

// Overlays
import USBLadegeraetOverlay from "./slides/USBLadegerät";
import BuschProtectorOverlay from "./slides/BuschProtector";
import SchukoSteckdoseSafetyPlusOverlay from "./slides/SchukoSteckdoseMitBeschriftung";
import MultimediaDatenanschlussOveray from "./slides/MultimediaDatenanschlussOverlay";
import Kontrollschalter16AOverlay from "./slides/Kontrollschalter16A";
import TischstaenderOverlay from "./slides/Tischständer";

// Images
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/43.jpg";
import BuschFreeAtHomeFlexJalousie from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/busch-free-at-home-flex-jalousie.jpg";
import EDVTelefon from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/edv-telefon.jpg";
import HDMIVGAUSB from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/hdmi-vga-usb.jpg";
import Kontrollschalter from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/kontrollschalter.jpg";
import Lautsprecherdose from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/lautsprecherdose.jpg";
import Netzwerktechnik from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/netzwerktechnik.jpg";
import SchukoSteckdoseMitKennzeichnung from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/schuko-steckdose-mit-kennzeichnung.jpg";
import Tischstaender from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/tischstaender.jpg";
import Ueberspannungsschutz from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/ueberspannungsschutz.jpg";
import USBLaden from "../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/usb-laden.jpg";

// Placeholder
import PlaceholderImg from "../../../../../../../../assets/renovation-und-neubau/placeholder.jpg";
import ChapterCard from "../../../../../../../../components/ChapterCard";
import BuschFreeAtHomeFlexJalousieOverlay from "./slides/BuschFreeAtHomeFlexJalousie";

const items = [
  {
    title: "USB-Laden",
    img: USBLaden,
    menuOverlay: <USBLadegeraetOverlay />,
  },
  {
    title: (
      <>
        Überspannungs-
        <br />
        schutz
      </>
    ),
    img: Ueberspannungsschutz,
    menuOverlay: <BuschProtectorOverlay />,
  },
  {
    title: "SCHUKO-Steckose mit Beschriftung",
    img: SchukoSteckdoseMitKennzeichnung,
    menuOverlay: <SchukoSteckdoseSafetyPlusOverlay />,
  },
  {
    title: "Kontrollschalter 16A",
    img: Kontrollschalter,
    menuOverlay: <Kontrollschalter16AOverlay />,
  },
  { placeholder: true },
  { placeholder: true },
  {
    title: "Tischständer",
    img: Tischstaender,
    menuOverlay: <TischstaenderOverlay />,
  },
  {
    title: "Netzwerktechnik",
    img: Netzwerktechnik,
    menuOverlay: <MultimediaDatenanschlussOveray />,
  },
  {
    title: "HDMI, VGA, USB",
    img: HDMIVGAUSB,
    menuOverlay: <MultimediaDatenanschlussOveray />,
  },
  {
    title: "Lautsprecherdose",
    img: Lautsprecherdose,
    menuOverlay: <MultimediaDatenanschlussOveray />,
  },
  {
    title: "EDV / Telefon",
    img: EDVTelefon,
    menuOverlay: <MultimediaDatenanschlussOveray />,
  },
  {
    title: (
      <>
        Busch-free@home<sup>®</sup> flex, Jalousie
      </>
    ),
    img: PlaceholderImg,
    menuOverlay: <BuschFreeAtHomeFlexJalousieOverlay />,
  },
];

export default function Arbeitszimmer() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        width: 0.45,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Arbeitszimmer" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Ein modernes, ruhiges Heimbüro für die Arbeit oder einfach ein
                Ort zum Entspannen und Nachdenken. Gleichzeitig der Raum mit den
                höchsten Anforderungen an Kommunikation, Technik und
                Flexibilität.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {items.map((e, i) => {
              return (
                <Grid item xs={2}>
                  <ChapterCard chapter={e} cardWidth="auto" />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
