import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Outdoor1() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/smarterhome/background/outdoor-1.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/smarterhome/produkte/outdoor-1.jpg"
        alt="Busch-Welcome® Außenstation Video"
        height="45vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6} style={{ paddingRight: "10rem" }}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Außenstation
            </QBodyText>
            <QBodyText>
              Ob mit Video oder Audio. Auf- oder Unterputz. Dank der großen
              Vielfalt und Modularität bietet Busch-Welcome® für nahezu jeden
              die ideale Lösung an.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Einfache Installation dank 2-Draht-Bus-Technik",
                "Hochwertige Materialien, zeitloses Design",
                "Modulare Bauweise",
                "Briefkasten-Integration",
                "Schutz vor unberechtigtem Zugriff",
              ]}
              dense
              variant="arrow"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
