import Sicherheit1 from "./slides/Sicherheit-1";
import Haushaltsgeraete from "./slides/Haushaltsgeraete";
import Heizungssteuerung from "./slides/Heizungssteuerung";
import Jalousiesteuerung from "./slides/Jalousiesteuerung";
import Beleuchtungssteuerung from "./slides/Beleuchtungssteuerung";
import Mediensteuerung from "./slides/Mediensteuerung";
import Tuerkommunikation from "./slides/Tuerkommunikation";
import Uebersicht from "./slides/Uebersicht";

export default function DasSystem() {
  return [
    <Uebersicht anchor="uebersicht" />,
    <Sicherheit1 anchor="sicherheit-1" />,
    <Heizungssteuerung anchor="heizungssteuerung" />,
    <Beleuchtungssteuerung anchor="beleuchtungssteuerung" />,
    <Jalousiesteuerung anchor="Jalousiesteuerung" />,
    <Tuerkommunikation anchor="tuerkommunikation" />,
    <Mediensteuerung anchor="mediensteuerung" />,
    <Haushaltsgeraete anchor="haushaltsgeraete" />,
  ];
}
