import { Box, Grid } from "@material-ui/core";

import backgroundLichtschalter from "../../../../../../assets/cwa/background/titelbilder/schalter/future-linear.jpg";

import QHeader from "../../../../../../components/QHeader";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import CenteredImage from "../../../../../../components/CenteredImage";
import QBodyText from "../../../../../../components/QBodyText";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

export default function FutureLiinear() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/busch-balance-sihttps://www.busch-jaeger.de/produkte/schalterdesign/future-linear",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "preisliste",
          children: <Preisliste anchor="future-linear" />,
        },
      ]}
      background={{
        src: backgroundLichtschalter,
      }}
    >
      <CenteredImage
        images={[
          {
            src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/future-linear/1.jpg",
            alt: (
              <>
                future<sup>®</sup> linear studioweiß
              </>
            ),
          },
          {
            src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/future-linear/2.jpg",
            alt: (
              <>
                future<sup>®</sup> linear schwarz matt
              </>
            ),
          },
          {
            src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/future-linear/5.jpg",
            alt: (
              <>
                future<sup>®</sup> linear studioweiß matt
              </>
            ),
          },
          {
            src: "../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/future-linear/6.jpg",
            alt: (
              <>
                future<sup>®</sup> linear anthrazit
              </>
            ),
          },
        ]}
        width="30vw"
        col={6}
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                future<sup>®</sup> linear
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QBodyText>Setzt farbige axcente</QBodyText>
              <QList
                title="Vorteile"
                items={[
                  "Echtglas Rahmen",
                  "Rückseitig bedruckt für brilliantere Farben",
                  "Satin matt",
                  "Erhältlich in 1-fach bis 5-fach",
                  "Keine Flatmontage möglich",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/future-linear/alusilber.jpg",
                    alt: (
                      <>
                        future<sup>®</sup> linear Lichtschalter alusilber
                      </>
                    ),
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/future-linear/schuko-anthrazit.jpg",
                    alt: (
                      <>
                        future<sup>®</sup> linear Schuko anthrazit
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
