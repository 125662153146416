import { h, Component } from "preact";
import { Router } from "preact-router";

// Code-splitting is automated for routes
import Chapters from "./routes/Chapters";
import DoorCommunication from "./routes/Chapters/doorcommunication";
import SmarterHome from "./routes/Chapters/smarterhome";
import SwitchControl from "./routes/Chapters/switchcontrol";

//Components
import Menu from "./components/Menu";

//Redux
import configureStore from "./redux/store/configureStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

//Material UI
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import ModalProvider from "mui-modal-provider";

//Material UI Picker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

//StylesProvider for overriding MaterialUI
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

import CustomerView from "./routes/CustomerView";

//Toastify
import { ToastContainer, toast } from "react-toastify";

//tippy.js
import "tippy.js/dist/tippy.css";

//Sentry https://docs.sentry.io/platforms/javascript/react/
// import * as Sentry from '@sentry/react';

//Fullpage ScrollOverflow
import "fullpage.js/vendors/scrolloverflow";

import root from "window-or-global";
//import PrivateRoute from "./components/PrivateRoute";

import APIService from "./api/index";
import AppWrap from "./appWrap";

import LoadingScreen from "./components/LoadingScreen";
import ModerneElektroinstallation from "./routes/Chapters/moderneElektroinstallation";

const generateClassName = createGenerateClassName({
  productionPrefix: "qmarketing",
});

export default class App extends Component {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = (e) => {
    this.currentUrl = e.url;
  };

  handleConnection(event) {
    if (event.type === "offline") {
      toast.error("Keine Internetverbindung.");
    }
    if (event.type === "online") {
      toast.info("Sie sind wieder online.");
    }
  }

  async componentDidMount() {
    console.log(process.env.PREACT_APP_B2CAPI)
    if (process.env.NODE_ENV !== "development") {
      await APIService.get("api/pb/hit");
    }

    root.addEventListener("online", this.handleConnection);
    root.addEventListener("offline", this.handleConnection);
  }


  render() {
    const { store, persistor } = configureStore({});

    return (
      <div id="app">
        <ToastContainer
          position="bottom-center"
          toastClassName="toast-container"
          autoClose={3000}
          hideProgressBar
        />
        <Provider store={store}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <StylesProvider
              injectFirst={false}
              generateClassName={generateClassName}
            >
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <ModalProvider>
                    <AppWrap>
                      <Menu />
                      <Router onChange={this.handleRoute}>
                        <CustomerView path="/c" />
                        <Chapters path="/" />
                        <DoorCommunication path="/door-communication" />
                        <SmarterHome path="/smarter-home" />
                        <SwitchControl path="/switch-control" />
                        <ModerneElektroinstallation path="/moderne-elektroinstallation" />
                      </Router>
                    </AppWrap>
                  </ModalProvider>
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </StylesProvider>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}
