import { h } from "preact";
import { useState, useCallback, useEffect } from "preact/hooks";
import { Dots } from "react-activity";
import NewsCollapse from "./Components/NewsCollapse";
import { Box, Grid } from "@material-ui/core";
import { NewsApi } from "@qmarketing/b2c-js-sdk";

export default function NewsPage() {
  const [news, setNews] = useState(null);

  const getNews = useCallback(async () => {
    try {
      let opts = {
        size: "15",
      };

      const data = await new NewsApi().apiNewsGet(opts);

      setNews(data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getNews();
  }, [getNews]);

  if (!news) {
    return <Dots />;
  }

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        {news.items.map((msg) => (
          <Grid item key={msg.id}>
            <NewsCollapse
              title={msg.title}
              author={msg.author}
              content={msg.content}
              date={msg.created_at}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
