import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/20.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/beleuchtungssteuerung/8.jpg";

const items = [
  {
    title: "Tastdimmer flex",
    img: Image1,
    link: { href: "https://www.busch-jaeger.de/busch-freeathome-flex" },
  },
  {
    title: "Drehdimmer",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-drehdimmer",
    },
  },
  {
    title: "Tastdimmer flex WL",
    img: Image3,
    link: { href: "https://www.busch-jaeger.de/busch-freeathome-flex" },
  },
  {
    title: "Friends of Hue",
    img: Image4,
    link: { href: "https://www.busch-jaeger.de/produkt/friends-of-hue" },
  },
  {
    title: "DALI",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/dali-potenziometer-broadcast",
    },
  },
  {
    title: "Seriendimmer",
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-memory-seriendimmer",
    },
  },
  {
    title: "REG Dimmer",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=236&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=e7f0c7c25a96755ed60f3f038ef244a1",
    },
  },
  {
    title: "BT-Switch/Casambi",
    img: Image8,
    link: {
      href: "https://www.busch-jaeger.de/produkt/bluetooth-smart-switch",
    },
  },
];

export default function BeleuchtungssteuerungOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/dimmer",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Beleuchtungssteuerung" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Beleuchtungssteuerung heißt heute schon längst nicht mehr „nur“
                dimmen. Neben Farbsteuerung mit farbigen Akzenten werden Lichter
                in unterschiedlichen Farbtemperaturen (Turnable White) dem
                Biorhythmus angepasst. Bus-Systeme wie DALI und Busch-free@home
                <sup>®</sup> steuern über Szenen und Timer unterschiedliche
                Beleuchtungsgruppen.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
