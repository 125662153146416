/* eslint-disable */
import { h, Component, createRef } from "preact";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  getCustomerFirstname,
  getCustomerLastname,
  getCustomerEmail,
  getCustomerSalutation,
  setCustomerSalutation,
  setCustomerLastname,
  setCustomerEmail,
  setCustomerFirstname,
  setCustomerCompany,
  getCustomerCompany,
} from "../../../redux/modules/customer";
import { connect } from "react-redux";
import { resetConsulting } from "../../../redux/modules/reducers";
import {
  getConsultingRunning,
  setConsultingTopic,
  getConsultingTopic,
  setAppointment,
  setAppointmentPlace,
  getAppointment,
  getAppointmentPlace,
  setConsultingRunning,
} from "../../../redux/modules/consulting";
import { toast } from "react-toastify";
import QActionHeader from "../../QActionHeader/index";
import { getConsultantEmail } from "../../../redux/modules/consultant";

const useStyles = makeStyles((theme) => ({
  root: {},
  setupPage: {
    padding: theme.spacing(4),
    margin: theme.spacing(0),
  },
  startConsultingButton: {
    display: "flex !important",
    margin: "30px auto",
    width: "100% !important",
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
}));

class SetupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationLabelWidth: 0,
      companies: [],
      contacts: [],
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSelectChangeSalutation =
      this.handleSelectChangeSalutation.bind(this);
    this.startConsulting = this.startConsulting.bind(this);
    this.resetConsulting = this.resetConsulting.bind(this);
    this.inputLabel = createRef();
    this.resetContact = this.resetContact.bind(this);
    this.abortConsulting = this.abortConsulting.bind(this);
    this.handleTopicChange = this.handleTopicChange.bind(this);
  }

  async componentDidMount() {
    this.setState({
      salutationLabelWidth: this.inputLabel.current.offsetWidth,
    });
    if (!this.props.consultingRunning) {
      this.handleDateChange(new Date());
    }
  }

  handleTopicChange(e) {
    this.props.setConsultingTopic(e.currentTarget.value);
  }

  handleDateChange(v) {
    this.props.setSetupField({
      id: "appointmentDate",
      value: v,
    });
  }

  handleFieldChange(e) {
    this.props.setSetupField({
      id: e.currentTarget.id,
      value: e.currentTarget.value,
    });
  }

  handleSelectChangeSalutation(e) {
    this.props.setSetupField({
      id: e.target.name,
      value: e.target.value,
    });
  }

  startConsulting(e) {
    e.preventDefault();
    const currentMail =
      typeof window !== "undefined"
        ? document.getElementById("email").value
        : this.props.email;

    this.props.setSetupField({
      id: "email",
      value: currentMail,
    });

    let rMail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!currentMail.match(rMail)) {
      toast.error("Keine gültige Kunden E-Mail hinterlegt.");
    }

    if (!this.props.consultantEmail.match(rMail)) {
      toast.error("Keine gültige ADM E-Mail hinterlegt.");
    }

    if (
      this.props.email.match(rMail) &&
      this.props.consultantEmail.match(rMail)
    ) {
      toast.info("Beratung gestartet.");
      this.props.startConsulting();
      this.props.handleDialogClose(e);
    }
  }

  abortConsulting(e) {
    e.preventDefault();
    this.props.setTab("consultations");
  }

  resetConsulting(e) {
    e.preventDefault();
    this.props.resetConsulting();
  }

  resetContact() {
    this.props.setSetupField({
      id: "firstname",
      value: "",
    });
    this.props.setSetupField({
      id: "lastname",
      value: "",
    });
    this.props.setSetupField({
      id: "email",
      value: "",
    });
    this.props.setSetupField({
      id: "company",
      value: "",
    });
  }

  render() {
    const classes = useStyles();
    const { salutationLabelWidth } = this.state;
    const {
      appointmentDate,
      appointmentPlace,
      salutation,
      firstname,
      lastname,
      email,
      company,
      consultingRunning,
      topic,
    } = this.props;

    return (
      <Grid container spacing={2} wrap="nowrap" direction="column">
        <Grid item>
          <Paper square>
            <Box p={2}>
              <Grid container direction="column">
                <Grid item>
                  <QActionHeader
                    title="Termin"
                    subTitle="Wann und bei welchem Unternehmen?"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid container spacing={4} item>
                      <Grid item xs={4}>
                        <DatePicker
                          label="Datum"
                          id="appointmentDate"
                          value={appointmentDate}
                          onChange={this.handleDateChange}
                          cancelLabel="Abbrechen"
                          format="dd.MM.yyyy"
                          animateYearScrolling
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          label="Ort"
                          value={appointmentPlace}
                          id="appointmentPlace"
                          onChange={this.handleFieldChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Paper square>
            <Box p={2}>
              <Grid container direction="column" wrap="nowrap">
                <Grid item>
                  <QActionHeader
                    title="Kunde"
                    subTitle="Wen treffen Sie?"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <form className={classes.root} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid container spacing={2} item>
                        <Grid item xs>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel
                              htmlFor="salutation"
                              ref={this.inputLabel}
                              id="salutation-select-outlined-label"
                            >
                              Anrede
                            </InputLabel>
                            <Select
                              native
                              labelId="salutation-select-outlined-label"
                              id="salutation"
                              value={salutation}
                              onChange={this.handleSelectChangeSalutation}
                              labelWidth={salutationLabelWidth}
                              inputProps={{
                                name: "salutation",
                                id: "salutation",
                              }}
                            >
                              <option value="Herr">Herr</option>
                              <option value="Frau">Frau</option>
                            </Select>
                          </FormControl>
                          {/* <TextField id="outlined-basic" onChange={this.handleFieldChange} id="salutation" value={salutation} fullWidth={true} label="Anrede" variant="outlined" /> */}
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            label="Vorname"
                            id="firstname"
                            onChange={this.handleFieldChange}
                            variant="outlined"
                            fullWidth
                            value={firstname}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            label="Nachname"
                            id="lastname"
                            onChange={this.handleFieldChange}
                            variant="outlined"
                            fullWidth
                            value={lastname}
                          />
                        </Grid>
                      </Grid>

                      <Grid container item spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            label="E-Mail"
                            type="email"
                            id="email"
                            onChange={this.handleFieldChange}
                            variant="outlined"
                            fullWidth
                            value={email}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            label="Unternehmen"
                            type="company"
                            id="company"
                            onChange={this.handleFieldChange}
                            variant="outlined"
                            fullWidth
                            value={company}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Paper square>
            <Box p={2}>
              <Grid container direction="column">
                <Grid item>
                  <QActionHeader
                    title="Besuchsthema"
                    subTitle="Was ist der Anlass des Termins?"
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <form className={classes.root} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid container item>
                        <Grid item xs={12}>
                          <TextField
                            onChange={this.handleTopicChange}
                            id="topic"
                            value={topic}
                            fullWidth
                            label="Thema"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          {!consultingRunning && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              spacing={2}
              xs={10}
            >
              <Grid item xs={6}>
                <Button
                  className={`${classes.startConsultingButton} centered-button mt-3 plaintext`}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={this.startConsulting}
                >
                  Starten
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  className={`${classes.startConsultingButton} centered-button mt-3 plaintext`}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={this.abortConsulting}
                >
                  Abbrechen
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  salutation: getCustomerSalutation(state),
  firstname: getCustomerFirstname(state),
  lastname: getCustomerLastname(state),
  email: getCustomerEmail(state),
  company: getCustomerCompany(state),
  consultantEmail: getConsultantEmail(state),
  consultingRunning: getConsultingRunning(state),
  topic: getConsultingTopic(state),
  appointmentDate: getAppointment(state),
  appointmentPlace: getAppointmentPlace(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSetupField(valueSet) {
    switch (valueSet.id) {
      case "salutation":
        {
          dispatch(setCustomerSalutation(valueSet.value));
        }
        break;
      case "firstname":
        {
          dispatch(setCustomerFirstname(valueSet.value));
        }
        break;
      case "lastname":
        {
          dispatch(setCustomerLastname(valueSet.value));
        }
        break;
      case "email":
        {
          dispatch(setCustomerEmail(valueSet.value));
        }
        break;
      case "company":
        {
          dispatch(setCustomerCompany(valueSet.value));
        }
        break;
      case "appointmentDate":
        {
          dispatch(setAppointment(valueSet.value));
        }
        break;
      case "appointmentPlace": {
        dispatch(setAppointmentPlace(valueSet.value));
      }
    }
  },
  startConsulting() {
    dispatch(setConsultingRunning(true));
  },
  resetConsulting() {
    dispatch(resetConsulting());
  },
  setConsultingTopic(topic) {
    dispatch(setConsultingTopic(topic));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupPage);
