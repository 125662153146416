import { h } from "preact";
import Grid from "@material-ui/core/Grid";
import Slide from "../../../../../components/Slide/index";

export default function Lebensraeume() {
  return (
    <Slide>
      <iframe
        src="https://lebensraeume.busch-jaeger.de"
        width="100%"
        style={{ border: "none", height: "calc(100% - 6.5rem)" }}
      />
    </Slide>
  );
}
