import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Haushaltsgeraete() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/haushaltsgeraete.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/tuerkommunikation/produkte/haushaltsgeraete.jpg"
        alt="Busch-free@homePanel"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Haushaltsgeräte
            </QBodyText>
            <QBodyText>
              Ein kompatibles Smart-Home-System bietet viele Vorteile, die
              zusätzlichen Komfort und Sicherheit bieten
            </QBodyText>
            <Box style={{ paddingRight: "16vw" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Anbindung von Miele Haushaltsgeräten",
                  "Anbindung von BSH Haushaltsgeräten über HomeConncet",
                  "Abfrage von Status, Restlaufzeit, Betriebsart",
                  "Bedienen ausgewählter Funktionen z. B. Gerät „Aus“",
                  "Einbindung in Szenen z. B. „Zentral Aus“ oder Geofencing",
                ]}
                dense
                variant="arrow"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
