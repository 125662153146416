import ChapterWrapper from "../../../components/ChapterWrapper";
import Loesungen from "./sections/loesungen";
import DreiDLiveView from "./sections/3d-live-view";
import SchaltenUndBedienen from "./sections/SchaltenUndBedienen";

export default function SwitchControl() {
  return (
    <ChapterWrapper chapterKey="switchcontrol">
      <DreiDLiveView anchor="drei-d-liveview" />
      <Loesungen anchor="loesungen" />
      <SchaltenUndBedienen anchor="schalten-und-bedienen" />
    </ChapterWrapper>
  );
}
