import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/41.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/usb-laden/usb-laden.jpg";
import SCHUKOUSBSteckdose from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/usb-laden/schuko-usb.jpg";
import BuschPowerdock from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/usb-laden/lightning.jpg";

export default function USBLadegeraetOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/usb-ladegeraete",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="USB-Ladegeräte" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Mit den USB-Ladegeräten finden Mobiltelefone ihren idealen Platz
                zum Aufladen. Die SCHUKO® USB-Steckdose kann man nachrüsten und
                es geht kein Steckdosenplatz verloren. Mit den
                USB-Ladestation-Einsätzen können moderne Mobiltelefone,
                Digitalkameras, Festplatten und ähnliche Geräte geladenoder
                versorgt werden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Integrierbar ins Schalterdesign",
                  "Schnellladefunktion",
                  "Lademanagement",
                  "Bis zu 3A Ladestrom (Varianten-/Belegungsabhängig)",
                ]}
              />
              <ImageComponent
                wrapperWidth="80%"
                align="left"
                col={3}
                images={[
                  {
                    src: SCHUKOUSBSteckdose,
                    alt: (
                      <>
                        SCHUKO<sup>®</sup> USB-Steckdose
                      </>
                    ),
                  },
                  {
                    src: BuschPowerdock,
                    alt: "Busch-powerDock",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="35vh"
                shadow
                src={CenteredImg}
                alt="USB-Netzteil-Einsatz"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
