import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Zutrittskontrolle() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/smarterhome/background/zutrittskontrolle.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/smarterhome/produkte/zutrittskontrolle.jpg"
        alt="Außenstation mit Fingerprint-Modul"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Zutrittskontrolle
            </QBodyText>
            <QBodyText>
              Die Zutrittskontroll-Module Transponder, Tastatur und Fingerprint
              stellen eine interessante Lösung für den Wohn- oder Zweckbau dar.
            </QBodyText>
            <Box style={{ paddingRight: "140px" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Direkte Inbetriebnahme ohne Zusatzgeräte möglich",
                  "Komfortable Inbetriebnahme per Webbrowser via Busch-Welcome® IP-Gateway möglich",
                  "Optionale Zutrittssteuerung via Fingerprint, Transponder oder Tastatur-Modul",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <ImageComponent
              col={4}
              align="left"
              images={[
                {
                  alt: "Außenstation mit Transponder-Modul",
                  src: "../../../../../../../assets/smarterhome/produkte/zutrittskontrolle-left.jpg",
                },
                {
                  alt: "Außenstation mit Fingerprint",
                  src: "../../../../../../../assets/smarterhome/produkte/zutrittskontrolle-right.jpg",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
