const SET_BACKEND_STATE = "playbook/state/SET_BACKEND_STATE";

const initialState = {
  modules: [],
  version: "",
  fetchDate: null,
  initialState: null,
};

const backendState = (state = initialState, action) => {
  switch (action.type) {
    case SET_BACKEND_STATE: {
      return {
        ...state,
        modules: action.payload.modules,
        version: action.payload.version,
        initialState: {
          modules: action.payload.modules,
          version: action.payload.version,
        },
        fetchDate: new Date(),
      };
    }
    default:
      return state;
  }
};

//actions
export const fetchBackendState = (payload) => ({
  type: SET_BACKEND_STATE,
  payload,
});

//selector
export const getStateVersion = (state) => state.backendState.version;
export const getStateModules = (state) => state.backendState.modules;

export const getModuleByID = (state, id) =>
  state.backendState.modules.find((module) => module.chapter.id === id);

export const getModuleByKey = (state, key) =>
  state.backendState.modules.find((module) => module.chapter.key === key);

export const getActiveModules = (state) =>
  state.backendState.modules.filter(
    (module) => module.chapter.enabled === true
  );

export const getModuleChapterCategories = (state) =>
  state.backendState.modules
    .map((module) => module.chapter.category)
    .filter(
      (category, index, self) =>
        index === self.findIndex((c) => c.id === category.id)
    );

export const getModuleKeys = (state) =>
  state.backendState.modules.map((module) => module.key);

export const getFields = (state) =>
  state.backendState.modules.map((module) => module.fields);

export default backendState;
