import { Grid } from "@material-ui/core";
import Slide from "../../../../../components/Slide";

import QHeader from "../../../../../components/QHeader";
import QList from "../../../../../components/QList";
import QBodyText from "../../../../../components/QBodyText";

export default function Intro() {
  return (
    <Slide
      background={{
        src: "../../../../../assets/_backgrounds/renovation-und-neubau/1.jpg",
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Moderne Elektroinstallation" />
        </Grid>
        <Grid item container>
          <Grid item xs={5}>
            <QBodyText>
              Die Anforderungen an eine moderne Elektroinstallation, ob im
              Neubau als auch in der Sanierung, ist vielfältig. Wesentliche
              Faktoren die berücksichtigt werden sollten sind hierbei:
            </QBodyText>
            <QList
              items={[
                "Energieeffizienz",
                "Komfort",
                "Sicherheit",
                "Flexibilität für die Zukunft",
                "Nachhaltig",
                "Funktionalität",
                "Bedienbarkeit",
                "Qualität",
              ]}
              dense
            />
            <QBodyText>
              Als weltweit agierender Markenhersteller mit Produktionsstandorten
              in Deutschland, Europa und der ganzen Welt sind wir als
              kompetenter und zuverlässiger Partner ganz nah.
              Elektroinstallation Made in Germany.
            </QBodyText>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
