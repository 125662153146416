import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Visualisierung() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/visualisierung.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "left",
      }}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Box style={{ paddingRight: "160px" }}>
              <QBodyText fontWeight="bold" marginBottom="0px">
                Visualisierung
              </QBodyText>
              <QBodyText>
                Bei einer zeitgemäßen Installation kommen einige Verbraucher
                zusammen. Jalousie, Beleuchtung, Raumtemperaturregler. Oft
                entstehen wuchtige Schalterkombinationen. Displays sind nicht
                nur optisch eleganter sondern erleichtern die Bedienung.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Unterschiedliche Displaygrößen",
                  "Anbindung an die Türkommunikation",
                  "Saubere Beschriftung der Schaltflächen",
                  "Mehr Bedienoptionen wie z. B. Temperatur- oder Farbauswahl",
                ]}
                dense
                variant="arrow"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
