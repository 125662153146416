import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";

import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/4.jpg";
import Bewegungsmelder from "../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder.jpg";
import BuschWelcome from "../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-welcome.jpg";
import BewegungsmelderMultilinse from "../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder-multilinse.jpg";
import BuschIceLight from "../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-icelight.jpg";
import BuschObjektsteckdose from "../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-objektsteckdose.jpg";
import BewegungsmelderSelect from "../../../../../../../../assets/renovation-und-neubau/raume/diele/bewegungsmelder-select.jpg";
import BuschSteckdoseSteplight from "../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-steckdose-steplight.jpg";

// Placeholder
import ChapterCard from "../../../../../../../../components/ChapterCard";
import RauchwarnmelderOverlay from "./slides/Rauchwarnmelder";
import BuschWelcomeOverlay from "./slides/Busch-Welcome";
import BewegungsmelderMultiOverlay from "./slides/Bewegungsmelder-Multi";
import BewegungsmelderSelectOverlay from "./slides/Bewegungsmelder-Select";
import BuschIceLightOverlay from "./slides/Busch-iceLight";
import SchukoSteckdoseOverlay from "./slides/Schuko-Steckdose";
import BuschObjektsteckdoseOverlay from "./slides/Busch-Objektsteckdose";

const items = [
  {
    title: "Rauchwarnmelder",
    img: Bewegungsmelder,
    menuOverlay: <RauchwarnmelderOverlay />,
  },
  {
    title: (
      <>
        Busch-Welcome<sup>®</sup>
      </>
    ),
    img: BuschWelcome,
    menuOverlay: <BuschWelcomeOverlay />,
  },
  {
    title: "Bewegungsmelder Multi",
    img: BewegungsmelderMultilinse,
    menuOverlay: <BewegungsmelderMultiOverlay />,
  },
  {
    title: (
      <>
        Busch-iceLight<sup>®</sup>
      </>
    ),
    img: BuschIceLight,
    menuOverlay: <BuschIceLightOverlay />,
  },
  {
    title: (
      <>
        Busch-Objektsteckdose<sup>®</sup>
      </>
    ),
    img: BuschObjektsteckdose,
    menuOverlay: <BuschObjektsteckdoseOverlay />,
  },
  {
    title: "Bewegungsmelder Select",
    img: BewegungsmelderSelect,
    menuOverlay: <BewegungsmelderSelectOverlay />,
  },
  {
    title: "SCHUKO Steckdose / Steplight",
    img: BuschSteckdoseSteplight,
    menuOverlay: <SchukoSteckdoseOverlay />,
  },
];

export default function Diele() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "right",
        width: 0.45,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Diele" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Hier öffnen Besucher die Tür. Ihr Komfort und Ihre Sicherheit
                beginnt hier. Ein wichtiger Ort für viele Steuerungsfunktionen
                in Ihrem Zuhause.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
