import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/45.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/Jalousiesteuerung/jalousiesteuerung.jpg";

export default function BuschFreeAtHomeFlexJalousieOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-freeathome-flex",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://www.busch-jaeger.de/bje-flex/static/media/Jalousien-Video_DE.cb29864af9d41a53d3ae.mp4",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup> flex, Jalousie
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Neben der Möglichkeit über Busch-free@home® flex die
                Jalousiesteuerung Vor-Ort großflächig und gewohnt zu bedienen,
                bietet sie auch eine stufenlose Positionierung für blendfreies
                arbeiten und Fernsteuerung per App während eines Telefonats oder
                meetings.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Vor-Ort-Bedienung",
                  "Direkte Anfahrt von Positionen/Presets",
                  "Zeitschaltuhr mit Zwischenstellungen für eine automatische Beschattung",
                  "Rollladen/Jalousiesteuerung",
                  "Ins Schalterdesign integrierbar",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="35vh"
                shadow
                src={CenteredImg}
                alt={
                  <>
                    Busch-free@home<sup>®</sup> flex, Jalousie
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
