import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/17.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kueche/USB/schuko-usb-steckdosen.png";

export default function USBOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "right",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/schuko-usb-steckdose",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="SCHUKO® USB-Steckdose" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Eine Kombination aus normaler Steckdose und USB-Anschlüssen. So
                können Smartphones und Tablets während des Strombetriebs an
                einem festen Platz aufgeladen werden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Kombination aus SCHUKO®-Steckdose und zwei USB-A-Anschlüssen",
                  "Mit der USB-Steckdose hat jedes Smartphone, Tablet, jeder Fotoapparat oder MP3-Player einen festen Platz zum Aufladen",
                  "Keine Suche mehr nach dem jeweils eigenen Netzteil",
                  "Die USB-Steckdose lässt sich ganz einfach nachrüsten",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="USB-Steckdosen"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
