import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/44.jpg";
import CenteredImage from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/tischstaender/tischstaender.png";
import Tischstaender1 from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/tischstaender/busch-smarttouch.jpg";
import Tischstaender2 from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/tischstaender/ip-touch.jpg";

export default function TischstaenderOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg, align: "center" }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=4404&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=f42eeced303ae80f1f564542616a0b74",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Tischständer" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Eine mobile Kommandozentrale: Mit dem Tischständer lassen sich
                Panels, wie das SmartTouch 10“, IP touch 7 oder IP touch 10
                mobil im Haus nutzen. Die Displays können per WLAN an free@home
                oder Welcome IP angemeldet und genutzt werden. Beispielsweise im
                Büro oder an einem Empfangs- Tresen.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Mobile Nutzung",
                  "Weniger Montageaufwand",
                  "Anbindung über WLAN an Welcome IP",
                  "Anbindung über WLAN an f@h",
                  "Drahtgebundene Anbindung an Welcome TP möglich",
                ]}
              />
              <ImageComponent
                col={4}
                wrapperWidth="100%"
                images={[
                  {
                    src: Tischstaender1,
                    alt: (
                      <>
                        Busch-SmartTouch<sup>®</sup> 10"
                      </>
                    ),
                  },
                  { src: Tischstaender2, alt: 'IP touch 10"' },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                col={12}
                wrapperWidth="80%"
                src={CenteredImage}
                alt="Tischständer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
