import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/18.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kueche/Waermemelder/busch-waermemelder.png";

export default function WaermemelderOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "right",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/rauchmelder/busch-waermealarm-professionalline",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://youtu.be/dWpPPOF2FQA",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Wärmemelder" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Als Alternative zum Rauchmelder ist die Wärmeerkennung für den
                Einsatz in der Küche die geeignete Technik. Häufig auftretender
                Wasserdampf führt hier nicht zum Fehlalarm. Auch auf einem
                staubigen Dachboden könnte ein Melder mit Rauchpartikelerkennung
                schnell verschmutzen und würde im Ernstfall nicht funktionieren.
                Der Wärmealarm dagegen liefert konstante Wachsamkeit – über zehn
                Jahre durch die Lithiumbatterie. Die Funkvernetzung schafft
                weitere Sicherheit.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Der Wärmemelder ist für Küchen entwickelt",
                  "Wasserdampf könnte hier einen Rauchwarnmelder aktivieren, beim Wärmemelder löst die Dampftemperatur keinen Alarm aus",
                  "Langsam anschwellender Alarmton bei Funktionstest",
                  "Vernetzung mit anderen Rauchwarnmeldern",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="USB-Steckdosen"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
