import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/38.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/edv/edv.jpg";

export default function MultimediaDatenanschlussOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg, align: "left" }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatKapitel%5D=24&tx_nlbjproducts_catalog%5Bcontroller%5D=CatDokument&cHash=922d078130f3d79c98496a808584474b",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Multimedia / Datenanschluss" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Des einen Freud ist des anderen Leid. Kinder wachsen mit Medien
                auf und müssen lernen sie richtig einzusetzen. Zum Lernen,
                Chillen aber auch mal zum Spielen.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Datendosen",
                  "Kommunikationsadapter",
                  "Telefon",
                  "Netzwerk",
                  "Lautsprecher",
                  "UAE-Dosen",
                  "Antenne",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Multimedia / Datenanschluss"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
