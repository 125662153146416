import { h } from "preact";
import { useState, useRef } from "preact/hooks";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import root from "window-or-global";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    border: "1px solid rgba(255, 255, 255, 1) !important",
  },
  startPausedButton: {
    textTransform: "inherit !important",
    maxHeight: "45px",
  },
  dropdownButton: {
    color: "rgba(255, 255, 255, 1)",
    borderLeft: "1px solid rgba(255, 255, 255, 1) !important",
    "&:before": {
      content: "none !important",
      left: "0px !important",
    },
    maxHeight: "45px",
  },
}));

export default function OptionsForPausedConsultation({
  loadConsultation,
  deletePausedConsultation,
  data,
  disabled,
}) {
  const classes = useStyles();
  const options = ["Löschen"];
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClick = () => {
    loadConsultation(data);
  };

  const handleMenuItemClick = (event, index) => {
    switch (options[index]) {
      case "STARTEN":
        loadConsultation(data);
        break;
      case "Löschen":
        handleDeleteClickOpen();
        break;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteTrueClose = () => {
    deletePausedConsultation(data.consulting.internalID);
    setOpenDelete(false);
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        size="small"
        color="primary"
        ref={anchorRef}
        className={`${classes.buttonGroup} plaintext`}
        aria-label="options"
      >
        <Button
          className={`${classes.startPausedButton} plaintext`}
          onClick={handleClick}
          disabled={disabled}
        >
          STARTEN
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={`${classes.dropdownButton} short`}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 99 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Löschen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie die Beratung entfernen? (Unwiderruflich)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleDeleteClose}
            color="primary"
            className="plaintext"
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleDeleteTrueClose}
            color="primary"
            className="plaintext"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
