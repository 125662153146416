"use strict";

import {
  RBACClient,
} from "./proto/compiled/RbacServiceClientPb";

import {
  PermissionRequest
} from './proto/compiled/rbac_pb.js';

export class RBACRequest {
  readonly client: RBACClient

  constructor() {
    this.client = new RBACClient(process.env.PREACT_APP_MODULE_RBAC_API_URI)
  }

  async can(
    subject: string,
    obj: string,
    action: string,
    implicit: boolean = false,
    application?: string
  ): Promise<any> {

    if (typeof subject !== "string") {
      console.error("subject expected as typeof string")
      return false
    }

    if (typeof obj !== "string") {
      console.error("obj expected as typeof string")
      return false
    }

    if (typeof action !== "string") {
      console.error("action expected as typeof string")
      return false
    }

    const req = new PermissionRequest();
    if (application) {
      req.setApplication(application);
    }

    req.setSubject(subject)
    req.setObject(obj);
    req.setAction(action);
    req.setImplicit(implicit);

    return this.client.userCan(req, { "x-auth-ispf": subject })
  }
}
