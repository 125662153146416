import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/33.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/sicherheit/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/sicherheit/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/sicherheit/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/sicherheit/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/sicherheit/5.jpg";

const bedienelementItems1 = [
  {
    title: "Rauchwarnmelder",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produkt/rauchmelder/busch-rauchalarm",
    },
  },
  {
    title: "Wärmemelder",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produkt/rauchmelder/busch-waermealarm-professionalline",
    },
  },
  {
    title: "CO-Melder",
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produkt/rauchmelder/busch-co-alarm",
    },
  },
  {
    title: "Funk-Alarmschalter",
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=4646&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=494cc701fa53ab6e84dc7f6420bf4bd3",
    },
  },
  {
    title: "Funk-Notwarnkopf",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=4648&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=0058b38e158f64f16c8a0dfa52845f8a",
    },
  },
];

export default function Sicherheit() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "right",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=364&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=ea1dd6e366add235331351dcc3bcecdd",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QHeader title="Sicherheit" arrow />
              <QBodyText>
                Die größte Gefahr besteht, wenn Sie schlafen und der menschliche
                Geruchssinn inaktiv ist. Dies ist die Zeit, in der rechtzeitige
                Informationen über ein Feuer oder seinen Ausbruch lebensrettend
                sind. Ein Gerät und sein perfekter Geruchssinn schützt die ganze
                Familie vor Feuer.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {bedienelementItems1.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
