import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/9.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/eingang/bewegungsmelder/bewegungsmelder.png";

export default function BewegungsmelderOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/bewegungsmelder",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Türkommunikation" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Beleuchtet automatisch den Weg für ihre Gäste, Schreckt aber
                ungebetene Gäste auch ab. Perfekte Einbaumöglichkeit für
                unterschiedliche örtliche Begebenheiten. Einige Typen können
                sogar über Eck gesetzt werden. Über die optionale Fernbedienung
                kann das Licht auch 4Std. Ein oder ausgeschaltet werden. Das ist
                z.B. bei Feiern komfortabel. Über die Urlaubsfunktion wird das
                Licht bei Dämmerung eingeschaltet und gegen 22° in den
                Wächterbetrieb gewechselt. Das setzt die Auffahrt in Szene.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Verschiede Erfassungsreichweiten",
                  "Verschiedene Bauformen und Farben",
                  "Automatische Rauschunterdrückung",
                  "Automatische Reichweitenstabilisieurung (Sommer/Winter)",
                  "Optional: Fernbedienung",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="70vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
