import { h } from "preact";
import { forwardRef } from "preact/compat";
import { useRef } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import { isLoggedIn as getIsLoggedIn } from "../../../redux/modules/userReducer";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Button, Grid, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { loadConsultation as loadConsultationDispatch } from "../../../redux/modules/reducers";
import { Offline, Online } from "react-detect-offline";
import {
  getConsultationStore,
  deletePausedConsultation as deletePausedConsultationDispatch,
} from "../../../redux/modules/consultationStore";
import { getConsultingRunning } from "../../../redux/modules/consulting";
import OptionsForConsultation from "./Components/OptionsForConsultation";
import OptionsForPausedConsultation from "./Components/OptionsForPausedConsultation";
import * as B2CSDK from "@qmarketing/b2c-js-sdk";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ConsultationsPage({ setTab }) {
  const dispatch = useDispatch();
  const isLoggedin = useSelector(getIsLoggedIn);
  const consultingRunning = useSelector(getConsultingRunning);
  const storedConsultations = useSelector(getConsultationStore);
  const tableRef = useRef();

  const loadConsultation = (consultation) => {
    dispatch(loadConsultationDispatch(consultation));
    setTab("consulting");
  };

  const deletePausedConsultation = (id) => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
    dispatch(deletePausedConsultationDispatch(id));
  };

  const deleteRemoteConsultation = (id) => {
    if (navigator.onLine && isLoggedin) {
      let apiInstance = new B2CSDK.ConsultationApi();
      apiInstance.apiConsultationIdDelete(id).then(() => {
        if (tableRef.current) {
          tableRef.current.onQueryChange();
        }
        toast.success("Beratung gelöscht.");
      });
    }
  };

  const adjustData = (data) => {
    const resultLink = (id) => process.env.PREACT_APP_B2CMICROSITE + id;
    const pdfLink = (id) =>
      `${process.env.PREACT_APP_B2CAPI}/api/consultation/${id}/pdf`;

    let res = data.reverse().map((d) => {
      const dateAppointment = new Date(d.consulting.appointment);
      return {
        customer: d.customer.email,
        appointment: dateAppointment,
        topic: d.consulting.topic,
        status: "",
        action: [
          <Online
            key="online"
            polling={{
              url: `${process.env.PREACT_APP_B2CAPI}/ping`,
              interval: 60000,
            }}
          >
            <OptionsForConsultation
              eDialog={resultLink(d.id)}
              pdf={pdfLink(d.id)}
              id={d.id}
              deleteRemoteConsultation={deleteRemoteConsultation}
            />
          </Online>,
          <Offline
            key="offline"
            polling={{
              url: `${process.env.PREACT_APP_B2CAPI}/ping`,
              interval: 60000,
            }}
          >
            <OptionsForConsultation disabled />
          </Offline>,
        ],
      };
    });
    return res;
  };

  const adjustDataForStoredConsultations = (data) => {
    let res = data.reverse().map((d) => {
      const dateAppointment = new Date(d.consulting.appointment);
      return {
        customer: d.customer.email,
        appointment: dateAppointment,
        topic: d.consulting.topic,
        status: (
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Pausiert
          </Typography>
        ),
        action: (
          <OptionsForPausedConsultation
            loadConsultation={loadConsultation}
            deletePausedConsultation={deletePausedConsultation}
            data={d}
            disabled={consultingRunning}
          />
        ),
        id: d.consulting.internalID,
      };
    });
    return res;
  };

  const startNewConsulting = () => {
    setTab("setup");
  };

  const pausedConsultations = adjustDataForStoredConsultations(
    storedConsultations.consultations
  );

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      justify="center"
      alignItems="stretch"
    >
      <Grid item>
        <MaterialTable
          tableRef={tableRef}
          columns={[
            {
              title: "Kunde",
              field: "customer",
              customFilterAndSearch: (term, rowData) =>
                `${rowData.customer.email.toLowerCase()}`.indexOf(
                  term.toLowerCase()
                ) !== -1,
            },
            {
              title: "Datum",
              field: "appointment",
              type: "date",
            },
            {
              title: "Besuchsthema",
              field: "topic",
              customFilterAndSearch: (term, rowData) =>
                rowData.topic.toLowerCase().indexOf(term.toLowerCase()) !== -1,
            },
            { title: "", field: "status" },
            { title: "", field: "action" },
          ]}
          title={
            <Typography variant="h6" component="h2" gutterBottom>
              Meine Beratungen
              <Offline
                polling={{
                  url: `${process.env.PREACT_APP_B2CAPI}/ping`,
                  interval: 60000,
                }}
              >
                <Typography variant="overline" display="block" gutterBottom>
                  Offlinemodus: Liste eventuell nicht vollständig.
                </Typography>
              </Offline>
            </Typography>
          }
          icons={tableIcons}
          options={{
            headerStyle: {
              color: "#000",
            },
            rowStyle: {
              color: "black",
            },
            emptyRowsWhenPaging: false,
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              let apiInstance = new B2CSDK.ConsultationsApi();
              let opts = {
                size: query.pageSize, // String | size
                page: query.page, // String | page
              };
              apiInstance.apiConsultationsConsultantGet(opts).then(
                (data) => {
                  const d = adjustData(data.items);
                  resolve({
                    data: [...pausedConsultations, ...d],
                    page: data.page,
                    totalCount: data.total,
                  });
                },
                (error) => {
                  reject(error);
                }
              );
            })
          }
          localization={{
            toolbar: {
              searchTooltip: "Suche",
              searchPlaceholder: "Suche",
            },
            pagination: {
              labelRowsSelect: "Zeilen",
              labelRowsPerPage: "Zeilen pro Seite:",
              firstAriaLabel: "Erste Seite",
              firstTooltip: "Erste Seite",
              previousAriaLabel: "Vorherige Seite",
              previousTooltip: "Vorherige Seite",
              nextAriaLabel: "Nächste Seite",
              nextTooltip: "Nächste Seite",
              lastAriaLabel: "Letzte Seite",
              lastTooltip: "Letzte Seite",
              labelDisplayedRows: "{from}-{to} von {count}",
            },
            body: {
              emptyDataSourceMessage: [
                <Online
                  key="body-online"
                  polling={{
                    url: `${process.env.PREACT_APP_B2CAPI}/ping`,
                    interval: 60000,
                  }}
                >
                  <Typography>Keine Beratungen</Typography>
                </Online>,
              ],
            },
          }}
        />
      </Grid>
      <Grid item>
        <Grid container justify="center">
          {!consultingRunning && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={startNewConsulting}
            >
              Neue Beratung
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
