import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function LEDLicht() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/SchaltenUndSteuern/background/led-licht.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/SchaltenUndSteuern/produkte/led-licht.jpg"
        alt="Busch-iceLight® – Wandmodul Ambiente"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Beleuchtung" />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              LED-Licht
            </QBodyText>
            <QBodyText>
              Die LED-Leuchten von Busch-Jaeger geben Lichtgestaltung eine neue
              Qualität. Ihr geradliniges Design betont die Architektur und weckt
              Emotionen.
            </QBodyText>
            <Box style={{ paddingRight: "10vw" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Geradliniges Design",
                  "Hochwertige Technik",
                  "Robust, langlebig und effizient",
                  "Neue Perspektiven beim Gestalten, Beleuchten, Orientieren und Informieren",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Busch-steplight®",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/led-licht-left.jpg",
                  },
                  {
                    alt: "Busch-iceLight® – Orientierung",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/led-licht-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
