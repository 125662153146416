import React from "react";

import Grid from "@material-ui/core/Grid";

import QHeader from "../QHeader";
import QScrollBody from "../QScrollBody";
import Section from "../Section";
import Slide from "../Slide";
import QActionPaper from "../QActionPaper";
import QTextFieldAction from "../QTextFieldAction";
import QIncrementGroup from "../QIncrementGroup";
import { useDispatch, useSelector } from "react-redux";
import { setFieldValue } from "../../redux/modules/fieldValuesReducer";

export default function ActionSection({ module, section }) {
  const fieldValues = useSelector((state) => state.fieldValues.fieldValues);
  const dispatch = useDispatch();

  const fields = module.fields.map((field) => {
    const fieldValue = fieldValues.find((el) => el.id === field.id);

    switch (field.fieldType.dataType) {
      case "string":
        const stringValue = fieldValue ? fieldValue.value : ""; // eslint-disable-line
        return (
          <QActionPaper
            key={field.id}
            title={field.description}
            context="fullpage"
          >
            <QTextFieldAction
              value={stringValue}
              valueID={field.id}
              placeholder={field.placeholder}
              setSetupField={(data) => {
                dispatch(setFieldValue({ id: data.id, value: data.value }));
              }}
            />
          </QActionPaper>
        );
      case "order":
        const orderValue = fieldValue ? fieldValue.value : 0; // eslint-disable-line
        return (
          <QActionPaper
            key={field.id}
            // title={field.fieldType.description}
            context="fullpage"
          >
            <QIncrementGroup
              value={parseInt(orderValue, 10)}
              valueID={field.id}
              label={field.description}
              mode={1}
              setAsset={(id, val) => {
                dispatch(setFieldValue({ id, value: val.toString() }));
              }}
            />
          </QActionPaper>
        );
    }
  });

  return (
    <Section selector={section}>
      <Slide
        background={{
          enabled: true,
          alignment: "left",
          width: "100%",
          height: "100%",
        }}
      >
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <Grid item>
            <QHeader title={module.chapter.name} subTitle="Aktionsliste" />
          </Grid>
          <Grid item>
            <QScrollBody maxHeight="70vh">{fields}</QScrollBody>
          </Grid>
        </Grid>
      </Slide>
    </Section>
  );
}
