import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/35.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/busch-radio-dab-plus/busch-radio-dab-plus.jpg";

export default function BuschRadioDABPlusOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-radio-dab",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Radio DAB+" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Die neue Generation DigitalRadio für die beste Unterhaltung in
                Ihrem Zuhause. Der genial einfache Weg, Musik und Information in
                Küche, Bad oder Schlafzimmer zu holen – ohne großen Platzbedarf
                und ohne Kabelsalat.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "DAB+ und UKW-Empfang",
                  "Beste Klangqualität, ohne Störungen",
                  "Hochauflösendes Farbdisplay zur Anzeige von mitgelieferten Zusatzdiensten wie z.B. Titelanzeige",
                  "Sleep-/Weckfunktion",
                  "Nebenstelleneingang zum ferngesteuerten EIN-/AUS-Schalten",
                  "Auch als Variante mit BT erhältlich (s. Küche)",
                  "Über Nebenstelle mit Licht/Bewegungsmelder schaltbar",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                shadow
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Busch-Radio DAB+"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
