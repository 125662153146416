import { Box, Grid } from "@material-ui/core";

import backgroundLichtschalter from "../../../../../../../../assets/_backgrounds/cwa/3.jpg";

import QHeader from "../../../../../../../../components/QHeader";
import Slide from "../../../../../../../../components/Slide";
import QList from "../../../../../../../../components/QList";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import NachhaltigkeitSVG from "./nachhaltigkeitSVG";
import ImageComponent from "../../../../../../../../components/ImageComponent";
import SchalterImage from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/schalter-gesamt/holz-eiche.png";

import ZertifikatImage from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/cradle-to-cradle-certified.png";
import CenteredImage from "../../../../../../../../components/CenteredImage";

// Trailer
import TrailerVideo from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/trailer-busch-art-linear-nachhaltigkeit.mp4";

export default function Nachhaltigkeit() {
  return (
    <Slide
      actions={[
        {
          id: "nachhaltigkeit",
          type: "dialog",
          img: "info",
          children: (
            <TransformWrapper>
              <TransformComponent>
                <NachhaltigkeitSVG />
              </TransformComponent>
            </TransformWrapper>
          ),
        },
        {
          id: "trailer",
          type: "dialog",
          img: "youtube",
          children: (
            <Box
              padding="3.5rem 1.5rem"
              sx={{
                height: "calc(100% - 3.5rem * 2)",
                width: "calc(100% - 1.5rem * 2)",
              }}
              display="flex"
              alignItems="center"
            >
              <video autoPlay controls style={{ width: "100%" }}>
                <source src={TrailerVideo} type="video/mp4" />
              </video>
            </Box>
          ),
        },
      ]}
      background={{
        src: backgroundLichtschalter,
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        shadow
        src={SchalterImage}
        alt="Busch-art linear | Holz Eiche"
        height="40vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-art linear" subTitle="Vor allem nachhaltig" />
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <QList
              items={[
                "Nachhaltige Materialien",
                <>
                  Klimaneutrale Produktion /<br />
                  Mission 2 zero
                </>,
                "93 % weniger CO2 im Produktionsprozess",
                "98 % - Anteil Rezyclat (gemäß ISO 14021) bei den schwarzen Schaltern",
                "92 % - Anteil Rezyclat (gemäß ISO 14021) bei den weißen Schaltern",
                "Cradle to cradle Gold",
              ]}
              dense
            />
            <ImageComponent
              col={4}
              wrapperWidth="100%"
              align="left"
              images={[
                {
                  src: ZertifikatImage,
                  alt: "Cradle to cradle",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
