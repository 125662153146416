import { h } from "preact";
import { useEffect } from "preact/hooks";
import { Children } from "preact/compat";
import ReactFullpage from "@fullpage/react-fullpage";
import { Box, Grid, makeStyles } from "@material-ui/core";
import NextChapter from "../NextChapter";
import Section from "../Section";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModule } from "../../redux/modules/consulting";
import { getModuleByKey } from "../../redux/modules/backendState";
import ActionSection from "../ActionSection";

const useStyles = makeStyles(() => ({
  actions: {
    position: "absolute",
    bottom: "25px",
    "& img": {
      height: "25px",
      widht: "auto",
    },
  },
  logo: {
    position: "absolute",
    bottom: "12px",
    right: "12px",
    zIndex: 200,
  },
}));

export default function ChapterWrapper(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const module = useSelector((state) => {
    return getModuleByKey(state, props.chapterKey);
  });
  const section = module.chapter.path;
  const children = Children.toArray(props.children);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("Mount chapter: ", section);
    }
    // eslint-disable-next-line no-undef
    fullpage_api.reBuild();
    dispatch(setActiveModule(module.chapter.key));
    return () => {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log("Unmount chapter: ", section);
      }
      // eslint-disable-next-line no-undef
      fullpage_api.destroy("all");
    };
  }, [dispatch, module.chapter.key, section]);

  let anchors = [];

  const slides = children.map((slide) => {
    const { anchor } = slide.props;
    anchors.push(anchor);

    const actions =
      slide.props.actions !== undefined ? slide.props.actions : [];

    const actionItems = actions.map((action, i) => (
      <Grid item key={`action-${i}`}>
        <a href={action.link}>
          <img src={action.imgSrc} />
        </a>
      </Grid>
    ));

    return (
      <Section key={`${section}-${anchor}`} selector={section} {...slide.props}>
        <Box style={{ height: "inherit" }} id="slideWrapper">
          {slide}
          {actionItems.length > 0 && (
            <Grid className={classes.actions} container spacing={2}>
              {actionItems}
            </Grid>
          )}
        </Box>
      </Section>
    );
  });

  if (module.fields.length !== 0) {
    anchors.push("aktionsliste");
  }

  anchors.push("themenwahl");

  return (
    <>
      <ReactFullpage
        licenseKey="82375DBB-66484CC6-A59E8B65-42EC72CD"
        navigation
        navigationPosition={"right"}
        anchors={anchors}
        showActiveTooltip={false}
        slidesNavigation
        slidesNavPosition={"bottom"}
        continuousVertical={false}
        touchSensitivity={5}
        scrollOverflowOptions={{
          click: true,
        }}
        autoScrolling
        verticalCentered={false}
        scrollingSpeed={500}
        css3
        easingcss3={"ease"}
        normalScrollElements=".MuiDialog-root, .MuiDialog-scrollPaper, .MuiAutocomplete-popper, #consultationDialog, .autoScroll, #QSrollBody"
        sectionSelector={`.${section}`}
        render={() => (
          <ReactFullpage.Wrapper>
            {slides}
            {module.fields.length !== 0 && (
              <ActionSection module={module} section={section} />
            )}
            {!props.disableNextChapter && <NextChapter section={section} />}
          </ReactFullpage.Wrapper>
        )}
      />
      {props.logo !== null && <Box className={classes.logo}>{props.logo}</Box>}
    </>
  );
}

ChapterWrapper.defaultProps = {
  anchors: [],
  section: "",
  actions: null,
  normalScrollElements: [],
  logo: null,
};
