import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/9.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/eingang/busch-accesscontrol/busch-access-control.png";

export default function BuschAccessControlOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/busch-accesscontrol/euro-halbzylinder",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-AccessControl" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Mit Busch-AccessControl können Sie komplexe elektronische
                Zutrittsverwaltungen für Gebäude einfach und nutzerfreundlich
                handhaben. Dank dem Smart Access Point lässt sich das System als
                eigenständige Lösung oder Teil einer Gebäudeanlage nutzen. Der
                Zugriff auf das System ist über das myBUSCH-JAEGER Portal per
                Fernzugriff von überall und zu jeder Zeit möglich.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Sicherer schlüsselloser Zutritt",
                  "Individuelle Zutrittskontrolle",
                  "Online-Verwaltungssystem",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
