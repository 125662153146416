import { Grid } from "@material-ui/core";

import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/schalter/balance-si.jpg";

import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import QBodyText from "../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../components/CenteredImage";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

export default function BalanceSI() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/busch-balance-si",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "preisliste",
          children: <Preisliste anchor="busch-balance-si" />,
        },
      ]}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/busch-balance-si.png"
        alt={
          <>
            Busch-balance<sup>®</sup> SI
          </>
        }
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                Busch-balance<sup>®</sup> SI
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QBodyText>
                Bietet durch ihre Form einen spannenden Gleichklang aus geraden
                und konvexen Linien
              </QBodyText>
              <QList
                items={[
                  "Erhältlich in 1-fach bis 5-fach",
                  "Umfangreiches Schalterprogramm für viele Anforderungen",
                  "Passt sich universell an verschiedene Einrichtungsstile an",
                  "Senkrechte oder waagerechte Montage",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-balance-si/sitzungssaal.jpg",
                    alt: (
                      <>
                        future<sup>®</sup> linear Beschriftungsrahmen alpinweiß
                      </>
                    ),
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-balance-si/schuko.jpg",
                    alt: (
                      <>
                        future<sup>®</sup> linear Schuko alpinweiß
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
