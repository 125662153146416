import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Appnext() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/app-next.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/tuerkommunikation/smartphone/app-next.png"
        alt="App next"
        height="50vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Box style={{ paddingRight: "7rem" }}>
              <QBodyText fontWeight="bold" marginBottom="0px">
                Busch-free@home<sup>®</sup> APP next
              </QBodyText>
              <QBodyText>Immer den passenden Blick aufs Heim.</QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Lokaler oder Fernzugriff aufs Eigenheim",
                  "Benachrichtigung bei Störungen über myBusch-Jaeger",
                  "Kostenlose App für Smartphone & Tablet",
                  "Zugriff über Web-Server für die Konfiguration und Bedienung (Browser)",
                ]}
                dense
                variant="arrow"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
