import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import Montage from "../../../../../../_globalSlides/Montage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/33.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/bluetooth-smart-switch/bluetooth-smart-switch.png";
import SmartSwitch1 from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bluetooth-smart-switch/6711-UBT-BT-Relay.jpg";
import SmartSwitch2 from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bluetooth-smart-switch/6715UBT-BT-Dimmer.jpg";

export default function BluetoothSmartSwitchOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/bluetooth-smart-switch",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "info",
          children: <Montage />,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Bluetooth Smart Switch" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Mit dem Bluetooth Smart Switch können Sie Ihre Beleuchtung noch
                smarter fernsteuern. Der Schalter kann, dank "Energy
                Harvesting", kabellos angebracht und betrieben werden. Mit
                Bluetooth Smart Switch können alle Casambi-fähigen Geräte (wie
                auch der BT-Dimmer und das BT-Relais) ferngesteuert werden. Z.B.
                die Nachttischleuchte vom Bett und von der Tür aus.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Belegung der Wippe mit bis zu vier Funktionen",
                  'Batterielos und wartungsfrei durch "Energy Harvesting"',
                  "Eignet sich ideal für die Nachrüstung",
                  "Inbetriebnahme per App",
                  "Maximale Funktionssicherheit durch Mesh-Technologie",
                ]}
              />
              <ImageComponent
                wrapperWidth="80%"
                align="left"
                col={3}
                images={[
                  {
                    src: SmartSwitch1,
                    alt: "6711 UBT BT Relay",
                  },
                  {
                    src: SmartSwitch2,
                    alt: "6715 UBT BT Dimmer",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="Bluetooth Smart Switch"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
