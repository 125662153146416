import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/3.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/8.jpg";
import Image9 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/9.jpg";
import Image10 from "../../../../../../../assets/renovation-und-neubau/funktion/zutrittskontrolle/10.jpg";

const bedienelementItems1 = [
  {
    title: "Fingerprint",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produkt/busch-welcome/busch-welcome-fingerprint-modul",
    },
  },
  {
    title: "Transponder",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produkt/busch-welcome/busch-welcome-transponder-modul",
    },
  },
  {
    title: "Tastatur-Modul",
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produkt/busch-welcome/busch-welcome-tastatur-modul",
    },
  },
  { placeholder: true },
  {
    title: "Zylinderschloss",
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produkt/busch-accesscontrol/euro-halbzylinder",
    },
  },
  {
    title: "Smart AccessPointPro",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=27311&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=7cbc7438b16d5e18527bfb9e9c563273",
    },
  },
  {
    title: "Einbaumodule",
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=3124&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=e99110f90b97559aecc2accd9cb06aad",
    },
  },
  {
    title: "Außenstation Modular",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=3562&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=7f83442cfe1fc5b343c0ac1f96b3d738",
    },
  },
  {
    title: "IP-Gateway zur Verwaltung",
    img: Image8,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=4375&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=0504cf22d04bd9da5fe2b88c486bacdd",
    },
  },
  { placeholder: true },
  {
    title: "Transponder-Schlüssel",
    img: Image9,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=27318&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=5bd590bde18425ad9ad2feb2968a469e",
    },
  },
  {
    title: "RF Repeater",
    img: Image10,
    link: {
      href: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=show&tx_nlbjproducts_catalog%5BcatBjeProdukt%5D=27313&tx_nlbjproducts_catalog%5Bcontroller%5D=CatStdArtikel&cHash=6f5b85f7be50d6af188e81078fc6181a",
    },
  },
];

export default function Zutrittskontrolle() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.42,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-welcome",
          target: "_blank",
        },
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/busch-accesscontrol",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={7}>
              <QHeader title="Zutrittskontrolle" arrow />
              <QBodyText>
                Busch-Jaeger bietet angelehnt an Busch-Welcome<sup>®</sup> zwei
                Varianten der Zutrittssteuerung. Als Integration in
                Busch-Welcome<sup>®</sup> 2-Draht-Außenstationen zur Öffnung der
                jeweiligen Tür oder Als Busch-Access-Control, als ideale
                Anbindung an Busch-Welcome<sup>®</sup> IP.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {bedienelementItems1.map((e, i) => {
                  return (
                    <Grid item xs={2}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
