import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";
import { useModal } from "mui-modal-provider";
import QDialog from "../../../../../../../components/QDialog";
import Button from "@material-ui/core/Button";
import Simulation from "@qmarketing/dimu-simulation";
import "@qmarketing/dimu-simulation/build/index.css";
import QButton from "../../../../../../../components/QButton";

export default function Outdoor2() {
  const { showModal } = useModal();

  const openSimulation = () => {
    const d = showModal(QDialog, {
      children: (
        <Box width="100vw" height="100vh">
          <Simulation type="outdoor" />
        </Box>
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  return (
    <Slide
      background={{
        src: "../../../../../../../assets/smarterhome/background/outdoor-2.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/smarterhome/produkte/outdoor-2.jpg"
        alt="Busch-Welcome® Außenstation Video"
        height="50vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup> IP
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6} style={{ paddingRight: "8rem" }}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Außenstationen
            </QBodyText>
            <QBodyText>
              Die Busch-Welcome® IP Türkommunikation bietet ein System mit
              HD-Videokommunikation, das zur Überwachung von Wohn- und
              Zweckbauten eingesetzt werden kann.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Einfache Montage und Installation – ein Internetanschluss genügt",
                "Hohe Flexibilität bei der Steuerung",
                "Vielseitige Anwendungsfelder",
                "HD-Bild- und Videoübertragung (720p)",
                "Externer Zugang und zukunftssichere Update-Funktion über myBUSCH-JAEGER",
              ]}
              dense
              variant="arrow"
            />
            <QButton variant="contained" onClick={openSimulation}>digital ausprobieren</QButton>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
