import { h } from "preact";
import { Grid } from "@material-ui/core";
import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/schalter/dynasty.jpg";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import CenteredImage from "../../../../../../components/CenteredImage";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatKapitel%5D=5&tx_nlbjproducts_catalog%5Bcontroller%5D=CatDokument&cHash=95a294c5ac98b88d780a0f6f0d17a904",
    target: "_blank",
  },
];

export default function Dynasty() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/busch-dynasty.png"
        alt={
          <>
            Busch-dynasty<sup>®</sup>
          </>
        }
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                Busch-dynasty<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QList
                items={[
                  "Unikat",
                  "Per Hand poliert",
                  "Wippe aus hochwertigem Kunststoff",
                  "Qualität auf höchstem Niveau",
                  "Erhältlich in 1-fach bis 5-fach",
                  "Senkrechte und waagerechte Montage möglich",
                  "Die Farbe und Haptik wertet jeden Wohnraum auf und betont das luxuriöse Ambiente und Geschmack des Besitzers",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/dynasty/schwarz.jpg",
                    alt: (
                      <>
                        Busch-dynasty<sup>®</sup> Lichtschalter | anthrazit
                      </>
                    ),
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/dynasty/gold-akzent.jpg",
                    alt: (
                      <>
                        Busch-dynasty<sup>®</sup> Schuko | elfenbeinweiß /
                        Messing poliert
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
