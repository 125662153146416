import SchalterSeitlich from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/nachhaltigkeit/schalter-seitlich.png";
import Landschaft from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/nachhaltigkeit/landschaft.png";
import Eight from "../../../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/busch-art-linear/nachhaltigkeit/eight.png";
import { Box } from "@material-ui/core";

export default function NachhaltigkeitSVG() {
  return (
    <Box padding={2} sx={{ height: "calc(100vh - 16px * 2)", width: "100%" }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 2597 1285"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g clip-path="url(#clip0_30_209)">
          <rect width="2597" height="1285" fill="white" />
          <path
            d="M191 0H2395V1270C2395 1278.28 2388.28 1285 2380 1285H206C197.716 1285 191 1278.28 191 1270V0Z"
            fill="#E1EBDB"
          />
          <mask
            id="mask0_30_209xxx"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="1574"
            y="65"
            width="1023"
            height="624"
          >
            <rect
              x="1574"
              y="65"
              width="1023"
              height="624"
              rx="15"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_30_209xxx)">
            <rect
              x="1537"
              y="-27"
              width="1153"
              height="716"
              fill="url(#pattern9)"
            />
          </g>
          <text
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="22"
            letter-spacing="0em"
          >
            <tspan x="29" y="878.263">
              Wenn Rohstoffe als Abfall enden, werden
            </tspan>
            <tspan x="29" y="906.263">
              Ressourcen verschwendet. Busch-Jaeger hat
            </tspan>
            <tspan x="29" y="934.263">
              verstanden, dass die Rückgewinnung von wertvollen
            </tspan>
            <tspan x="29" y="962.263">
              Stoffen die Lösung darstellt. Echtes Recycling, kein
            </tspan>
            <tspan x="29" y="990.263">
              Downcycling, erlaubt eine nachhaltige Produktion,
            </tspan>
            <tspan x="29" y="1018.26">
              die auch den Ansprüchen folgender Generationen
            </tspan>
            <tspan x="29" y="1046.26">
              genügt. Die Busch-art linear Serie besteht aus
            </tspan>
            <tspan x="29" y="1074.26">
              recycelten und recyclebaren Materialien. Das klingt
            </tspan>
            <tspan x="29" y="1102.26">
              unspektakulär, ist aber eine große Sache im
            </tspan>
            <tspan x="29" y="1130.26">
              nachhaltigen Design für hochwertige Produkte.
            </tspan>
          </text>
          <text
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="22"
            letter-spacing="0em"
          >
            <tspan x="795" y="170.263">
              Anteil an Rezyklat für den
            </tspan>
            <tspan x="795" y="198.263">
              schwarzen Schalter,
            </tspan>
            <tspan x="795" y="226.263">
              92 % für den weißen Schalter
            </tspan>
          </text>
          <text
            transform="matrix(0 -1 1 0 1523 357)"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="15"
            letter-spacing="-0.035em"
          >
            <tspan x="0" y="13.77">
              Material aus alten CSs
            </tspan>
          </text>
          <text
            fill="#009EE2"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="25"
            letter-spacing="0.28em"
          >
            <tspan x="30" y="795.117">
              VOM WISSEN
            </tspan>
            <tspan x="30" y="823.117">
              ZUM HANDELN
            </tspan>
          </text>
          <text
            fill="#009EE2"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Bold"
            font-size="473"
            letter-spacing="-0.02em"
          >
            <tspan x="0.200714" y="479.455">
              98
            </tspan>
          </text>
          <text
            transform="translate(542.035 106.137) rotate(8)"
            fill="#009EE2"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Bold"
            font-size="190"
            letter-spacing="-0.02em"
          >
            <tspan x="0.144531" y="174.809">
              %
            </tspan>
          </text>
          <rect x="705" y="160" width="69" height="3" fill="#009EE2" />
          <rect x="1530" y="372" width="3" height="136" fill="#009EE2" />
          <rect x="1940" y="753" width="175" height="3" fill="#009EE2" />
          <text
            transform="translate(2131 748)"
            fill="black"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="15"
            letter-spacing="-0.035em"
          >
            <tspan x="0" y="13.77">
              Alte Scheinwerfer
            </tspan>
          </text>
          <rect
            x="463"
            y="282"
            width="1558"
            height="751"
            fill="url(#pattern1)"
          />
          <rect
            x="742"
            y="1082"
            width="1121"
            height="203"
            fill="url(#pattern2)"
          />
          <text
            fill="white"
            xmlSpace="preserve"
            style="white-space: pre"
            font-family="Averta-Regular"
            font-size="15"
            letter-spacing="-0.035em"
          >
            <tspan x="2322" y="514.77">
              übrigens: Busch Jaeger war 2019
            </tspan>
            <tspan x="2322" y="531.77">
              weltweit das erste Unternehmen,
            </tspan>
            <tspan x="2322" y="548.77">
              das ein Cradle-to-Cradle-Zertifikat
            </tspan>
            <tspan x="2322" y="565.77">
              erhalten hat - ebenfalls für ein
            </tspan>
            <tspan x="2322" y="582.77">
              Schalterdesign (future linear<sup>®</sup>).
            </tspan>
          </text>
        </g>
        <defs>
          <pattern
            id="pattern9"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_30_209"
              transform="matrix(0.000401156 0 0 0.000645995 -0.0215022 0)"
            />
          </pattern>
          <pattern
            id="pattern1"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image1_30_209"
              transform="scale(0.000266756 0.000553403)"
            />
          </pattern>
          <pattern
            id="pattern2"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image2_30_209"
              transform="matrix(0.00034493 0 0 0.00190476 -0.000666072 0)"
            />
          </pattern>
          <clipPath id="clip0_30_209">
            <rect width="2597" height="1285" fill="white" />
          </clipPath>
          <image
            id="image0_30_209"
            width="2600"
            height="1548"
            xlinkHref={Landschaft}
          />
          <image
            id="image1_30_209"
            width="3750"
            height="1807"
            xlinkHref={Eight}
          />
          <image
            id="image2_30_209"
            width="2903"
            height="525"
            xlinkHref={SchalterSeitlich}
          />
        </defs>
      </svg>
    </Box>
  );
}
