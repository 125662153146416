import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Gateway() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/smarterhome/background/gateway.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/smarterhome/produkte/indoorUndGateway.jpg"
        alt="Busch-Welcome Innenstation"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Gateway
            </QBodyText>
            <QBodyText>
              Gut bewährt – die Anbindung der Türkommunikation an das
              Festnetztelefon oder die TK-Anlage.
            </QBodyText>
            <Box style={{ paddingRight: "120px" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Telefon erfolgt über den analogen Anschluss und wird zur Innenstation",
                  "Optimaler Einsatz durch kleine Bauform ",
                  "Über TK-Anlage Nutzung mit allen anderen Anschlüssen möglich",
                  "Komfortable Konfiguration über die Weboberfläche des IP-Gateways",
                  "Einfache Inbetriebnahme",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: (
                      <>
                        Telefon Gateway Busch-Welcome<sup>®</sup>
                      </>
                    ),
                    src: "../../../../../../../assets/smarterhome/produkte/gateway-left.jpg",
                  },
                  {
                    alt: (
                      <>
                        Busch-Welcome<sup>®</sup> IP-Gateway
                      </>
                    ),
                    src: "../../../../../../../assets/smarterhome/produkte/gateway-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
