import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/43.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/Kontrollschalter16A/kontrollschalter-rot.jpg";
import KabelImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/Kontrollschalter16A/kontrollschalter-kabel.jpg";
import EinsatzImg from "../../../../../../../../../assets/renovation-und-neubau/raume/arbeitszimmer/Kontrollschalter16A/kontrollschalter-einsatz.png";

export default function Kontrollschalter16AOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg, align: "left" }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/lichtschalter",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Kontrollschalter 16A" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Besonders im Arbeitszimmer wird viel Energie im Stand-by
                verbraucht. Abhilfe schaffen schaltbare Steckdosen die über
                leistungsfähige 16A-Einsätze, die hohen Einschaltströme der
                Netzteile verkraften, vom Netzt genommen werden. 2-polige
                Schalter schützen zudem vor Überspannung aus dem Netz.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Optionale eindeutige Kennzeichnung durch Symbolwippen",
                  "Optional als Kontrollschalter zur Statusanzeige",
                ]}
              />
              <ImageComponent
                wrapperWidth="80%"
                align="left"
                col={4}
                images={[
                  {
                    src: KabelImg,
                    alt: "Kontrollschalter 16A",
                  },
                  {
                    src: EinsatzImg,
                    alt: "Einsatz",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="35vh"
                src={CenteredImg}
                alt="SCHUKO® Steckdose Safety+"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
