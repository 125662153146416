import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";

export default function Mediensteuerung() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/mediensteuerung.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "left",
      }}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Mediensteuerung
            </QBodyText>
            <QBodyText>
              Was ist eine Szene ohne die entsprechende Musik? Mit Sonos können
              Playlisten oder Lieblingssender zu Szenen hinzugefügt werden.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Einbindung von Musik in Szenen",
                "Steuern der Lautstärke und Titel von Tastsensor aus",
                "Anzeigen von Titel und Playlisten am Display",
              ]}
              dense
              variant="arrow"
            />
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Mediensteuerung",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/mediensteuerung-left.jpg",
                  },
                  {
                    alt: "Mediensteuerung",
                    src: "../../../../../../../assets/tuerkommunikation/produkte/mediensteuerung-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
