import * as React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  QButton: {
    background: "#009fe3",
    color: "#fff",
    border: "1px solid #009fe3",
    borderColor: "#009fe3",
    borderRadius: "0px",
    boxShadow: "none",
    "&:hover, &:focus": {
      backgroundColor: "#fff",
      color: "#009fe3",
      border: "1px solid #009fe3",
      boxShadow: "none",
      borderRadius: "0px",
    },
  },
}));

export default function QButton() {
  const classes = useStyles();
  return (
    <Button className={classes.QButton} variant={this.props.variant} onClick={this.props.onClick}>
      {this.props.children}
    </Button>
  );
}
