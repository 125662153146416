import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import QBodyText from "../QBodyText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
  root: {
    background: "transparent",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 36,
    color: "var(--grey-50)",
    paddingLeft: "0px",
    flexDirection: "row-reverse",
    "&$expanded": {
      minHeight: 36,
      color: "var(--grey-100)",
    },
  },
  content: {
    margin: "0",
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
  expandIcon: { marginRight: "0px", marginLeft: "-12px", padding: "0 12px" },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: "0px",
    paddingLeft: "15px",
  },
})(MuiAccordionDetails);

export default function QAccordion({ items, fontSize }) {
  const [collapseState, setCollapseState] = useState("");

  const handleCollapseState = (e) => {
    const id = e.currentTarget.id;
    setCollapseState(id);
  };

  useEffect(() => {
    if (items.length > 0) {
      setCollapseState(items[0].title);
    }
  }, []);

  return (
    <div>
      {items.map((item) => {
        return (
          <Accordion
            square
            expanded={collapseState === item.title}
            onChange={handleCollapseState}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.title}-content`}
              id={item.title}
            >
              <QBodyText
                fontSize={fontSize}
                fontWeight="bold"
                marginBottom="0px"
              >
                {item.title}
              </QBodyText>
            </AccordionSummary>
            <AccordionDetails>{item.content}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
