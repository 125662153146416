import Uebersicht from "./slides/Uebersicht";
import BuschDimmer from "./slides/busch-dimmer";
import Radio from "./slides/radio";
import Steckdosen from "./slides/steckdosen";
import USBLaden from "./slides/usb-laden";
import Jalousiemanagement from "./slides/jalousiemanagement";
import Raumklima from "./slides/raumklima";
import AutomatischesLicht from "./slides/automatisches-licht";
import Rauchwarnmelder from "./slides/rauchwarnmelder";
import LEDLicht from "./slides/led-licht";

export default function DasSystem() {
  return [
    <Uebersicht anchor="uebersicht" />,
    <Radio anchor="radio" />,
    <USBLaden anchor="usb-laden" />,
    <Steckdosen anchor="steckdosen" />,
    <BuschDimmer anchor="busch-dimmer" />,
    <Jalousiemanagement anchor="jalousiemanagement" />,
    <Raumklima anchor="raumklima" />,
    <AutomatischesLicht anchor="automatisches-licht" />,
    <Rauchwarnmelder anchor="rauchwarnmelder" />,
    <LEDLicht anchor="led-licht" />,
  ];
}
