/* eslint-disable */
export function sectionStart(e) {
    fullpage_api.moveTo(fullpage_api.getActiveSection().anchor, 0);
  }
  
  export function moveTo(section, slide) {
    fullpage_api.moveTo(section, slide);
  }
  
  export function silentMoveTo(section, slide) {
    fullpage_api.silentMoveTo(section, slide);
  }
  
  export function reBuild() {
    fullpage_api.reBuild();
  }
  
  export function moveToDirection(direction) {
    switch (direction.toLowerCase()) {
      case "up":
        fullpage_api.moveSectionUp();
        break;
      case "down":
        fullpage_api.moveSectionDown();
        break;
      case "right":
        fullpage_api.moveSlideRight();
        break;
      case "left":
        fullpage_api.moveSlideLeft();
        break;
  
      default:
        break;
    }
  }
  
  export function setAllowScrolling(p) {
    if(typeof fullpage_api !== "undefined")
      fullpage_api.setAllowScrolling(p);
  }
  