import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/51.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/7.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/8.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/9.jpg";
import Image9 from "../../../../../../../assets/renovation-und-neubau/funktion/bewegungsmelder-praesenzmelder/10.jpg";

const items = [
  {
    title: "Näherungsschalter",
    img: Image1,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/1",
    },
  },
  {
    title: "Komfortschalter",
    img: Image2,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/2",
    },
  },
  {
    title: (
      <>
        Busch-Wächter<sup>®</sup> 180 flex
      </>
    ),
    img: Image3,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/4",
    },
  },
  {
    title: (
      <>
        Busch-Wächter<sup>®</sup> 180 Komfort
      </>
    ),
    img: Image4,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/5",
    },
  },
  {
    title: (
      <>
        Busch-Wächter<sup>®</sup> 180 Komfort Multi
      </>
    ),
    img: Image5,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/6",
    },
  },
  { placeholder: true },
  { placeholder: true },
  { placeholder: true },
  {
    title: "Universal",
    img: Image6,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/9",
    },
  },
  {
    title: "Corridor",
    img: Image7,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/9",
    },
  },
  {
    title: "Sky",
    img: Image8,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/9",
    },
  },
  {
    title: "DALI",
    img: Image9,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/beruehrungsloses-schalten#anwendung-innen/10",
    },
  },
];

export default function BewegungsmelderIndoor() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/bewegungsmelder",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Bewegungsmelder
                <br />
                Präsenzmelder
              </>
            }
            arrow
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Automatisches Licht bietet neben mehr Sicherheit durch gut
                ausgeleuchtete Wege, auch mehr Komfort und Effizienz.
                Bewegungsmelder im Schalterdesign lassen sich schnell und
                einfach nachrüsten.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={2}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
