import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Indoor1() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/smarterhome/background/indoor-1.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/smarterhome/produkte/indoorUndGateway.jpg"
        alt="Busch Jaeger Innenstation"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Innenstationen
            </QBodyText>
            <QBodyText>
              Ein breites Portfolio bietet maximale Flexibilität für jede Art
              von Immobilie.
            </QBodyText>
            <Box style={{ paddingRight: "120px" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Intuitive Bedienung",
                  "Aufputz- oder Unterputzmontage",
                  "Umfassende Video- und Audiofunktionalitäten",
                  "Klarer Klang, funktionales Design",
                  "Passend zu vielen Busch-Jaeger Schalterserien",
                  "Bewährte 2-Draht-Technik",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={3}
                align="left"
                images={[
                  {
                    alt: "Busch-Jaeger Innenstation Video",
                    src: "../../../../../../../assets/smarterhome/produkte/indoor-left.jpg",
                  },
                  {
                    alt: "Busch-Jaeger Innenstation Audio",
                    src: "../../../../../../../assets/smarterhome/produkte/indoor-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
