import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/25.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/BuschCompactTimer/busch-compact-timer.png";

export default function BuschCompactTimerOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-compact-timer",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title='Busch-Compact-Timer' />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Einfachste Bedienung steht hier im Vordergrund. Der
                Busch-Compact-Timer ermöglicht die intuitive Handhabung Ihrer
                Rollläden, sowohl bei der Steuerung als auch bei der
                Programmierung.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Geeignet für Rollladen- und Jalousieantriebe mit mechanischer oder elektronischer Endlagenabschaltung",
                  "Großes Display mit Hintergrundbeleuchtung'",
                  "Memoryfunktion für individuelle Schaltzeiten mit netzausfallsicherer Speicherung",
                  "Automatische Sommer-/Winterzeitumstellung",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="Dreh-/Tastdimmer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
