import { h } from "preact";
import Slide from "../../../../../components/Slide/index";
import { Grid } from "@material-ui/core";
import QHeader from "../../../../../components/QHeader";
import QBoxList from "../../../../../components/QBoxList";
import ChapterCard from "../../../../../components/ChapterCard";

// Images
import PDF1 from "../../../../../assets/renovation-und-neubau/unterstuetzung/1.jpg";
import PDF2 from "../../../../../assets/renovation-und-neubau/unterstuetzung/2.jpg";
import PDF3 from "../../../../../assets/renovation-und-neubau/unterstuetzung/3.jpg";
import PDF4 from "../../../../../assets/renovation-und-neubau/unterstuetzung/4.jpg";
import PDF5 from "../../../../../assets/renovation-und-neubau/unterstuetzung/5.jpg";
import PDF6 from "../../../../../assets/renovation-und-neubau/unterstuetzung/6.jpg";
import PDF7 from "../../../../../assets/renovation-und-neubau/unterstuetzung/7.jpg";
import PDF8 from "../../../../../assets/renovation-und-neubau/unterstuetzung/8.jpg";

const items = [
  {
    title: (
      <>
        Virtuelle Ausstellung
        <br />
        Türkommunikation
      </>
    ),
    img: PDF1,
    link: { href: "https://www.busch-jaeger.de/produkterlebnis" },
  },
  {
    title: "Busch-Baustellenplaner",
    img: PDF2,
    link: { href: "https://baustellenplaner.busch-jaeger.de" },
  },
  {
    title: "Smart Home Konfigurator",
    img: PDF3,
    link: { href: "https://smart-home-configurator.my.busch-jaeger.de" },
  },
  {
    title: "Busch-Jaeger Community",
    img: PDF4,
    link: { href: "https://community.busch-jaeger.de" },
  },
  {
    title: "Stromkompass",
    img: PDF5,
    link: { href: "https://stromkompass.de" },
  },
  {
    title: "Lichtschalter 3D LiveView",
    img: PDF6,
    link: {
      href: "https://apps.apple.com/de/app/3d-liveview/id1410280377",
    },
  },
  {
    title: "Smart Home Explorer",
    img: PDF7,
    link: {
      href: "https://apps.apple.com/de/app/smart-home-explorer/id1160341481",
    },
  },
  {
    title: "Smarter Gallery",
    img: PDF8,
    link: {
      href: "https://playbook.lightsail-aws.qmarketing.de/smarter-gallery",
    },
  },
];

export default function Marketing() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-Jaeger/ABB" subTitle="Unterstützung" />
        </Grid>
        <Grid item>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={11}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
