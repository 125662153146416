import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Wohnungsbau() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/smarterhome/background/wohnungsbau.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/smarterhome/produkte/wohnungsbau.jpg"
        alt="Busch-Welcome® Außenstation Video"
        height="50vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Welcome<sup>®</sup> IP
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6} style={{ paddingRight: "100px" }}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Wohnungsbau
            </QBodyText>
            <QBodyText>
              Busch-Welcome® IP setzt neue Standards bei Flexibilität,
              Skalierbarkeit und Funktionalität. So ist z. B. Entfernung nur
              noch eine Frage der IP-Konnektivität.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Grenzenlose Optionen bei der Konfiguration",
                "Netzwerkbasierte IP-Technik ermöglicht eine exzellente Bildqualität",
                "Vielseitige Anwendungsfelder",
                "Fernzugriffsfunktionalität",
                "Rostfrei, wasser- (IP54) und vandalismusgeschützt (IK 07)",
                "Hohe Flexibilität bei der Steuerung",
                "Modulare Lösungen",
              ]}
              dense
              variant="arrow"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
