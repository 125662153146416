import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/38.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/bad/busch-waechter/busch-waechter-180-flex.png";
import FanschalterBraunschweig from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bundesliga-fanschalter/bundesliga-fanschalter-braunschweig.jpg";
import FanschalterBVB from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bundesliga-fanschalter/bundesliga-fanschalter-bvb.jpg";
import FanschalterFCBayern from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bundesliga-fanschalter/bundesliga-fanschalter-fc-bayern.jpg";
import FanschalterKoeln from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bundesliga-fanschalter/bundesliga-fanschalter-fc-koeln.jpg";
import FanschalterFrankfurt from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bundesliga-fanschalter/bundesliga-fanschalter-frankfurt.jpg";
import FanschalterGladbach from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bundesliga-fanschalter/bundesliga-fanschalter-moenchengladbach.jpg";
import FanschalterSchalke from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/bundesliga-fanschalter/bundesliga-fanschalter-schalke.jpg";

export default function BundesligaFanschalterOverlay() {
  return (
    <Slide
      background={{ src: BackgroundImg, align: "left" }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/busch-axcent/bundesliga-fanschalter",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Bundesliga Fanschalter" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Bekennen Sie Farbe. Auch zu Hause. Mit den Bundesliga
                Fanschaltern aus unserer Lichtschalterserie Busch-axcent® holen
                Sie sich Ihren Lieblingsverein direkt nach Hause.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Fanschalter von div. Bundesliga-Vereinen",
                  <>
                    Kombinier-/Erweiterbar mit der Schalterserie Busch-axcent
                    <sup>®</sup>
                  </>,
                ]}
              />
              <ImageComponent
                wrapperWidth="80%"
                align="left"
                col={3}
                images={[
                  {
                    src: FanschalterBraunschweig,
                    alt: "Bundesliga Fanschalter: Eintracht Braunschweig",
                  },
                  {
                    src: FanschalterBVB,
                    alt: "Bundesliga Fanschalter: Borussia Dortmund",
                  },
                  {
                    src: FanschalterFCBayern,
                    alt: "Bundesliga Fanschalter: FC Bayern München",
                  },
                  {
                    src: FanschalterKoeln,
                    alt: "Bundesliga Fanschalter: 1. FC Köln",
                  },
                  {
                    src: FanschalterFrankfurt,
                    alt: "Bundesliga Fanschalter: Eintracht Frankfurt",
                  },
                  {
                    src: FanschalterGladbach,
                    alt: "Bundesliga Fanschalter: Borussia Mönchengladbach",
                  },
                  {
                    src: FanschalterSchalke,
                    alt: "Bundesliga Fanschalter: FC Schalke 04",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                shadow
                wrapperHeight="30vh"
                src={FanschalterFCBayern}
                alt="Bundesliga Fanschalter: FC Bayern München"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
