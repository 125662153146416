import { cloneElement } from "preact/compat";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import root from "window-or-global";

function ElevationScroll({ children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: root,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default ElevationScroll;
