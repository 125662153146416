import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/27.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/busch-co-alarm/busch-co-alarm.png";

export default function COMelderOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/rauchmelder/busch-co-alarm",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://youtu.be/N0PaKOUpNJA",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Busch-CO Alarm" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Der Busch-CO Alarm ist ein Kohlenmonoxidwarnmelder mit
                elektrochemischem Sensor. Kohlenmonoxid (CO) ist ein nicht
                wahrnehmbares, farb-, geruch- und geschmackloses, hochgiftiges
                Gas. CO entsteht unter anderem durch die unvollständige
                Verbrennung kohlenstoffhaltiger Stoffe bei unzureichender
                Sauerstoffzufuhr.
              </QBodyText>
              <QList
                headline="Einsatzort"
                items={[
                  "Kaminöfen",
                  "Heizungsräume",
                  "Garage",
                  "Räume durch die Abgas-Kamine führen.",
                ]}
              />
              <QList
                headline="Vorteile"
                items={[
                  "Stand Alone",
                  "Vernetzung mit Rauchwarn-/Wärmemelder",
                  <>
                    Anbindung an Busch-free@home<sup>®</sup> möglich
                  </>,
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="45vh"
                align="center"
                src={CenteredImg}
                alt="Dreh-/Tastdimmer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
