import { h } from "preact";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextareaAutosize, makeStyles } from "@material-ui/core";
import {
  getGeneralNotes,
  setGeneralNotes,
} from "../../../redux/modules/consulting";
import QActionPaper from "../../QActionPaper";

const useStyles = makeStyles(() => ({
  textAreaStyling: {
    minWidth: "100%",
    border: "none",
    fontFamily: "Averta-Regular",
  },
}));

export default function Notes() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notes = useSelector((state) => getGeneralNotes(state));

  const handleFieldChange = (e) => {
    dispatch(setGeneralNotes(e.currentTarget.value));
  };
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <QActionPaper title="Notizen">
          <TextareaAutosize
            value={notes}
            className={classes.textAreaStyling}
            onChange={handleFieldChange}
            placeholder="Ihre Notizen ..."
            rowsMin={12}
            aria-label="notizen"
          />
        </QActionPaper>
      </Grid>
    </Grid>
  );
}
