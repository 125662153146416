import { Grid } from "@material-ui/core";

import background from "../../../../../../assets/cwa/background/titelbilder/schalter/pur-edelstahl.jpg";
import backgroundGrohe from "../../../../../../assets/cwa/background/titelbilder/grohe.jpg";

import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import QBodyText from "../../../../../../components/QBodyText";
import CenteredImage from "../../../../../../components/CenteredImage";
import QAccordion from "../../../../../../components/QAccordion";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

const itemlist = [
  {
    title: "Beschreibung",
    content: (
      <QBodyText>
        Harmonisch aufeinander abgestimmte Designs für eine hochwertige
        Innenausstattung in Bad und Küche: In Zusammenarbeit mit dem
        Premium-Hersteller GROHE offerieren wir im pur edelstahl
        Schalterprogramm zwei einzigartige GROHE Oberflächen, mit denen wir
        unser Schalter-Design im Stil der GROHE Armaturen veredeln – für ein
        neues Maß an Exklusivität in Bad und Küche. Wählen Sie zwischen
        gebürstetem oder poliertem Oberflächen-Finish sowie attraktiven
        Farboptionen
      </QBodyText>
    ),
  },
  {
    title: "Oberflächen",
    content: (
      <QList
        items={[
          "Poliert: Hard Graphite, warm Sunset, Cool Sunrise, Polished Nickel, Chrom",
          "Gebürstet: Brushed Hard Graphite, Brushed Warm Sunset, Brushed Cool Sunrise, Brushed Nickel, SuperSteel",
        ]}
        dense
      />
    ),
  },
];

export default function PurEdelstahl() {
  return (
    <Slide
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/pur-edelstahl",
          target: "_blank",
        },
        {
          type: "dialog",
          img: "preisliste",
          children: <Preisliste anchor="pur-edelstahl" />,
        },
      ]}
      background={{
        src: background,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/pur-edelstahl.png"
        alt="pur edelstahl"
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Lichtschalter" subTitle="pur edelstahl" />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QList
                items={[
                  "Echtmaterial",
                  "Antifingerprint",
                  "Stilsicher und prägnante Form",
                  "Passt perfekt zu einer modernen Innenarchitektur",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/pur-edelstahl/schalter-do-not-disturb.jpg",
                    alt: "pur edelstahl Lichtschalter 'do not disturb'",
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/pur-edelstahl/schuko.jpg",
                    alt: "pur edelstahl Schuko",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
