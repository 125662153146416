import { h } from "preact";
import { Grid } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";

export default function Marke() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/tuerkommunikation/background/marke.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Marke
            </QBodyText>
            <QBodyText>
              Bei der Anschaffung eines Smart-Home-Sytems sollte man sich die
              Frage stellen, wo das System her kommt, wo z. B. Kundendaten
              bleiben, wie viel Erfahrung der Hersteller damit hat und wie
              zukunftssicher das System in Bezug auf Ersatzteile und Ausbau
              sind.
            </QBodyText>
            <QList
              headline="Vorteile"
              items={[
                "Marktführer in Deutschland",
                "Made in Germany",
                "Bustechnik seit der ersten Minute",
                "zukunftssicheres System in Bezug auf Ersatzteile und Ausbau",
                <>
                  CO<sub>2</sub>-Neutral
                </>,
              ]}
              dense
              variant="arrow"
            />
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
