import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/8.jpg";
import BuschWaechter from "../../../../../../../../assets/renovation-und-neubau/raume/bad/busch-waechter-180-flex-komfort.jpg";
import Raumtemperaturrefelung from "../../../../../../../../assets/renovation-und-neubau/raume/bad/raumtemperatur-regelung.jpg";
import BuschRadioDABPlus from "../../../../../../../../assets/renovation-und-neubau/raume/bad/busch-radio-dab-plus.jpg";
import Deckenlautsprecher from "../../../../../../../../assets/renovation-und-neubau/raume/bad/deckenlautsprecher.jpg";
import BuschRaumluftsensor from "../../../../../../../../assets/renovation-und-neubau/raume/bad/busch-raumluftsensor.jpg";
import FISchukomat from "../../../../../../../../assets/renovation-und-neubau/raume/bad/fi-schukomat.jpg";
import BuschWaechterOverlay from "./slides/Busch-Waechter";
import BuschRaumtemperaturreglerOverlay from "./slides/Raumtemperatur-Regelung";
import BuschRadioDABPlusOverlay from "./slides/Busch-Radio-SAB-Plus";
import DeckenLautsprecherOverlay from "./slides/Deckenlautsprecher";
import BuschRaumluftsensorOverlay from "./slides/Busch-Ramluftsensor";
import FISchukomatOverlay from "./slides/FI-Schukomat";

const items = [
  {
    title: "Busch-Wächter® 180 flex Komfort",
    img: BuschWaechter,
    menuOverlay: <BuschWaechterOverlay />,
  },
  {
    title: "Raumtemperatur-Regelung",
    img: Raumtemperaturrefelung,
    menuOverlay: <BuschRaumtemperaturreglerOverlay />,
  },
  {
    title: "Busch-Radio DAB+",
    img: BuschRadioDABPlus,
    menuOverlay: <BuschRadioDABPlusOverlay />,
  },
  {
    title: "Deckenlautsprecher",
    img: Deckenlautsprecher,
    menuOverlay: <DeckenLautsprecherOverlay />,
  },
  {
    title: "Busch-Raumluftsensor®",
    img: BuschRaumluftsensor,
    menuOverlay: <BuschRaumluftsensorOverlay />,
  },
  {
    title: "FI-Schukomat",
    img: FISchukomat,
    menuOverlay: <FISchukomatOverlay />,
  },
];

export default function Bad() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
        width: 0.45,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Bad" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Gönnen Sie sich ein angenehmes Bad, das Sie mit allen Sinnen
                genießen. Entspannen Sie sich. Machen Sie sich bereit für eine
                beruhigende Atmosphäre aus Wasser, Wärme, Licht und Ton.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
