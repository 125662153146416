import { h } from "preact";
import Slide from "../../../../../components/Slide/index";
import { Grid } from "@material-ui/core";
import QHeader from "../../../../../components/QHeader";
import QBoxList from "../../../../../components/QBoxList";
import ChapterCard from "../../../../../components/ChapterCard";

// Images
import PDF1 from "../../../../../assets/renovation-und-neubau/marketing-unterstuetzung/1.jpg";
import PDF2 from "../../../../../assets/renovation-und-neubau/marketing-unterstuetzung/2.jpg";
import PDF3 from "../../../../../assets/renovation-und-neubau/marketing-unterstuetzung/3.jpg";
import PDF4 from "../../../../../assets/renovation-und-neubau/marketing-unterstuetzung/4.jpg";
import PDF5 from "../../../../../assets/renovation-und-neubau/marketing-unterstuetzung/5.jpg";
import PDF6 from "../../../../../assets/renovation-und-neubau/marketing-unterstuetzung/6.jpg";

const items = [
  {
    title: "Smarter Home für mehr Wohnspaß",
    img: PDF1,
    link: {
      href: "https://www.flipedia.com/de/5119",
    },
  },
  {
    title: "Lebensräume frei gestalten",
    img: PDF2,
    link: {
      href: "https://www.flipedia.com/de/5004",
    },
  },
  {
    title: "Lichtschalter",
    img: PDF3,
    link: {
      href: "https://www.flipedia.com/de/4981",
    },
  },
  {
    title: "System Busch-free@home® flex",
    img: PDF4,
    link: {
      href: "https://www.flipedia.com/de/5095",
    },
  },
  {
    title: "innomag",
    img: PDF5,
    link: {
      href: "https://www.busch-jaeger.de/media/download/Broschueren/de/bje/1866_BJE_Innomag_2022.pdf",
    },
  },
  {
    title: "Lösungsbroschüre Barrierefreie Elektrotechnik",
    img: PDF6,
    link: {
      href: "https://www.flipedia.com/de/5054",
    },
  },
];

export default function Marketing() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Busch-Jaeger/ABB"
            subTitle="Marketing-Unterstützung"
          />
        </Grid>
        <Grid item>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
