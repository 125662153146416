import { h } from "preact";
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
} from "@material-ui/core";
import LogoIcon from "../../assets/Logo.svg";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    opacity: "100%",
    backgroundColor: "white",
  },
}));

export default function LoadingScreen() {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <Grid
        container
        direction="column"
        spacing={2}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <img src={LogoIcon} />
        </Grid>
        <Grid item xs={12}>
          <CircularProgress color="black" />
        </Grid>
      </Grid>
    </Backdrop>
  );
}
