import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/50.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garage/mistral-stromkreisverteiler/mistral-stromkreisverteiler.png";

export default function VerteilerMistralOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://new.abb.com/low-voltage/de/produkte/mistral65at",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://www.youtube.com/watch?v=5qR8vBJocAA&feature=youtu.be",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Mistral Stromkreisverteiler" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Viel Platz, montagefreundlich und optisch ansprechend. Die
                schutzisolierten Aufputzgehäuse aus Thermoplast sind vollständig
                recycelbar und bieten viel zusätzlichen Raum für die
                Installationsgeräte. Z.B. Sicherungen, FI-Schutzschalter und
                Aktoren für Busch-free@home® oder auch die Netzwerk und
                Sat-Technik. Für den Hobbybereich oder auch den Garten.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "hohe Schutzart IP65",
                  "flexible N/PE Quick-Steckklemmen",
                  "4 bis 72 PLE",
                  "Stromkreisverteiler",
                  "Medienverteiler",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="60vh"
                col={12}
                src={CenteredImg}
                alt="System pro E comfort – Mistral65AT"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
