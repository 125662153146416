import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/23.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/video-innenstation/video-innenstation.png";
import Overlay from "../../../../../../../../../assets/renovation-und-neubau/raume/diele/busch-welcome/overlay.jpg";

export default function VideoInnenstationOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/busch-welcome/innenstation-video-43-wlan",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://youtu.be/dWpPPOF2FQA",
          target: "_blank",
        },
        {
          type: "galery",
          img: "info",
          images: [
            {
              src: Overlay,
              alt: "Kommunikation mit Smartphone und Tablet",
            },
          ],
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title='Video Innenstation 4.3" WLAN' />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Die Video Innenstation 4,3" verbindet die Funktionalität einer
                Video-Innensprechstelle mit eine Anbindung von Tablet oder
                Smartphone per App.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Intuitive, minimalistische Benutzeroberfläche",
                  "Display mit Glasoberfläche",
                  "Integrierte Induktionsschleife für hörgeschädigte Personen",
                  "Darstellung des Videobildes der Außenstation",
                  "Schnellzugriffstasten für Türöffnen, Stummschalten und Überwachung.",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                previewImgMaxHeight="30vh"
                align="center"
                src={CenteredImg}
                alt="Dreh-/Tastdimmer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
