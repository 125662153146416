import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../../../components/CenteredImage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/24.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/schlafen/Luftguetesensor/luftguetesensor.png";

export default function LuftguetesensorOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-raumluftsensor-co2",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Raumluftsensor® CO<sub>2</sub>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Der CO<sub>2</sub> Sensor misst Qualität und Feuchtigkeit der
                Luft und regelt die Frischluftzufuhr. Er schaltet automatisch
                die Lüftung in ein oder zwei Stufen ein oder öffnet das Fenster,
                sobald einer der Werte überschritten ist. Das Gerät entspricht
                der EnEV für Wohngebäude.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  <>
                    CO<sub>2</sub>- und Luftfeuchtesensor mit Displayanzeige
                  </>,
                  "Zusätzliche Anzeige der Temperatur und des Luftdruckes",
                  "Ansteuerung für Fensteröffner oder Lüfter (Zwei Stufen)",
                  <>
                    Einstellbare Nachlaufzeit des CO<sub>2</sub> Melders
                  </>,
                  "Wahlweise Automatik oder Handbetrieb",
                  "Warnung über Farbwechsel im Display",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Rauchwarnmelder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
