import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../../../components/CenteredImage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/32.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/schlafen/schuko-steckdose-steplight/schuko-steckdose-steplight.png";

export default function SchukoSteckdoseSteplightOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-steplight",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://youtu.be/dWpPPOF2FQA",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-steplight<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Zwei in einem: Busch-steplight® ist ein SCHUKO<sup>®</sup>
                Steckdosen-Einsatz, der über ein integriertes Orientierungslicht
                verfügt. Die LEDs ermöglichen in Räumen, Fluren oder
                Treppenhäusern eine gute Orientierung bei Dunkelheit.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Steckdose mit separat schaltbarem Orientierungslicht",
                  "Lichtkegel im Dunkeln gibt Orientierung",
                  "Kein Verlegungs- und Montageaufwand für zusätzliches Licht notwendig",
                  "Sicherheit im Dunkeln",
                  "Effiziente LED-Technik",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Rauchwarnmelder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
