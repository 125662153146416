import Overview from "./slides/overview";
import BalanceSI from "./slides/balance-si";
import AxcentPurGlas from "./slides/axcent-pur-glas";
import Decento from "./slides/decento";
import FutureLinear from "./slides/future-linear";
import Axent from "./slides/axcent";
import Impuls from "./slides/impuls";
import AxcentPur from "./slides/axcent-pur";
import CaratPorzellan from "./slides/carat-porzellan";
import Dynasty from "./slides/dynasty";
import PurEdelstahl from "./slides/pur-edelstahl";
import Solo from "./slides/solo";
import Alpha from "./slides/alpha";
import ReflexSI from "./slides/reflex-si";
import Allwetter from "./slides/allwetter";
import Ocean from "./slides/ocean";
import BuschArtLinear from "./slides/BuschArtLinear";

export default function SchaltenUndBedienen() {
  return [
    <Overview key="schaltenundbedienen-Overview" />,
    <BuschArtLinear key="busch-art-linear" />,
    <CaratPorzellan key="schaltenundbedienen-CaratPorzellan" />,
    <Decento key="schaltenundbedienen-Decento" />,
    <Dynasty key="schaltenundbedienen-Dynasty" />,
    <PurEdelstahl key="schaltenundbedienen-PurEdelstahl" />,
    <Solo key="schaltenundbedienen-Solo" />,
    <AxcentPur key="schaltenundbedienen-AxcentPur" />,
    <AxcentPurGlas key="schaltenundbedienen-AxcentPurGlas" />,
    <Axent key="schaltenundbedienen-Axent" />,
    <FutureLinear key="schaltenundbedienen-FutureLinear" />,
    <BalanceSI key="schaltenundbedienen-BalanceSI" />,
    <ReflexSI key="schaltenundbedienen-ReflexSI" />,
    <Allwetter key="schaltenundbedienen-Allwetter" />,
    <Ocean key="schaltenundbedienen-Ocean" />,
  ];
}
