import { useState, useEffect } from "preact/hooks";
import { forwardRef } from "preact/compat";
import {
  Box,
  Grid,
  Paper,
  makeStyles,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Chip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLogout, isLoggedIn as getIsLoggedIn } from "../../redux/modules/userReducer";
import {
  addCategory,
  getCustomCategories,
  removeCategory,
} from "../../redux/modules/settingsReducer";
import { route } from "preact-router";
import { useModal } from "mui-modal-provider";
import BeratungPageDialog from "../Beratung/BeratungPageDialog";
import ConsultingIcon from "../SvgIcons/Consulting";
import MenuIcon from "../SvgIcons/Menu";
import root from "window-or-global";
import QDialog from "../QDialog";
import clsx from "clsx";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  getActiveModules,
  getModuleChapterCategories,
} from "../../redux/modules/backendState";

import {
  userHasPermission
} from "../../redux/modules/userReducer";


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    padding: "40px",
    zIndex: "2",
  },
  menuIcon: {
    right: "10px",
    top: "10px",
    zIndex: "20",
    position: "fixed",
  },
  consultationDialogIcon: {
    right: "10px",
    bottom: "25px",
    zIndex: "20",
    position: "fixed",
  },
  closeButton: {
    position: "fixed",
    right: "10px",
    top: "10px",
    color: "#000",
  },
  loginButton: {
    position: "fixed",
    right: "70px",
    top: "22px",
    border: "0",
  },
  appBar: {
    position: "fixed",
    color: "#000",
  },
  chapterPaper: {
    padding: "1.8rem 1.4rem",
    fontSize: "1.2rem",
    minHeight: "1.2rem",
    margin: "0.5rem",
    fontWeight: 400,
    textDecoration: "none",
    transition: "all .2s ease-in",
    cursor: "pointer",
    "&:hover, &:focus": {
      textDecoration: "none",
      backgroundColor: "var(--grey-10)",
    },
  },
  chapterActive: {
    backgroundColor: "#000",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "#000",
    },
  },
  background: {
    position: "absolute",
    zIndex: 0,
    height: "80%",
    width: "70%",
    background: "var(--grey-20)",
    right: "0",
    top: "0",
  },
  categoryController: {
    top: "28px",
    pointerEvents: "none",
    position: "fixed",
    "& div": {
      pointerEvents: "all",
    },
  },
  appWrap: {
    height: "auto",
  },
  appText: {
    textAlign: "center",
    alignItems: "center",
    fontSize: "clamp(13px, 1.2vw, 1.5rem)",
    fontFamily: "'Averta-Regular'",
  },
  appImg: {
    overflow: "hidden",
    margin: "0 auto",
    "& > img": {
      width: "auto",
      height: "calc(100vh / 9.5)",
    },
  },
  card: {
    height: "100%",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  categoriesFont: {
    fontSize: "1rem",
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export default function Menu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const [open, setOpen] = useState(false);
  const isLoggedIn = useSelector((state) => getIsLoggedIn(state));
  const activeModules = useSelector((state) => getActiveModules(state));
  const categories = useSelector((state) => getModuleChapterCategories(state));

  const customCategories = useSelector((state) => getCustomCategories(state));
  const [collapseState, setCollapseState] = useState({});
  const [appFilter, setAppFilter] = useState([]);

  const [hasPermissionToConsult, setHasPermissionToConsult] = useState()
  const [requestingPermission, setRequestingPermission] = useState(false)

  // lets lookup in global RBAC Partition  @ first
  const permissionRequest = {
    action: "edit",
    object: "consultation",
  }

  useEffect(() => {
    let cState = {};
    categories.forEach((category) => {
      cState = { ...cState, [category.key]: true };
    });

    setCollapseState((c) => ({ ...c, ...cState }));
  }, []);


  useEffect(() => {
    if (!requestingPermission && isLoggedIn && hasPermissionToConsult === undefined) {
      // no bombardement to rbac service
      setRequestingPermission(true)
      Promise.resolve(userHasPermission(permissionRequest)).then((response) => {
        if (typeof response.getValue === 'function') {
          setHasPermissionToConsult(response.getValue())
        } else {
          setHasPermissionToConsult(false)
        }

        setRequestingPermission(false)
      })
    }
  }, [requestingPermission, isLoggedIn, permissionRequest])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCustomCategories = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const category = e.currentTarget.id;
    if (customCategories.includes(category)) {
      dispatch(removeCategory(category));
    } else {
      dispatch(addCategory(category));
    }
  };

  const handleCollapseState = (e) => {
    const type = e.currentTarget.id;
    setCollapseState({ ...collapseState, [type]: !collapseState[type] });
  };

  const handleScrollIntoView = (e) => {
    const element = document.getElementById(
      e.currentTarget.getAttribute("data-id")
    );
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const startPage = () => {
    route("/", true);
    handleClose();
  };

  const routeTo = (e) => {
    const link = e.currentTarget.getAttribute("data-link");
    route(link, true);
    handleClose();
  };

  const dialog = (children) => {
    const d = showModal(QDialog, {
      children,
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  const openConsultationDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dialog = showModal(BeratungPageDialog, {
      onConfirm: () => {
        dialog.hide();
      },
      onCancel: () => {
        dialog.hide();
      },
    });
  };

  const handleAppFilterAdd = (chapter) => {
    if (appFilter.includes(chapter)) {
      setAppFilter(appFilter.filter((c) => c !== chapter));
    } else {
      setAppFilter([...appFilter, chapter]);
    }
  };

  const handleAppClick = (app) => {
    if (Object.prototype.hasOwnProperty.call(app, "menuOverlay")) {
      dialog(
        <Box height="100vh" width="100vw">
          {app.menuOverlay}
        </Box>
      );
    } else {
      root.open(app.link, app.target, "noopener");
      handleClose();
    }
  };

  const logout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(fetchUserLogout());
    handleClose();
  };

  let categoriesBarRenderData = [];
  categoriesBarRenderData.push(
    <Grid item data-id="startPage-wrapper" onClick={startPage}>
      <Typography
        className={clsx(classes.pointerCursor, classes.categoriesFont)}
      >
        Startseite
      </Typography>
    </Grid>
  );

  categories.forEach((category) => {
    categoriesBarRenderData.push(
      <Grid
        item
        data-id={`${category.key}-wrapper`}
        onClick={handleScrollIntoView}
      >
        <Typography
          className={clsx(classes.pointerCursor, classes.categoriesFont)}
        >
          {category.name}
        </Typography>
      </Grid>
    );
  });

  let categoriesCollapseRenderData = [];
  categories.forEach((category) => {
    const open = collapseState[category.key];
    let renderData = [];
    activeModules.forEach((module) => {
      if (module.chapter.category.id === category.id) {
        renderData.push(
          <Grid item xs={12} sm={6} m={0} key={module.chapter.key}>
            <Paper
              className={`${classes.chapterPaper}`}
              data-link={module.chapter.path}
              onClick={routeTo}
              elevation={3}
            >
              <Grid
                container
                justify="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid item>{module.chapter.name}</Grid>
                <Grid item>
                  <IconButton
                    id={module.chapter.key}
                    onClick={handleCustomCategories}
                  >
                    {customCategories.includes(module.chapter.key) ? (
                      <StarIcon />
                    ) : (
                      <StarBorderIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      }
    });

    categoriesCollapseRenderData.push(
      <Grid item id={`${category.key}-wrapper`}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography
              className={classes.pointerCursor}
              onClick={handleCollapseState}
              id={category.key}
              variant="h4"
            >
              {category.name} ({renderData.length}){" "}
              {open ? <ExpandLess /> : <ExpandMore />}
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Grid
                    container
                    alignItems="stretch"
                    justify="flex-start"
                    spacing={2}
                  >
                    {renderData}
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <div>
      {isLoggedIn && hasPermissionToConsult && (
        <Button
          color="primary"
          onClick={openConsultationDialog}
          className={classes.consultationDialogIcon}
        >
          <ConsultingIcon fontSize="large" stroke="black" stroke-width={1} />
        </Button>
      )}
      <Button
        color="primary"
        onClick={handleClickOpen}
        className={classes.menuIcon}
      >
        <MenuIcon fontSize="large" />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <AppBar className={classes.appBar}>
          {!isLoggedIn && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.loginButton}
              startIcon={<AccountCircleIcon />}
              size="large"
              href={`${process.env.PREACT_APP_SSOURL}authorize?response_type=code&client_id=${process.env.PREACT_APP_SSOCLIENTID}&popup=1`}
            >
              Anmelden
            </Button>
          )}
          {isLoggedIn && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.loginButton}
              startIcon={<ExitToAppIcon />}
              size="large"
              onClick={logout}
            >
              Abmelden
            </Button>
          )}
          <IconButton
            className={classes.closeButton}
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </AppBar>
        <Box className={classes.root}>
          <Grid
            className={classes.categoryController}
            container
            alignItems="center"
            justify="center"
            spacing={4}
          >
            {categoriesBarRenderData}
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="flex-start"
            spacing={4}
          >
            <Grid
              item
              container
              direction="column"
              alignItems="stretch"
              justify="flex-start"
              spacing={4}
            >
              {categoriesCollapseRenderData}
            </Grid>
          </Grid>
        </Box>
        <div className={classes.background} />
      </Dialog>
    </div>
  );
}
