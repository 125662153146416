import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../../../components/CenteredImage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/33.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/schlafen/Drehtastdimmer/drehtastdimmer.png";

export default function DrehTastdimmerOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-tastdimmer",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Dreh-/Tastdimmer" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Schöne Musik, ein Glas Wein, ein weiches Sofa. Was diese
                herrliche Situation jetzt noch perfekter macht, ist ein angenehm
                gedämpftes, stimmungsvolles Licht. Die innovativen und eleganten
                Busch-Dimmer® schaffen durch sanfte Reduzierung der Lichtmenge
                eine intime, wohnliche Atmosphäre. Sie bieten die Möglichkeit,
                je nach Stimmung und Anlass genau das passende Licht zu
                erzeugen.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Komfortable Bedienung",
                  "Nebenstellenbetrieb",
                  "Geeignet für LEDs und herkömmliche Leuchtmittel wie Glühlampen, 230-V-Halogenlampen, 230-V-Halogen-Energiesparlampen oder Niedervolt-Halogenlampen",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Rauchwarnmelder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
