import { h, Fragment } from "preact";
import { useState } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import parse from "html-react-parser";
import { Typography, Grid, Paper, Box, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  titleNews: {
    color: "black",
    paddingLeft: "10px",
    fontSize: "20px",
  },
  markdownItem: {
    padding: "35px !important",
  },
  date: {
    fontSize: ".9rem",
  },
  open: {},
}));

export default function NewsCollapse({ title, author, content, date }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const dateToString = (date) =>
    `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
      -2
    )}.${date.getFullYear()}`;
  const strDate = dateToString(new Date(date));

  return (
    <List className={`${classes.root} ${open ? classes.open : ""}`}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Typography className={classes.date}>
            <Chip label={strDate} />
          </Typography>
        </ListItemIcon>
        <ListItemText
          primary={
            <Fragment>
              <Typography className={classes.titleNews}>{title}</Typography>
              <Typography>{author}</Typography>
            </Fragment>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="200" unmountOnExit>
        <Grid container direction="column">
          <Grid item className={classes.markdownItem}>
            <Box p={1}>{parse(content)}</Box>
          </Grid>
        </Grid>
      </Collapse>
    </List>
  );
}
