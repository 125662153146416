import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../components/Slide";
import QHeader from "../../../../components/QHeader";
import QBodyText from "../../../../components/QBodyText";
import ImageComponent from "../../../../components/ImageComponent";

// Images
import CenteredImg from "../../../../assets/renovation-und-neubau/raume/wohnen/bluetooth-smart-switch/montage.png";

export default function Montage() {
  return (
    <Slide background>
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Montage" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Die smarten Schalter lassen sich in bestehende
                Schalterkombinationen integrieren, ohne Bohren, ohne Staub. Für
                die Stand-Alone-Montage werden komplette Sets angeboten.
              </QBodyText>
            </Grid>
            <Grid item xs={12}>
              <ImageComponent
                previewImgMaxHeight="40vh"
                align="center"
                src={CenteredImg}
                alt="Bluetooth Übertragung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
