import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/6.jpg";
import MultimediaImage1 from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/multimedia-datenanschluss/multimedia-1.png";
import MultimediaImage2 from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/multimedia-datenanschluss/multimedia-2.png";
import MultimediaImage3 from "../../../../../../../../../assets/renovation-und-neubau/raume/wohnen/multimedia-datenanschluss/multimedia-3.png";

export default function MultimediaDatenanschlussOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatKapitel%5D=24&tx_nlbjproducts_catalog%5Bcontroller%5D=CatDokument&cHash=922d078130f3d79c98496a808584474b",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Friends of Hue" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Kommunikation ist das Tor zur Welt. Busch-Jaeger bietet für
                nahezu jede Anwendung den passenden Einsatz zur perfekten
                Integration ins bestehende Schalterdesign.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Datendosen",
                  "Kommunikationsadapter",
                  "Telefon",
                  "Netzwerk",
                  "Lautsprecher",
                  "UAE-Dosen",
                  "Antenne",
                  "Homeway",
                  "USB",
                  "HDMI",
                  "VGA",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                col={12}
                wrapperWidth="80%"
                images={[
                  { src: MultimediaImage1, alt: "Multimedia/Datenanschluss" },
                  { src: MultimediaImage2, alt: "Multimedia/Datenanschluss" },
                  { src: MultimediaImage3, alt: "Multimedia/Datenanschluss" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
