import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/33.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/busch-steplight/busch-steplight-centered-image.jpg";
import BuschSteplightImg from "../../../../../../../../../assets/renovation-und-neubau/raume/kinderzimmer/busch-steplight/busch-steplight.jpg";

export default function BuschSteplightOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkt/future-linear/busch-steplight",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-Steplight<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Zwei in einem: Busch-steplight® ist ein SCHUKO®
                Steckdosen-Einsatz, der über ein integriertes Orientierungslicht
                verfügt. Die LEDs ermöglichen in Räumen, Fluren oder
                Treppenhäusern eine gute Orientierung bei Dunkelheit.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Steckdose mit Orientierungslicht",
                  "Lichtkegel im Dunkeln gibt Orientierung",
                  "Ideal für Krankenhäuser",
                  "Kein Verlegungs- und Montageaufwand für zusätzliches Licht notwendig",
                  "Abschaltbares Orientierungslicht",
                  "Sicherheit im Dunkeln",
                  <>
                    Auch als Busch-iceLight<sup>®</sup> erhältlich
                  </>,
                ]}
              />
              <ImageComponent
                wrapperWidth="80%"
                align="left"
                col={4}
                images={[
                  {
                    src: BuschSteplightImg,
                    alt: "Busch-Steplight",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="35vh"
                src={CenteredImg}
                shadow
                alt={
                  <>
                    Busch-Steplight<sup>®</sup>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
