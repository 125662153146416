import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../../components/ChapterCard";

// Overlays
import BluetoothSmartSwitchOverlay from "./slides/Bluetooth-Smart-Switch";
import FriendsOfHueOverlay from "./slides/Friends-of-Hue";

// Images
import BackgroundImg from "../../../../../../../../assets/_backgrounds/renovation-und-neubau/6.jpg";
import BluetoothSmartSwitch from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/bluetooth-smart-switch.jpg";
import FriendsOfHue from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/friends-of-hue.jpg";
import VideoInnenstation from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/video-innenstation.jpg";
import MultimediaDatenanschluss from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/multimedia-datenanschluss.jpg";
import RaumTemperaturregler from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/raum-temperaturregler.jpg";
import BuschPowerdock from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/busch-powerdock.jpg";
import BuschCompactTimer from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/busch-compact-timer.jpg";
import LEDDimmerFlex from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/led-dimmer-flex.jpg";
import RelaiseinsatzFlex from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/relaiseinsatz-flex.jpg";
import COMelder from "../../../../../../../../assets/renovation-und-neubau/raume/wohnen/co-melder.jpg";
import VideoInnenstationOverlay from "./slides/Video-Innenstation";
import MultimediaDatenanschlussOverlay from "./slides/Multimedia-Datenanschluss";
import RaumtemperaturreglerOverlay from "./slides/Raumtemperaturregler";
import BuschPowerDockOverlay from "./slides/BuschPowerDock";
import BuschCompactTimerOverlay from "./slides/Busch-Compact-Timer";
import LEDDimmerUndRelaisFlexOverlay from "./slides/LED-Dimmer-und-Relais-fles";
import COMelderOverlay from "./slides/CO-Melder";

const items = [
  {
    title: "Bluetooth Smart Switch",
    img: BluetoothSmartSwitch,
    menuOverlay: <BluetoothSmartSwitchOverlay />,
  },
  {
    title: "Friends of Hue",
    img: FriendsOfHue,
    menuOverlay: <FriendsOfHueOverlay />,
  },
  {
    title: 'Video Innenstation 4.3" WLAN',
    img: VideoInnenstation,
    menuOverlay: <VideoInnenstationOverlay />,
  },
  {
    title: "Multimedia / Datenanschluss",
    img: MultimediaDatenanschluss,
    menuOverlay: <MultimediaDatenanschlussOverlay />,
  },
  {
    title: "Raum-Temperaturregler",
    img: RaumTemperaturregler,
    menuOverlay: <RaumtemperaturreglerOverlay />,
  },
  {
    title: "Busch-powerDock",
    img: BuschPowerdock,
    menuOverlay: <BuschPowerDockOverlay />,
  },
  {
    title: "Busch-Compact-Timer",
    img: BuschCompactTimer,
    menuOverlay: <BuschCompactTimerOverlay />,
  },
  {
    title: "LED-Dimmer flex",
    img: LEDDimmerFlex,
    menuOverlay: <LEDDimmerUndRelaisFlexOverlay />,
  },
  {
    title: "Relaiseinsatz flex",
    img: RelaiseinsatzFlex,
    menuOverlay: <LEDDimmerUndRelaisFlexOverlay />,
  },
  {
    title: "CO-Melder",
    img: COMelder,
    menuOverlay: <COMelderOverlay />,
  },
];

export default function Wohnen() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
        width: 0.45,
      }}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Wohnen" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Das Zentrum jedes Hauses. Hier treffen wir uns mit Familie und
                Freunden. Ein Ort für Spaß und Entspannung. Ein Ort, der uns
                repräsentiert.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={3}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
