import { h, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";
import { forwardRef } from "preact/compat";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PauseIcon from "@material-ui/icons/Pause";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import StopIcon from "@material-ui/icons/Stop";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Tab,
  Tabs,
  Badge,
} from "@material-ui/core";
import ElevationScroll from "../ElevationScroll";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn } from "../../redux/modules/userReducer";
import { getUnreadAmount } from "../../redux/modules/newsReducer";
import { useModal } from "mui-modal-provider";

import ConsultationsPage from "./ADMProfile/ConsultationsPage";
import ADMProfile from "./ADMProfile/ADMProfile";
import NewsPage from "./ADMProfile/NewsPage";
import SetupPage from "./Setup/SetupPage";
import ConsultingPage from "./Categories";
import ResultPage from "./Result/ResultPage";
import {
  getConsultationStoreAmount,
  setOfflineConsultation,
} from "../../redux/modules/consultationStore";
import { getSetupData } from "../../redux/modules/setupReducer";
import { resetConsulting } from "../../redux/modules/reducers";
import QDialog from "../QDialog";
import { getConsultingRunning } from "../../redux/modules/consulting";
import { getCustomerData } from "../../redux/modules/customer";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dialog-tab-${index}`}
      aria-labelledby={`dialog-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `dialog-tab-${index}`,
    "aria-controls": `dialog-tab-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  fab: {
    "& > *": {
      margin: theme.spacing(1),
    },
    position: "fixed",
    bottom: "25px",
    right: "25px",
    zIndex: "20",
  },
  container: {
    overflow: "scroll",
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch",
  },
  alignLeft: {
    marginLeft: "auto",
  },
  tabButtons: {
    minWidth: "0",
    color: "black",
    fontFamily: "Averta-Bold",
  },
  dialogPaper: {},
  tabPadding: {
    padding: "1rem",
  },
  appBar: {
    backgroundColor: "var(--grey-20)",
  },
  badge: {
    backgroundColor: "#007a33",
  },
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function BeratungPageDialog({ onConfirm, onCancel, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { showModal } = useModal();

  const [tabIndex, setTabIndex] = useState("consultations");

  const loggedIn = useSelector((state) => isLoggedIn(state));
  const consultingRunning = useSelector((state) => getConsultingRunning(state));
  const unreadAmount = useSelector((state) => getUnreadAmount(state));
  const storedConsultationsAmount = useSelector((state) =>
    getConsultationStoreAmount(state)
  );
  const customerData = useSelector((state) => getCustomerData(state));

  const handleChange = (event, newValue) => {
    if (newValue !== -1) {
      setTabIndex(newValue);
    }
  };

  const setTab = (value) => {
    setTabIndex(value);
  };

  const pauseConsulting = () => {
    dispatch(setOfflineConsultation());
    dispatch(resetConsulting());
    setTab("consultations");
  };

  const openSetupPage = () => {
    const d = showModal(QDialog, {
      children: (
        <Box width="100vw" height="100vh">
          <Box p={2}>
            <SetupPage />
          </Box>
        </Box>
      ),
      onConfirm: () => {
        d.hide();
      },
      onCancel: () => {
        d.hide();
      },
    });
  };

  useEffect(() => {
    if (open) {
      if (consultingRunning) {
        setTabIndex("consulting");
      } else {
        setTabIndex("consultations");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chkEndButton = consultingRunning;
  const customerLabel = `Beratung mit ${
    customerData.salutation === "Herr" ? "Herrn" : "Frau"
  } ${customerData.lastname}`;

  return (
    <Dialog
      fullScreen
      {...props}
      TransitionComponent={Transition}
      PaperProps={{ classes: { root: classes.dialogPaper } }}
    >
      <Fragment>
        <ElevationScroll>
          <AppBar className={classes.appBar}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              indicatorColor="transparent"
              textColor="primary"
              variant="standard"
              aria-label="full width tabs example"
            >
              {chkEndButton && (
                <Tab
                  className={classes.tabButtons}
                  {...a11yProps("consulting")}
                  value="consulting"
                  label={
                    <>
                      <QuestionAnswerOutlinedIcon color="primary" />
                      &ensp;Gespräch
                    </>
                  }
                />
              )}
              {chkEndButton && (
                <Tab
                  className={clsx(classes.tabButtons)}
                  wrapped
                  label={customerLabel}
                  onClick={openSetupPage}
                  value={-1}
                />
              )}
              {storedConsultationsAmount <= 20 && consultingRunning && (
                <Tab
                  className={clsx(classes.tabButtons)}
                  onClick={pauseConsulting}
                  label={
                    <>
                      <PauseIcon color="primary" />
                      &ensp;Pausieren
                    </>
                  }
                />
              )}
              {consultingRunning && (
                <Tab
                  className={clsx(classes.tabButtons)}
                  value="consultingFinish"
                  label={
                    <>
                      <StopIcon color="primary" />
                      &ensp;Beenden
                    </>
                  }
                />
              )}
              {loggedIn && (
                <Tab
                  className={clsx(classes.alignLeft, classes.tabButtons)}
                  {...a11yProps("news")}
                  value="news"
                  label={
                    <>
                      <Badge badgeContent={unreadAmount} color="error">
                        <MailOutlineIcon color="primary" />
                      </Badge>
                      &ensp;News
                    </>
                  }
                />
              )}
              {!chkEndButton && (
                <Tab
                  className={clsx({
                    [classes.tabButtons]: true,
                    [classes.alignLeft]: !loggedIn,
                  })}
                  {...a11yProps("consultations")}
                  value="consultations"
                  label={
                    <>
                      <SupervisedUserCircleIcon color="primary" />
                      &ensp;Beratungen
                    </>
                  }
                />
              )}
              <Tab
                className={classes.tabButtons}
                {...a11yProps("profile")}
                value="profile"
                label={
                  <>
                    <AccountCircleIcon color="primary" />
                    &ensp;Profil
                  </>
                }
              />
              <Tab
                className={classes.tabButtons}
                onClick={onCancel}
                label={<CloseIcon color="primary" fontSize="large" />}
                {...a11yProps(-1)}
                value={-1}
              />
            </Tabs>
          </AppBar>
        </ElevationScroll>
        <Toolbar variant="dense" />
        <Box id="consultationDialog" className={classes.container}>
          <TabPanel
            value={tabIndex}
            index="news"
            dir={theme.direction}
            className={classes.tabPadding}
          >
            <NewsPage />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index="consultations"
            dir={theme.direction}
            className={classes.tabPadding}
          >
            <ConsultationsPage setTab={setTab} handleDialogClose={onCancel} />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index="profile"
            dir={theme.direction}
            className={classes.tabPadding}
          >
            <ADMProfile handleDialogClose={onCancel} />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index="setup"
            dir={theme.direction}
            className={classes.tabPadding}
          >
            <SetupPage handleDialogClose={onCancel} setTab={setTab} />
          </TabPanel>
          <TabPanel value={tabIndex} index="consulting" dir={theme.direction}>
            <ConsultingPage />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index="consultingFinish"
            dir={theme.direction}
            className={classes.tabPadding}
          >
            <ResultPage handleDialogClose={onCancel} />
          </TabPanel>
        </Box>
      </Fragment>
    </Dialog>
  );
}
