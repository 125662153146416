import { combineReducers } from "redux";
import news from "./newsReducer";
import consultationStore from "./consultationStore";
// new backend 2021
import backendState from "./backendState";
import fieldValues from "./fieldValuesReducer";
import customer from "./customer";
import consultant from "./consultant";
import consulting from "./consulting";
import user from "./userReducer";
import settings from "./settings";

const RESET_CONSULTING = "playbook/rootReducer/RESET_CONSULTING";
const RESET_APP = "playbook/rootReducer/RESET_APP";
const LOAD_CONSULTATION = "playbook/rootReducer/LOAD_CONSULTATION";

const reducers = {
  news,
  consultationStore,
  // new backend 2021
  backendState,
  fieldValues,
  customer,
  consultant,
  user,
  consulting,
  settings,
};

const appReducer = combineReducers({
  ...reducers,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_CONSULTING) {
    const {
      settings,
      consultant,
      user,
      consultationStore,
      backendState,
      news,
    } = state;
    state = {
      settings,
      consultant,
      user,
      consultationStore,
      backendState,
      news,
    };
  }

  if (action.type === RESET_APP) {
    state = undefined;
  }

  if (action.type === LOAD_CONSULTATION) {
    const { consultationStore, user, settings, backendState } = state;
    let consultationStoreNew = {
      consultations: [],
    };
    consultationStoreNew.consultations = consultationStore.consultations.filter(
      (c) =>
        c.consulting.internalID !== action.consultation.consulting.internalID
    );

    state = {
      ...action.consultation,
      consultationStore: consultationStoreNew,
      user,
      settings,
      backendState,
    };
  }

  return appReducer(state, action);
};

export const resetConsulting = () => ({
  type: RESET_CONSULTING,
});

export const resetApp = () => ({
  type: RESET_APP,
});

export const loadConsultation = (consultation) => ({
  type: LOAD_CONSULTATION,
  consultation,
});

export default rootReducer;
