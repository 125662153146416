import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Jalousiemanagement() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/SchaltenUndSteuern/background/jalousiemanagement.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/SchaltenUndSteuern/produkte/jalousiemanagement.jpg"
        alt="Busch-Compact-Timer"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Elektronik" />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Jalousiemanagement
            </QBodyText>
            <QBodyText>
              Das Jalousiemanagement eignet sich zur Steuerung aller
              motorbetriebenen Jalousien, Rollläden und Markisen und sorgt damit
              für mehr Wohnkomfort, Sicherheit und Wirtschaftlichkeit.
            </QBodyText>
            <Box style={{ paddingRight: "10vw" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Jalousien, Rollläden oder der Sonnenschutz können manuell, der Situation angemessen, gesteuert werden",
                  "Komfortable, intuitive Bedienung",
                  "Vielfalt im ganzheitlichen Schalterdesign",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Jalousieschalter/-taster",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/jalousiemanagement-left.jpg",
                  },
                  {
                    alt: "Jalousieschalter/-taster",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/jalousiemanagement-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
