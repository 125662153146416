import { Grid } from "@material-ui/core";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";

export default function Uebersicht() {
  return (
    <Slide>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
            subTitle="System"
          />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
          <svg style={{width: "auto", height: "80%", margin: "0px auto", display: "block"}} viewBox="0 0 931 670" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>tuerkommunikation</title>
    <defs>
        <rect id="path-1" x="0" y="144.5" width="124" height="95" rx="4"></rect>
        <filter x="-5.6%" y="-5.3%" width="111.3%" height="114.7%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-3" x="333" y="30" width="140" height="114" rx="5"></rect>
        <filter x="-5.0%" y="-4.4%" width="110.0%" height="112.3%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-5" x="627" y="0" width="140" height="110" rx="5"></rect>
        <filter x="-5.0%" y="-4.5%" width="110.0%" height="112.7%" filterUnits="objectBoundingBox" id="filter-6">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-7" x="73" y="309.5" width="124" height="118" rx="5"></rect>
        <filter x="-5.6%" y="-4.2%" width="111.3%" height="111.9%" filterUnits="objectBoundingBox" id="filter-8">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-9" x="561" y="527" width="152" height="124" rx="5"></rect>
        <filter x="-4.6%" y="-4.0%" width="109.2%" height="111.3%" filterUnits="objectBoundingBox" id="filter-10">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-11" x="771" y="357" width="152" height="118" rx="5"></rect>
        <filter x="-4.6%" y="-4.2%" width="109.2%" height="111.9%" filterUnits="objectBoundingBox" id="filter-12">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-13" x="9.5" y="537" width="162" height="124" rx="6"></rect>
        <filter x="-4.3%" y="-4.0%" width="108.6%" height="111.3%" filterUnits="objectBoundingBox" id="filter-14">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
        <rect id="path-15" x="0" y="0" width="166" height="166" rx="17"></rect>
        <polygon id="path-17" points="0 0 67.504 0 67.504 67.5039 0 67.5039"></polygon>
        <polygon id="path-19" points="0 0 35.9994 0 35.9994 64.5 0 64.5"></polygon>
        <polygon id="path-21" points="0 0 1 0 1 38.9997 0 38.9997"></polygon>
        <polygon id="path-23" points="0 0 1 0 1 38.9997 0 38.9997"></polygon>
        <polygon id="path-25" points="0 0 18 0 18 38.9997 0 38.9997"></polygon>
        <polygon id="path-27" points="0 39 66 39 66 0 0 0"></polygon>
    </defs>
    <g id="Allgemein" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard-3" transform="translate(-46.000000, -51.000000)">
            <g id="tuerkommunikation" transform="translate(50.000000, 53.500000)">
                <g id="hotspots">
                    <a href="#loesungen/6">
                      <g id="medien">
                          <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                          <use fill-opacity="0.981368485" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-1"></use>
                      </g>
                    </a>
                    <a href="#loesungen/2">
                      <g id="heizung">
                          <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlinkHref="#path-3"></use>
                          <use fill-opacity="0.981368485" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-3"></use>
                      </g>
                    </a>
                    <a href="#loesungen/3">
                      <g id="beleuchtung">
                          <use fill="black" fill-opacity="1" filter="url(#filter-6)" xlinkHref="#path-5"></use>
                          <use fill-opacity="0.981368485" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-5"></use>
                      </g>
                    </a>
                    <a href="#loesungen/1">
                      <g id="sicherheit">
                          <use fill="black" fill-opacity="1" filter="url(#filter-8)" xlinkHref="#path-7"></use>
                          <use fill-opacity="0.981368485" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-7"></use>
                      </g>
                    </a>
                    <a href="#loesungen/7">
                      <g id="haushaltsgeraete">
                          <use fill="black" fill-opacity="1" filter="url(#filter-10)" xlinkHref="#path-9"></use>
                          <use fill-opacity="0.981368485" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-9"></use>
                      </g>
                    </a>
                    <a href="#loesungen/4">
                      <g id="jalousie">
                          <use fill="black" fill-opacity="1" filter="url(#filter-12)" xlinkHref="#path-11"></use>
                          <use fill-opacity="0.981368485" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-11"></use>
                      </g>
                    </a>
                    <a href="#loesungen/5">
                      <g id="tuerkommunikation">
                          <use fill="black" fill-opacity="1" filter="url(#filter-14)" xlinkHref="#path-13"></use>
                          <use fill-opacity="0.981368485" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-13"></use>
                      </g>
                    </a>
                </g>
                <g id="Grafik" transform="translate(22.512300, 9.500000)">
                    <g id="App-Icon---BJE---1024" transform="translate(339.000000, 258.000000)">
                        <mask id="mask-16" fill="white">
                            <use xlinkHref="#path-15"></use>
                        </mask>
                        <use id="Mask" fill="#D8D8D8" xlinkHref="#path-15"></use>
                        <image mask="url(#mask-16)" x="0.4877" y="0" width="166" height="166" xlinkHref="../../../../../../../assets/smarterhome/bje-app-icon.png"></image>
                    </g>
                    <g id="Group-92" transform="translate(89.773700, 314.333300)" stroke-linejoin="bevel" stroke-width="3">
                        <path d="M25.515,63 C52.515,52.5 51.015,12 51.015,12 C34.515,12 25.515,0 25.515,0 C25.515,0 16.515,12 0.015,12 C0.015,12 -1.485,52.5 25.515,63 Z" id="Stroke-88" stroke="#000000"></path>
                        <polyline id="Stroke-90" stroke="#009DE0" points="13.53 26.4375 23.093 36.0005 39.03 20.0625"></polyline>
                    </g>
                    <g id="Group-98" transform="translate(588.634700, 536.408000)" stroke-linejoin="bevel" stroke-width="3">
                        <path d="M0,63 L48,63 L48,0 L0,0 L0,63 Z M1.5,12 L46.5,12 L1.5,12 Z" id="Stroke-93" stroke="#000000"></path>
                        <path d="M41.9995,37.4999 C41.9995,47.4409 33.9405,55.4999 23.9995,55.4999 C14.0585,55.4999 5.9995,47.4409 5.9995,37.4999 C5.9995,27.5589 14.0585,19.4999 23.9995,19.4999 C33.9405,19.4999 41.9995,27.5589 41.9995,37.4999 Z" id="Stroke-95" stroke="#009DE0"></path>
                        <path d="M40.4995,5.9999 L43.4995,5.9999 M34.4995,5.9999 L37.4995,5.9999 M28.4995,5.9999 L31.4995,5.9999 M4.4995,5.9999 L19.4995,5.9999" id="Stroke-96" stroke="#000000"></path>
                        <path d="M35.9995,37.4999 C35.9995,44.1269 30.6275,49.4999 23.9995,49.4999 C17.3725,49.4999 11.9995,44.1269 11.9995,37.4999 C11.9995,30.8729 17.3725,25.4999 23.9995,25.4999 C30.6275,25.4999 35.9995,30.8729 35.9995,37.4999 Z" id="Stroke-97" stroke="#009DE0"></path>
                    </g>
                    <g id="Group-106" transform="translate(795.635200, 360.433100)">
                        <mask id="mask-18" fill="white">
                            <use xlinkHref="#path-17"></use>
                        </mask>
                        <g id="Clip-105"></g>
                        <path d="M55.229,64.435 L52.161,64.435 L52.161,62.9 C52.161,62.054 52.848,61.367 53.694,61.367 C54.541,61.367 55.228,62.054 55.228,62.9 L55.228,64.435 L55.229,64.435 Z M67.504,3.067 L67.504,3.97903932e-13 L-5.68434189e-14,3.97903932e-13 L-5.68434189e-14,3.067 L52.161,3.067 L52.161,6.136 L-5.68434189e-14,6.136 L-5.68434189e-14,9.205 L52.161,9.205 L52.161,12.273 L-5.68434189e-14,12.273 L-5.68434189e-14,15.342 L52.161,15.342 L52.161,18.41 L-5.68434189e-14,18.41 L-5.68434189e-14,21.479 L52.161,21.479 L52.161,24.548 L-5.68434189e-14,24.548 L-5.68434189e-14,27.616 L52.161,27.616 L52.161,30.685 L-5.68434189e-14,30.685 L-5.68434189e-14,33.753 L52.161,33.753 L52.161,58.583 C50.379,59.218 49.092,60.904 49.092,62.902 L49.092,65.971 L50.625,67.504 L56.762,67.504 L58.297,65.971 L58.297,62.902 C58.297,60.904 57.01,59.218 55.228,58.583 L55.228,33.753 L67.502,33.753 L67.502,30.685 L55.228,30.685 L55.228,27.616 L67.502,27.616 L67.502,24.548 L55.228,24.548 L55.228,21.479 L67.502,21.479 L67.502,18.41 L55.228,18.41 L55.228,15.342 L67.502,15.342 L67.502,12.273 L55.228,12.273 L55.228,9.205 L67.502,9.205 L67.502,6.136 L55.228,6.136 L55.228,3.067 L67.504,3.067 Z" id="Fill-104" fill="#000000" mask="url(#mask-18)"></path>
                    </g>
                    <polygon id="Fill-107" fill="#009DE0" points="802.2216 411.5093 796.0846 417.6463 798.2536 419.8143 801.7716 416.2993 801.7716 427.9363 804.8406 427.9363 804.8406 416.2993 808.3576 419.8143 810.5276 417.6463 804.3906 411.5093"></polygon>
                    <polygon id="Fill-108" fill="#009DE0" points="818.6475 411.3896 818.6475 423.0266 815.1305 419.5086 812.9605 421.6776 819.0965 427.8156 821.2655 427.8156 827.4035 421.6776 825.2335 419.5086 821.7155 423.0266 821.7155 411.3896"></polygon>
                    <polyline id="Stroke-109" stroke="#009DE0" stroke-width="3" stroke-linejoin="bevel" points="100.0633 546.2873 82.0633 564.2873 82.0633 592.7873 100.0633 609.2873 100.0633 546.2873 98.5633 547.7873"></polyline>
                    <line x1="85.0633" y1="579.2873" x2="88.0633" y2="579.2873" id="Stroke-111" stroke="#009DE0" stroke-width="3"></line>
                    <g id="Group-117" transform="translate(36.433300, 546.287300)">
                        <path d="M11.13,21 L0,29.969 L11.13,39 M0.63,30 L24.63,30" id="Stroke-112" stroke="#009DE0" stroke-width="3" stroke-linejoin="bevel"></path>
                        <g id="Group-116" transform="translate(29.130000, 0.000000)">
                            <mask id="mask-20" fill="white">
                                <use xlinkHref="#path-19"></use>
                            </mask>
                            <g id="Clip-115"></g>
                            <polyline id="Stroke-114" stroke="#000000" stroke-width="5" stroke-linejoin="bevel" mask="url(#mask-20)" points="36 0 0 0 0 64.5"></polyline>
                        </g>
                    </g>
                    <g id="Group-129" transform="translate(6.445700, 152.260000)">
                        <g id="Group-120" transform="translate(7.500000, 0.000000)">
                            <mask id="mask-22" fill="white">
                                <use xlinkHref="#path-21"></use>
                            </mask>
                            <g id="Clip-119"></g>
                            <path d="M0,-0.0003 L0,8.9997 M0,16.4997 L0,38.9997" id="Stroke-118" stroke="#000000" stroke-width="3" mask="url(#mask-22)"></path>
                        </g>
                        <g id="Group-123" transform="translate(22.500000, 0.000000)">
                            <mask id="mask-24" fill="white">
                                <use xlinkHref="#path-23"></use>
                            </mask>
                            <g id="Clip-122"></g>
                            <path d="M0,22.4997 L0,-0.0003 M0,29.9997 L0,38.9997" id="Stroke-121" stroke="#009DE0" stroke-width="3" mask="url(#mask-24)"></path>
                        </g>
                        <g id="Group-126" transform="translate(40.500000, 0.000000)">
                            <mask id="mask-26" fill="white">
                                <use xlinkHref="#path-25"></use>
                            </mask>
                            <g id="Clip-125"></g>
                            <path d="M0,19.4997 L0,38.9997 M0,11.9997 L0,-0.0003 M18,-0.0003 L18,4.4997 M18,11.9997 L18,38.9997" id="Stroke-124" stroke="#000000" stroke-width="3" mask="url(#mask-26)"></path>
                        </g>
                        <mask id="mask-28" fill="white">
                            <use xlinkHref="#path-27"></use>
                        </mask>
                        <g id="Clip-128"></g>
                        <path d="M1.5,16.5 L13.5,16.5 L13.5,9 L1.5,9 L1.5,16.5 Z M34.5,19.5 L46.5,19.5 L46.5,12 L34.5,12 L34.5,19.5 Z M52.5,12 L64.5,12 L64.5,4.5 L52.5,4.5 L52.5,12 Z" id="Stroke-127" stroke="#000000" stroke-width="3" stroke-linejoin="bevel" mask="url(#mask-28)"></path>
                    </g>
                    <polygon id="Stroke-130" stroke="#009DE0" stroke-width="3" stroke-linejoin="bevel" points="23.9457 182.26 35.9457 182.26 35.9457 174.76 23.9457 174.76"></polygon>
                    <g id="Group-135" transform="translate(368.780800, 32.798100)" stroke-linejoin="bevel" stroke-width="3">
                        <path d="M18.004,40.608 C21.588,42.683 24,46.56 24,51 C24,57.627 18.627,63 11.999,63 C5.372,63 0,57.627 0,51 C0,46.559 2.412,42.682 5.998,40.606 L6,40.5 L6,6 C6,2.686 8.686,0 11.999,0 C15.313,0 18,2.686 18,6 L18,40.5 L18.004,40.608 Z M11.999,6 L11.999,46.5 L11.999,6 Z M16.499,51 C16.499,53.485 14.485,55.5 11.999,55.5 C9.514,55.5 7.499,53.485 7.499,51 C7.499,48.515 9.514,46.5 11.999,46.5 C14.485,46.5 16.499,48.515 16.499,51 Z" id="Stroke-131" stroke="#000000"></path>
                        <path d="M22.4996,7.5 L34.4996,7.5 M22.4996,15 L34.4996,15 M22.4996,22.5 L28.4996,22.5" id="Stroke-133" stroke="#009DE0"></path>
                    </g>
                    <line x1="645.9877" y1="134.5" x2="522.0892" y2="239.5921" id="Stroke-136" stroke="#000000" stroke-width="1.638" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,4.885"></line>
                    <g id="Group-141" transform="translate(650.443570, 127.072163)" stroke="#009DE0" stroke-width="3.277">
                        <path d="M1.43813001,0.00203694091 C2.27513001,-0.0409630591 2.98913001,0.602036941 3.03313001,1.43803694 C3.07713001,2.27303694 2.43413001,2.99003694 1.59713001,3.03303694 C0.760130013,3.07803694 0.0461300127,2.43403694 0.00213001267,1.59803694 C-0.0418699873,0.762036941 0.601130013,0.0470369409 1.43813001,0.00203694091 Z" id="Stroke-139"></path>
                    </g>
                    <text id="Haushaltsgeräte" font-family="Averta-Semibold" font-size="16" font-weight="normal" fill="#000000">
                        <tspan x="551.968" y="624.4331">Haushaltsgeräte</tspan>
                    </text>
                    <text id="Beleuchtung" font-family="Averta-Semibold" font-size="16" font-weight="normal" fill="#000000">
                        <tspan x="626" y="86.4891">Beleuchtung</tspan>
                    </text>
                    <text id="Jalousie" font-family="Averta-Semibold" font-size="16" font-weight="normal" fill="#000000">
                        <tspan x="797.032" y="451.9611">Jalousie</tspan>
                    </text>
                    <text id="Türkommunikation" font-family="Averta-Semibold" font-size="16" font-weight="normal" fill="#000000">
                        <tspan x="0" y="634.4331">Türkommunikation</tspan>
                    </text>
                    <text id="Sicherheit" font-family="Averta-Semibold" font-size="16" font-weight="normal" fill="#000000">
                        <tspan x="76.648" y="402.1931">Sicherheit</tspan>
                    </text>
                    <text id="Medien" font-family="Averta-Semibold" font-size="16" font-weight="normal" fill="#000000">
                        <tspan x="11.52" y="214.1851">Medien</tspan>
                    </text>
                    <text id="Heizung" font-family="Averta-Semibold" font-size="16" font-weight="normal" fill="#000000">
                        <tspan x="348.784" y="121.4171">Heizung</tspan>
                    </text>
                    <line x1="385.7318" y1="168.8672" x2="398.9958" y2="230.4262" id="Stroke-142" stroke="#000000" stroke-width="1.638" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,5.038"></line>
                    <g id="Group-147" transform="translate(382.689036, 160.661574)">
                        <path d="M0.142363727,0.876526353 C0.496363727,0.116526353 1.39836373,-0.211473647 2.15836373,0.142526353 C2.91736373,0.496526353 3.24636373,1.39852635 2.89236373,2.15852635 C2.53836373,2.91852635 1.63636373,3.24752635 0.876363727,2.89352635 C0.117363727,2.53952635 -0.211636273,1.63652635 0.142363727,0.876526353" id="Fill-143" fill="#009DE0"></path>
                        <path d="M0.142363727,0.876526353 C0.496363727,0.116526353 1.39836373,-0.211473647 2.15836373,0.142526353 C2.91736373,0.496526353 3.24636373,1.39852635 2.89236373,2.15852635 C2.53836373,2.91852635 1.63636373,3.24752635 0.876363727,2.89352635 C0.117363727,2.53952635 -0.211636273,1.63652635 0.142363727,0.876526353 Z" id="Stroke-145" stroke="#009DE0" stroke-width="3.277"></path>
                    </g>
                    <line x1="714.9877" y1="400.5" x2="533.6047" y2="348.2059" id="Stroke-148" stroke="#000000" stroke-width="1.638" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,4.912"></line>
                    <g id="Group-153" transform="translate(721.708711, 401.289734)" stroke="#009DE0" stroke-width="3.277">
                        <path d="M2.55238947,0.407566163 C3.16538947,0.977566163 3.19838947,1.94056616 2.62638947,2.55256616 C2.05638947,3.16556616 1.09538947,3.19856616 0.482389473,2.62856616 C-0.131610527,2.05556616 -0.162610527,1.09456616 0.407389473,0.481566163 C0.980389473,-0.129433837 1.93938947,-0.164433837 2.55238947,0.407566163 Z" id="Stroke-151"></path>
                    </g>
                    <line x1="582.9877" y1="495.5" x2="486.562" y2="450.5116" id="Stroke-154" stroke="#000000" stroke-width="1.638" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,4.832"></line>
                    <g id="Group-159" transform="translate(588.187561, 496.581424)" stroke="#009DE0" stroke-width="3.277">
                        <path d="M2.98373886,1.12607621 C3.20073886,1.93607621 2.71973886,2.76807621 1.90973886,2.98407621 C1.10073886,3.20107621 0.268738858,2.72107621 0.0517388579,1.91107621 C-0.164261142,1.10207621 0.315738858,0.26907621 1.12473886,0.0520762105 C1.93473886,-0.16492379 2.76673886,0.31607621 2.98373886,1.12607621 Z" id="Stroke-157"></path>
                    </g>
                    <line x1="177.9877" y1="570.5" x2="314.8064" y2="443.7442" id="Stroke-160" stroke="#000000" stroke-width="1.638" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,4.921"></line>
                    <g id="Group-165" transform="translate(170.498396, 574.610096)" stroke="#009DE0" stroke-width="3.277">
                        <path d="M1.45150445,3.03250447 C0.614504452,2.99550447 -0.0354955484,2.28850447 0.00150445159,1.45050447 C0.0375044516,0.614504472 0.746504452,-0.0354955283 1.58350445,0.00150447171 C2.42150445,0.0365044717 3.07050445,0.747504472 3.03450445,1.58350447 C2.99750445,2.42150447 2.28850445,3.06750447 1.45150445,3.03250447 Z" id="Stroke-163"></path>
                    </g>
                    <line x1="208.3744" y1="357.6215" x2="309.0304" y2="357.4375" id="Stroke-166" stroke="#000000" stroke-width="1.638" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,4.91"></line>
                    <g id="Group-171" transform="translate(200.127042, 356.070793)">
                        <path d="M0.522757736,2.66360731 C-0.110242264,2.11260731 -0.178242264,1.15360731 0.372757736,0.520607314 C0.920757736,-0.109392686 1.88175774,-0.178392686 2.51475774,0.372607314 C3.14775774,0.923607314 3.21175774,1.88260731 2.66375774,2.51360731 C2.11275774,3.14560731 1.15575774,3.21460731 0.522757736,2.66360731" id="Fill-167" fill="#009DE0"></path>
                        <path d="M0.522757736,2.66360731 C-0.110242264,2.11260731 -0.178242264,1.15360731 0.372757736,0.520607314 C0.920757736,-0.109392686 1.88175774,-0.178392686 2.51475774,0.372607314 C3.14775774,0.923607314 3.21175774,1.88260731 2.66375774,2.51360731 C2.11275774,3.14560731 1.15575774,3.21460731 0.522757736,2.66360731 Z" id="Stroke-169" stroke="#009DE0" stroke-width="3.277"></path>
                    </g>
                    <line x1="133.9877" y1="195.5" x2="311.1517" y2="263.6255" id="Stroke-172" stroke="#000000" stroke-width="1.638" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,4.941"></line>
                    <g id="Group-177" transform="translate(125.459270, 191.072163)" stroke="#009DE0" stroke-width="3.277">
                        <path d="M1.59713001,0.00203694091 C0.760130013,-0.0409630591 0.0461300127,0.602036941 0.00213001267,1.43803694 C-0.0418699873,2.27303694 0.601130013,2.99003694 1.43813001,3.03303694 C2.27513001,3.07803694 2.98913001,2.43403694 3.03313001,1.59803694 C3.07713001,0.762036941 2.43413001,0.0470369409 1.59713001,0.00203694091 Z" id="Stroke-175"></path>
                    </g>
                    <g id="BJE_picto_48x48_11_light_bulb_0461" transform="translate(642.487700, 0.000000)" stroke-linejoin="bevel">
                        <line x1="30.5" y1="0" x2="30.5" y2="9" id="Stroke-1" stroke="#00A3DA" stroke-width="2"></line>
                        <path d="M21.8,58.6363636 L39.2,58.6363636 L39.2,52.8181818 L21.8,52.8181818 L21.8,58.6363636 Z M24.7,58.6363636 L24.7,63 L36.3,63 L36.3,58.6363636 L24.7,58.6363636 Z M30.5,54.2727273 L30.5,41 L26.15,33.9090909 L30.5,41 L30.5,54.2727273 Z M30.5,41.1818182 L34.85,33.9090909 L30.5,41.1818182 Z M23.25,51.3636364 L23.25,44.0909091 L16,31 L16,29.5454545 C16,21.512 22.49165,15 30.5,15 C38.50835,15 45,21.512 45,29.5454545 L45,31 L37.75,44.0909091 L37.75,51.3636364 L23.25,51.3636364 Z" id="Stroke-3" stroke="#1A1919" stroke-width="3"></path>
                        <path d="M8.85714286,8 L16.2380952,15.1875 M0,31 L10.3333333,31 M45.7619048,15.1875 L53.1428571,8 M51.6666667,31 L62,31" id="Stroke-4" stroke="#00A3DA" stroke-width="2"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
