import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/50.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/garage/terra-ac-wallbox/terra-ac-wallbox.png";

export default function TerraACWalboxOveray() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://new.abb.com/ev-charging/de/terra-ac-wandladestation",
          target: "_blank",
        },
        {
          type: "icon",
          img: "youtube",
          link: "https://www.youtube.com/watch?v=5qR8vBJocAA&feature=youtu.be",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Terra AC Wallbox" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Ob zu Hause, bei der Arbeit, im Einzelhandel, in Tiefgaragen
                oder im Gastgewerbe: Die Terra AC Wallbox ist eine sichere und
                zuverlässige Ladelösung, die sich für jeden Anwendungsfall
                eignet.
              </QBodyText>
              <QBodyText>
                Über Modbus und OCPP lässt sich die Wallbox in ein
                Energiemanagementsystem integrieren. Auch eine Anbindung der
                Terra AC Wallbox an ein Smart Home oder eine Photovoltaik-Anlage
                ist möglich.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Mit Ladekabel oder als Steckdose",
                  "Mit/ohne Display",
                  "Robuste Metallausführungen",
                  "Authentifizierung über App oder RFID",
                  "Einphasig bis zu 7,4 kW / 32 A",
                  "Dreiphasig bis zu 22 kW / 32 A",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="60vh"
                col={12}
                src={CenteredImg}
                alt="Terra AC Wallbox"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
