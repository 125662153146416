import { Grid } from "@material-ui/core";
import Slide from "../../../../components/Slide";
import QHeader from "../../../../components/QHeader";
import QBodyText from "../../../../components/QBodyText";
import QList from "../../../../components/QList";
import ImageComponent from "../../../../components/ImageComponent";

// Images
import BackgroundImg from "../../../../assets/_backgrounds/renovation-und-neubau/9.jpg";
import CenteredImg from "../../../../assets/renovation-und-neubau/raume/eingang/dome-kamera/dome-kamera-freigestellt.png";
import DomeKameraImg from "../../../../assets/renovation-und-neubau/raume/eingang/dome-kamera/dome-kamera.jpg";
import KameraInterfaceImg from "../../../../assets/renovation-und-neubau/raume/eingang/dome-kamera/kamera-interface.jpg";
import MiniComeKameraImg from "../../../../assets/renovation-und-neubau/raume/eingang/dome-kamera/mini-dome-kamera.jpg";
import VideoKameraImg from "../../../../assets/renovation-und-neubau/raume/eingang/dome-kamera/videokamera.jpg";

export default function DomeKamera() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktuebersicht?tx_nlbjproducts_catalog%5Baction%5D=list&tx_nlbjproducts_catalog%5BcatDokument%5D=347&tx_nlbjproducts_catalog%5Bcontroller%5D=CatBjeProdukt&cHash=34474ca2839e9b268f70db6632d7ea01",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Dome-Kamera" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Es gibt Bereiche, die man gerne zusätzlich überwacht hätte, oder
                die von der Busch-Welcome Außenstation nicht erfasst werden. Zur
                Erweiterung des Sichtbereichs können externe Kameras über ein
                Kamera Interface mit Busch-Welcome kombiniert werden.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "Sicherer schlüsselloser Zutritt",
                  "Individuelle Zutrittskontrolle",
                  "Online-Verwaltungssystem",
                ]}
              />
              <ImageComponent
                wrapperWidth="100%"
                align="left"
                col={3}
                images={[
                    {
                      src: VideoKameraImg,
                      alt: "Kamera Interface",
                    },
                  {
                    src: DomeKameraImg,
                    alt: "Videokamera",
                  },
                  {
                    src: MiniComeKameraImg,
                    alt: "Mini Dome-Kamera",
                  },
                  {
                    src: KameraInterfaceImg,
                    alt: "Dome-Kamera",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <ImageComponent
                wrapperHeight="30vh"
                src={CenteredImg}
                alt="Bewegungsmelder inkl. Fernbedienung"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
