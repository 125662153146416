import { Box, Grid } from "@material-ui/core";
import Slide from "../../../../../../../../../components/Slide";
import QHeader from "../../../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../../../components/QBodyText";
import QList from "../../../../../../../../../components/QList";
import ImageComponent from "../../../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../../../components/CenteredImage";

// Images
import BackgroundImg from "../../../../../../../../../assets/_backgrounds/renovation-und-neubau/9.jpg";
import CenteredImg from "../../../../../../../../../assets/renovation-und-neubau/raume/eingang/allwetter-44/allwetter-schuko.png";
import Allwetter1Fach from "../../../../../../../../../assets/renovation-und-neubau/raume/eingang/allwetter-44/allwetter-1-fach.jpg";
import Allwetter2Fach from "../../../../../../../../../assets/renovation-und-neubau/raume/eingang/allwetter-44/allwetter-2-fach.jpg";

export default function AllwetterOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "left",
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produkte/schalterdesign/allwetter-44",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Allwetter 44®" />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                An der Haustür ist eine Steckdose immer sinnvoll. Ob für Deko
                oder für die Versorgung eines Hochdruckreiniger. Zum Schutz vor
                unbefugter Benutzung bietet sích die abschließbare
                Steckdosenvariante an.
              </QBodyText>
              <QList
                headline="Vorteile"
                items={[
                  "In den Farben Alu, Anthrazit, Weiß, Braun oder Edelstahl",
                  "Auch Mehrfach-Kombinationen möglich",
                ]}
              />
              <ImageComponent
                wrapperWidth="60%"
                align="left"
                col={6}
                images={[
                  {
                    src: Allwetter1Fach,
                    alt: "Allwetter 44®",
                  },
                  {
                    src: Allwetter2Fach,
                    alt: "Allwetter 44®",
                  },
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                wrapperHeight="40vh"
                src={CenteredImg}
                alt="Allwetter 44®"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
