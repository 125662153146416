import { h } from "preact";
import { Grid } from "@material-ui/core";
import backgroundHervorhebenBild from "../../../../../../assets/cwa/background/titelbilder/schalter/solo.jpg";
import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import QHeader from "../../../../../../components/QHeader";
import CenteredImage from "../../../../../../components/CenteredImage";
import Preisliste from "../preisliste";
import ImageComponent from "../../../../../../components/ImageComponent";

const actionBar = [
  {
    type: "icon",
    img: "laptop",
    link: "https://www.busch-jaeger.de/produkte/schalterdesign/solo",
    target: "_blank",
  },
  {
    type: "dialog",
    img: "preisliste",
    children: <Preisliste anchor="solo" />,
  },
];

export default function Solo() {
  return (
    <Slide
      actions={actionBar}
      background={{
        src: backgroundHervorhebenBild,
      }}
    >
      <CenteredImage
        src="../../../../../../assets/cwa/SchaltenUndBedienen/freigestellt/solo.png"
        alt={
          <>
            solo<sup>®</sup>
          </>
        }
        height="34vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader
            title="Lichtschalter"
            subTitle={
              <>
                solo<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <QList
                items={[
                  <>
                    Mit Dynamik in Form und Farbgebung erzeugt die Schalterserie
                    solo<sup>®</sup> einen echten Blickfang in jedem Raum
                  </>,
                  "Kontrast in Form und Farbe",
                  "Senkrechte oder waagerechte Montage möglich",
                  "Erhältlich in 1-fach und 5-fach",
                  "Ideal für die harmonisch-gelassene Raumgestaltung",
                ]}
                dense
              />
              <ImageComponent
                col={6}
                wrapperWidth="80%"
                align="left"
                images={[
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/solo/chrom-glanz.jpg",
                    alt: (
                      <>
                        solo<sup>®</sup> graumetallic / Chrom glanz
                      </>
                    ),
                  },
                  {
                    src: "../../../../../../assets/cwa/SchaltenUndBedienen/Produkte/solo/chrom-matt.jpg",
                    alt: (
                      <>
                        solo<sup>®</sup> studioweiß / Chrom matt
                      </>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
