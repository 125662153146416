import { h } from "preact";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { sectionStart } from "../../helpers/fullpageHelpers";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Averta-Bold",
  },
  subTitle: {
    fontFamily: "Averta-Light",
  },
  arrowWrap: {
    position: "absolute",
    left: "8px",
    marginTop: "3px",
  },
  largeTitle: {
    fontSize: "48px",
  },
  largeSubTitle: {
    fontSize: "22px",
  },
  smallTitle: {
    fontSize: "20px",
  },
  smallSubTitle: {
    fontSize: "15px",
  },
}));

export default function QActionHeader({
  title,
  subTitle,
  arrow,
  size,
  ...props
}) {
  const classes = useStyles();

  let titleSize;
  let subTitleSize;
  switch (size) {
    case "large":
      {
        titleSize = classes.largeTitle;
        subTitleSize = classes.largeSubTitle;
      }
      break;
    case "small":
      {
        titleSize = classes.smallTitle;
        subTitleSize = classes.smallSubTitle;
      }
      break;
    default: {
      titleSize = classes.largeTitle;
      subTitleSize = classes.largeSubTitle;
    }
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        <Typography
          variant="h3"
          className={clsx(classes.title, titleSize)}
          gutterBottom={!subTitle}
          {...props}
          onClick={sectionStart}
        >
          {arrow && (
            <div className={classes.arrowWrap} onClick={sectionStart}>
              <svg
                height="25"
                viewBox="0 0 25 25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <circle cx="12.5" cy="12.5" fill="#000" r="12.5" />
                  <path
                    d="m16.9875 7.5-5.7375 5.725-5.7375-5.725-1.7625 1.7625 7.5 7.5 7.5-7.5z"
                    fill="#fff"
                    fill-rule="nonzero"
                    transform="matrix(0 1 -1 0 23.38125 .88125)"
                  />
                </g>
              </svg>
            </div>
          )}
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h5"
          className={clsx(classes.subTitle, subTitleSize)}
          gutterBottom
          {...props}
          onClick={sectionStart}
        >
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
}

QActionHeader.defaultProps = {
  arrow: false,
  size: "large",
};
