import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../../../components/QList";
import QHeader from "../../../../../../../components/QHeader";
import Slide from "../../../../../../../components/Slide/index";
import QBodyText from "../../../../../../../components/QBodyText";
import ImageComponent from "../../../../../../../components/ImageComponent";
import CenteredImage from "../../../../../../../components/CenteredImage";

export default function Steckdosen() {
  return (
    <Slide
      background={{
        src: "../../../../../../../assets/SchaltenUndSteuern/background/steckdosen.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../../../assets/SchaltenUndSteuern/produkte/steckdosen.jpg"
        alt="Busch-Servicesteckdose®"
        height="33vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader title="Stromversorgung" />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <QBodyText fontWeight="bold" marginBottom="0px">
              Steckdosen
            </QBodyText>
            <QBodyText>
              Die sicheren und komfortablen Steckdosen von Busch-Jaeger bieten
              Schutz und sorgen für ein rundum sicheres Gefühl
            </QBodyText>
            <Box style={{ paddingRight: "110px" }}>
              <QList
                headline="Vorteile"
                items={[
                  "Maximaler Schutz",
                  "Funktionsvielfalt im ganzheitlichen Schalterdesign",
                  "Einfache, schnelle und fest sitzende Montage",
                  "Einsätze für mehr Sicherheit, Komfort und Wirtschaftlichkeit",
                ]}
                dense
                variant="arrow"
              />
            </Box>
            <Box display="flex">
              <ImageComponent
                col={4}
                align="left"
                images={[
                  {
                    alt: "Steckdose mit Klappe",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/steckdosen-left.jpg",
                  },
                  {
                    alt: "Steckdose",
                    src: "../../../../../../../assets/SchaltenUndSteuern/produkte/steckdosen-right.jpg",
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
