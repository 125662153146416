import { Grid, Paper, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getActiveModule } from "../../redux/modules/consulting";
import Section from "../Section";
import { chapters } from "../../helpers/data";
import Slide from "../Slide";
import { getActiveModules } from "../../redux/modules/backendState";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  chapterPaper: {
    padding: "1.4rem",
    fontSize: "1rem",
    minHeight: "1.2rem",
    margin: "0.5rem",
  },
  chapterActive: {
    filter: "drop-shadow(0 0 0.09rem rgb(0, 0, 0))",
  },
}));

export default function NextChapter({ section }) {
  const classes = useStyles();
  const activeModules = useSelector((state) => getActiveModules(state));
  const activeModule = useSelector((state) => getActiveModule(state));

  const renderData = activeModules.map((module) => {
    return (
      <Grid item xs={6} m={0} key={module.chapter.key}>
        <a href={`/${module.chapter.path}`}>
          <Paper
            className={`${classes.chapterPaper} ${
              module.chapter.key === activeModule && classes.chapterActive
            }`}
            elevation={3}
          >
            {module.chapter.name}
          </Paper>
        </a>
      </Grid>
    );
  });

  return (
    <Section selector={`${section} fp-auto-height`}>
      <Slide
        background={{
          enabled: true,
          alignment: "right",
          width: 1,
          height: 1,
        }}
        className={classes.root}
      >
        <Grid
          container
          m={0}
          justify="center"
          alignItems="stretch"
          className={classes.root}
        >
          {renderData}
        </Grid>
      </Slide>
    </Section>
  );
}
