import { h } from "preact";
import { Grid, Box } from "@material-ui/core";
import QList from "../../../../../components/QList";
import QHeader from "../../../../../components/QHeader";
import Slide from "../../../../../components/Slide/index";
import QBodyText from "../../../../../components/QBodyText";
import CenteredImage from "../../../../../components/CenteredImage";

export default function Freiheit() {
  return (
    <Slide
      background={{
        src: "../../../../../assets/tuerkommunikation/background/freiheit.jpg",
        enabled: true,
        alignment: "right",
        width: 2 / 4,
        height: 3.575 / 4,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CenteredImage
        src="../../../../../assets/tuerkommunikation/smartphone/freiheit.png"
        alt={
          <>
            Busch-free@home<sup>®</sup> Next App
          </>
        }
        height="57vh"
        left="0"
        top="0"
      />
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <QHeader
            title={
              <>
                Busch-free@home<sup>®</sup>
              </>
            }
          />
        </Grid>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Box style={{ paddingRight: "120px" }}>
              <QBodyText fontWeight="bold" marginBottom="0px">
                Die neue Freiheit
              </QBodyText>
              <QBodyText>
                Nahezu unbegrenzte Möglichkeiten für Ihren Komfort, Ihre
                Sicherheit und Energieeffizienz.
              </QBodyText>
              <QList
                headline="Umfangreiche Funktionen wie"
                items={[
                  "Zeitschaltuhren/Astrofunktion",
                  "Anwesenheitssimulation",
                  "Szenen",
                  "Astrofunktionen",
                  "Logik/Aktionen „Wenn-dann“",
                  "Fernzugriff",
                ]}
                dense
                variant="arrow"
              />
              <QList
                items={[
                  "Für Renovation und Neubau",
                  "Kombinierbar mit Busch-Rauchalarm, Busch-Welcome®, Busch-Welcome® IP, ...",
                  "Kombinierbar mit anderen Herstellern wie Philips hue, Sonos, Miele, HomeConnect, ..",
                ]}
                dense
                variant="arrow"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
