import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/42.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/raumklima/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/raumklima/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/raumklima/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/raumklima/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/raumklima/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/raumklima/6.jpg";

const items = [
  {
    title: "Raumtemperaturregler",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/raumtemperaturregler",
    },
  },
  {
    title: "Raumtemperaturregler mit Display",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/raumtemperaturregler-mit-ist-wert-anzeige",
    },
  },
  {
    title: (
      <>
        Raumtemperaturregler
        <br />
        heizen/kühlen
      </>
    ),
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/raumtemperaturregler-heizen-kuehlen",
    },
  },
  {
    title: "Raumtemperaturregler mit Zeitsteuerung",
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/raumtemperaturregler-mit-timer",
    },
  },
  {
    title: (
      <>
        Raumtemperaturregler Busch-free@home<sup>®</sup>
      </>
    ),
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/produkt/busch-freehome/raumtemperaturregler-busch-freehome",
    },
  },
  {
    title: (
      <>
        Busch-Raumluftsensor<sup>®</sup> CO<sub>2</sub>
      </>
    ),
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-raumluftsensor-co2",
    },
  },
];

export default function RaumklimaOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/raumtemperaturregler",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Raumklima" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Früher klassische Zentralheizung – heute Einzelraumregelung,
                Fußbodenheizung, Klimatisierung bis hin zur Feuchtigkeits- und
                CO<sub>2</sub>-Überwachung.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={4}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
