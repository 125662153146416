import { Grid } from "@material-ui/core";
import Slide from "../../../../../../../components/Slide";
import QHeader from "../../../../../../../components/QHeader";
import QBodyText from "../../../../../../../components/QBodyText";
import ChapterCard from "../../../../../../../components/ChapterCard";

// Images
import BackgroundImg from "../../../../../../../assets/_backgrounds/renovation-und-neubau/52.jpg";
import Image1 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/1.jpg";
import Image2 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/2.jpg";
import Image3 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/3.jpg";
import Image4 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/4.jpg";
import Image5 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/5.jpg";
import Image6 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/6.jpg";
import Image7 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/7.jpg";
import Image8 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/8.jpg";
import Image9 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/9.jpg";
import Image10 from "../../../../../../../assets/renovation-und-neubau/funktion/jalousiemanagement/10.jpg";

const items = [
  {
    title: "Busch-Compact Timer",
    img: Image1,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-compact-timer",
    },
  },
  {
    title: "Jalousiesteuerung flex",
    img: Image2,
    link: {
      href: "https://www.busch-jaeger.de/busch-freeathome-flex",
    },
  },
  {
    title: (
      <>
        Jalousie
        <br />
        Tast/Rastschalter
      </>
    ),
    img: Image3,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/jalousieschalter-taster",
    },
  },
  {
    title: "Busch-Standard-Timer",
    img: Image4,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-jalousiecontrol-ii",
    },
  },
  {
    title: "Jalousiesteuerung flex WL",
    img: Image5,
    link: {
      href: "https://www.busch-jaeger.de/busch-freeathome-flex",
    },
  },
  { placeholder: true },
  {
    title: (
      <>
        Busch-free@home<sup>®</sup>
      </>
    ),
    img: Image6,
    link: {
      href: "https://www.busch-jaeger.de/busch-freehome",
    },
  },
  {
    title: "Jalousie-Drehschalter",
    img: Image7,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/jalousieschalter-taster-mit-drehgriff",
    },
  },
  {
    title: "Busch-Komfort-Timer",
    img: Image8,
    link: {
      href: "https://www.busch-jaeger.de/produkt/future-linear/busch-jalousiecontrol-ii",
    },
  },
  {
    title: (
      <>
        Wetterstation flex
        <br />
        Wireless
      </>
    ),
    img: Image9,
    link: {
      href: "https://www.busch-jaeger.de/busch-freeathome-flex",
    },
  },
  {
    title: (
      <>
        Wetterstation
        <br />
        Busch-free@home<sup>®</sup>
      </>
    ),
    img: Image10,
    link: {
      href: "https://www.busch-jaeger.de/busch-freehome",
    },
  },
];

export default function JalousiemanagementOverlay() {
  return (
    <Slide
      background={{
        src: BackgroundImg,
        backgroundPosition: "center",
        width: 0.45,
      }}
      actions={[
        {
          type: "icon",
          img: "laptop",
          link: "https://www.busch-jaeger.de/produktloesung/jalousiemanagement",
          target: "_blank",
        },
      ]}
    >
      <Grid container direction="column" spacing={2} wrap="nowrap">
        <Grid item>
          <QHeader title="Jalousiemanagement" arrow />
        </Grid>
        <Grid item>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <QBodyText>
                Klassische Bedienung über Tast-/Drehschalter oder
                teilautomatisiert mit einem Timer bis hin zur Raum- und smarten
                Komplettsteuerung mit Wetterstation.
              </QBodyText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {items.map((e, i) => {
                  return (
                    <Grid item xs={2}>
                      <ChapterCard smallPadding chapter={e} cardWidth="auto" />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
}
