import { h } from "preact";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  listItem: {
    fontSize: (props) => props.fontSize,
  },
  headline: {
    fontWeight: "bold",
    marginBottom: "0",
  },
});
export default function QList({
  items,
  variant,
  dense,
  divider,
  headline,
  fontSize,
  color,
}) {
  const classes = useStyles({ fontSize });
  let colorHex
  let v;

  switch (variant) {
    case "dot":
      v = "●";
      break;
    case "arrow":
      v = "»";
      break;
    case "none":
      v = "";
      break;
    default:
      v = "●";
  }

  switch (color) {
    case "bje":
      colorHex = "#009fe3";
      break;
    default:
      colorHex = color;
  }
  const listItems = items.map((item, i) => [
    <ListItem
      key={`listItem-${i}`}
      style={{
        fontSize: "clamp(1.1rem, 1.5vw, 1.5rem)",
        paddingTop: "0px",
        paddingBottom: "0px",
        alignItems: "unset",
        color: colorHex,
      }}
    >
      {variant !== "none" && (
        <ListItemIcon style={{ fontSize: "1rem", paddingTop: "5px", color: colorHex }}>
          {v}
        </ListItemIcon>
      )}
      <ListItemText className={classes.listItem}>{item}</ListItemText>
    </ListItem>,
    divider && i !== items.length - 1 && <Divider key={`divider-${i}`} />,
  ]);

  return (
    <div>
      {headline && <p style={{ color: colorHex }}className={classes.headline}>{headline}</p>}
      <List dense={dense}>{listItems}</List>
    </div>
  );
}

QList.defaultProps = {
  items: [],
  variant: "dot",
  dense: false,
  headline: "",
  divider: false,
  fontSize: null,
  color: "bje",
};
