import Cookies from "js-cookie";
import crmDB from "../../databases/crmDB";
import * as B2CSDK from "@qmarketing/b2c-js-sdk";
import {
  resetConsultant,
  setConsultantCompany,
  setConsultantEmail,
  setConsultantFirstname,
  setConsultantLastname,
  setConsultantSalutation,
} from "./consultant";

import { RBACRequest } from "./rbac/request";

export const FETCH_USER_REQUEST = "playbook/user/REQUEST";
export const FETCH_USER_INVALIDATE = "playbook/user/INVALIDATE";
export const FETCH_USER_RECEIVE = "playbook/user/RECEIVE";
export const FETCH_USER_FAILURE = "playbook/user/FAILURE";

//Reducer
export const initialState = {
  isInvalidated: true,
  isFetching: false,
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  gender_iso5218: 1,
  country: "",
  roles: "",
  loggedInAt: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_INVALIDATE:
      return {
        ...initialState,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false,
      };
    case FETCH_USER_RECEIVE:
      return {
        ...state,
        isFetching: false,
        isInvalidated: false,
        username: action.username,
        realname: action.realname,
        firstname: action.firstname,
        lastname: action.lastname,
        email: action.email,
        gender_iso5218: action.gender_iso5218,
        country: action.country,
        roles: action.roles,
        loggedInAt: Date.now ? Date.now() : new Date().getTime(),
      };
    default:
      return state;
  }
}

//Action creators
export const requestUser = () => ({
  type: FETCH_USER_REQUEST,
});

export const invalidateUser = () => ({
  type: FETCH_USER_INVALIDATE,
});

export const fetchUserFailure = () => ({
  type: FETCH_USER_FAILURE,
});

export const writeUserData = (user) => ({
  type: FETCH_USER_RECEIVE,
  username: user.username,
  realname: user.realname,
  firstname: user.firstname,
  lastname: user.lastname,
  email: user.email,
  gender_iso5218: user.gender_iso5218,
  country: user.country,
  roles: user.roles,
});

export const shouldFetchUserData = (state) =>
  (!state.username && !state.email && !state.isFetching) ||
  Cookies.get("access_token") === "";

export const fetchUserDataIfNeeded = () => (dispatch, getState) => {
  if (Cookies.get("access_token") === undefined) {
    dispatch(invalidateUser());
  } else if (shouldFetchUserData(getState().user)) {
    return dispatch(fetchUserData());
  }
};

//Async action creators
export const fetchUserData = () => (dispatch, getState) => {
  dispatch(requestUser());
  let apiUrl = `${process.env.PREACT_APP_MYBUILDINGSAPI}user`; //eslint-disable-line no-undef
  let xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      dispatch(writeUserData(JSON.parse(this.responseText)));
      let user = getState().user;
      dispatch(setConsultantEmail(user.email));
      dispatch(setConsultantFirstname(user.firstname));
      dispatch(setConsultantLastname(user.lastname));
      dispatch(
        setConsultantSalutation(user.gender_iso5218 === 1 ? "Herr" : "Frau")
      );
      // dispatch(fechtUserCompanies());
      dispatch(fechtUserCompanyLogo());
      dispatch(fetchConsultantCompanyData())
    } else {
      dispatch(fetchUserFailure());
    }
  });
  xhr.open("GET", apiUrl);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${Cookies.get("access_token")}`
  );
  xhr.send();
};

//Async action creators
export const fetchUserLogout = () => (dispatch) => {
  dispatch(logoutUser());
  dispatch(resetConsultant());
};

export const logoutUser = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  crmDB.delete().then(() => crmDB.open());
  return {
    type: FETCH_USER_INVALIDATE,
  };
};

export const fechtUserCompanies = () => (dispatch) => {
  let apiUrl = `${process.env.PREACT_APP_INSTALLERAPI}organizations`; //eslint-disable-line no-undef
  let xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      const companies = JSON.parse(this.responseText);
      if (companies.length > 0) {
        dispatch(fechtUserCompanyData(companies[0].uuid));
      }
    } else {
      dispatch(fetchUserFailure());
    }
  });
  xhr.open("GET", apiUrl);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${Cookies.get("access_token")}`
  );
  xhr.send();
};
export const fechtUserCompanyData = (uuid) => (dispatch) => {
  let apiUrl = `${process.env.PREACT_APP_INSTALLERAPI}organizations/${uuid}`; //eslint-disable-line no-undef
  let xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      const company = JSON.parse(this.responseText);
      dispatch(
        setConsultantCompany({
          id: company.uuid,
          name: company.name,
          street: company.address,
          postcode: company.postcode,
          city: company.city,
          logo: company.logo,
        })
      );
    } else {
      dispatch(fetchUserFailure());
    }
  });
  xhr.open("GET", apiUrl);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${Cookies.get("access_token")}`
  );
  xhr.send();
};

export const fetchConsultantCompanyData = () => (dispatch) => {
  let apiUrl = `${process.env.PREACT_APP_EXCELLENCEAPI}b2c/user-company`; //eslint-disable-line no-undef
  let xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      const company = JSON.parse(this.responseText) || {
        id: "-",
        name: "-",
        street: "-",
        postcode: "-",
        city: "-",
      };
      dispatch(
        setConsultantCompany({
          id: company.id,
          name: company.name,
          street: company.street,
          postcode: company.postcode,
          city: company.city,
        })
      );
    } else {
      dispatch(fetchUserFailure());
    }
  });
  xhr.open("GET", apiUrl);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${Cookies.get("access_token")}`
  );
  xhr.send();
};

export const fechtUserCompanyLogo = () => (dispatch) => {
  let apiUrl = `${process.env.PREACT_APP_EXCELLENCEAPI}b2c/logo?width=160`; //eslint-disable-line no-undef
  let xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      let reader = new FileReader();
      reader.onloadend = function () {
        dispatch(
          setConsultantCompany({
            logo: reader.result,
          })
        );
      };
      reader.readAsDataURL(xhr.response);
    } else {
      dispatch(fetchUserFailure());
    }
  });
  xhr.open("GET", apiUrl);
  xhr.setRequestHeader(
    "Authorization",
    `Bearer ${Cookies.get("access_token")}`
  );
  xhr.responseType = "blob";
  xhr.send();
};

//Selectors
export const getUser = (state) => state.user;

export const isLoggedIn = (state) =>
  state.user.username !== "" && Cookies.get("access_token") !== undefined;

export const userHasPermission = ({ action, object, application }) => {
  const token = Cookies.get("access_token");

  if (token === undefined || token === "") {
    return false;
  }

  const permission = new RBACRequest();
  return permission.can(token, object, action, true, application);
};

export const fetchJwtCookie = (username, password) => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    xhr.open(
      "POST",
      `${process.env.PREACT_APP_MYBUILDINGSAPI}user/access-token`,
      true
    );
    xhr.setRequestHeader(
      "Authorization",
      `Basic ${btoa(`${username}:${password}`)}`
    );
    xhr.onload = function () {
      if (this.status === 201) {
        Cookies.set("access_token", "xhr.responseText", { expires: 30 });
        resolve(true);
      } else {
        Cookies.remove("access_token");
        resolve(false);
      }
    };
    xhr.send();
  });
};

export const ssoAuth = (code) => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    let data = new FormData();
    data.append("client_id", process.env.PREACT_APP_SSOCLIENTID);
    data.append("client_secret", process.env.PREACT_APP_SSOCLIENTSECRET);
    data.append("grant_type", "authorization_code");
    data.append("code", code);
    xhr.open("POST", `${process.env.PREACT_APP_SSOURL}token`, true);
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const response = JSON.parse(xhr.responseText);
          Cookies.set("access_token", response["access_token"], {
            expires: 30,
          });
          Cookies.set("refresh_token", response["refresh_token"], {
            expires: 90,
          });

          global.API.authentications.MyBJE.apiKey = `Bearer ${response["access_token"]}`;

          resolve(true);
        } else {
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          resolve(false);
        }
      }
    });
    xhr.send(data);
  });
};

export const ssoRefresh = () => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    let data = new FormData();
    data.append("client_id", process.env.PREACT_APP_SSOCLIENTID);
    data.append("client_secret", process.env.PREACT_APP_SSOCLIENTSECRET);
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", Cookies.get("refresh_token"));
    xhr.open("POST", `${process.env.PREACT_APP_SSOURL}token`, true);
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const response = JSON.parse(xhr.responseText);
          Cookies.set("access_token", response["access_token"], {
            expires: 30,
          });
          Cookies.set("refresh_token", response["refresh_token"], {
            expires: 90,
          });

          global.API.authentications.MyBJE.apiKey = `Bearer ${response["access_token"]}`;

          resolve(response["access_token"]);
        } else {
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          resolve(false);
        }
      }
    });
    xhr.send(data);
  });
};
